import axios from "axios";
import { AppConfig } from "../config";
import { FbAuth } from "./firebase";

export async function SendPasswordResetEmail(email: string) {
    try {
        // Call /mailing/recovery with {email} in body
        console.log("Sending command...");
        await axios.post(AppConfig.api + "/mailing/recovery", {
            email: email,
        });
    } catch (err: any) {
        console.log(err);
        throw err;
    }
}

export async function GetDashboardData(guild: string) {
    try {
        // Call /analytics/dashboard with GET method.
        const token = await FbAuth.currentUser?.getIdToken();
        if (token) {
            const resp = await axios.get(
                AppConfig.api + "/analytics/dashboard?guild=" + guild,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            return resp.data as any;
        }
    } catch (err: any) {
        console.log("Error getting dashboard data.");
        console.log(err);
        throw err;
    }
}

export async function GetAnalyticsData(
    guild: string,
    from: string,
    to: string,
    timezone: string
) {
    try {
        // Call /analytics/dashboard with GET method.
        const token = await FbAuth.currentUser?.getIdToken();
        if (token) {
            const resp = await axios.get(
                AppConfig.api +
                    `/analytics/advanced?guild=${guild}&from=${from}&to=${to}&timezone=${timezone}`,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            return resp.data as {
                insights: {
                    members: number;
                    joined: number;
                    communicators: number;
                    visitors: number;
                    messages: number;
                };
                members: {
                    labels: number[];
                    values: number[];
                };
                reach: {
                    labels: number[];
                    delivered: number[];
                };
                topMembers: {
                    [key: string]: {
                        messages: number;
                        avatar: string;
                        username: string;
                    };
                };
                topChannels: {
                    channelId: string;
                    channelName: string;
                    subscribers: number;
                    telegram_messages: number;
                    whatsapp_messages: number;
                }[];
            };
        }
    } catch (err: any) {
        console.log("Error getting analytics data.");
        console.log(err);
        throw err;
    }
}

export async function SendVerificationEmail() {
    try {
        const token = await FbAuth.currentUser?.getIdToken();
        if (token) {
            const resp = await axios.post(
                AppConfig.api + "/mailing/signup",
                {},
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            return resp.data;
        }
    } catch (err: any) {
        console.log("Error sending verification email.");
        console.log(err);
        throw err;
    }
}

export async function SendVerificationConfirmation(uid: string) {
    try {
        const resp = await axios.post(AppConfig.api + "/mailing/confirmed", {
            uid: uid,
        });
        return resp.data;
    } catch (err: any) {
        console.log("Error sending confirmation email.");
        console.log(err);
        throw err;
    }
}
