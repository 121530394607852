import { useEffect, useState } from "react";
import { DialogProps } from "./DialogProps";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import ComboBox, { ComboBoxItem } from "../components/ComboBox";
import { Telegram, WhatsApp } from "@mui/icons-material";
import { AnnouncelyPlatform, LinkedChat } from "@announcely/models";
import { useSnackbar } from "notistack";
import { useRecoilState } from "recoil";
import { aProfile } from "../states/ui";
import { canAccessTelegram, canAccessWhatsApp } from "../core/helper";
import { createLinkedChat, getChats } from "../core/rtm";

export default function CreateLinkDialog(props: DialogProps<LinkedChat>) {
    const [server, setServer] = useState<ComboBoxItem>();
    const [channel, setChannel] = useState<ComboBoxItem>();
    const [target, setTarget] = useState<ComboBoxItem>();
    const [guilds, setGuilds] = useState<ComboBoxItem[]>();
    const [targets, setTargets] = useState<ComboBoxItem[]>();
    const [channels, setChannels] = useState<ComboBoxItem[]>();
    const [platform, setPlatform] = useState<"whatsapp" | "telegram">();
    const [created, setCreated] = useState(false);
    const [busy, setBusy] = useState(false);
    const [header, setHeader] = useState(false);
    const [profile] = useRecoilState(aProfile);

    const [features, setFeatures] = useState<any>({
        telegram: false,
        whatsapp: false,
    });

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    async function load() {
        try {
            const gs = profile?.guilds;
            if (gs) {
                let items: ComboBoxItem[] = [];
                let i = 0;
                for (let g of gs) {
                    items.push({
                        index: i,
                        name: g.name,
                        object: g,
                    });
                    i++;
                }
                setGuilds(items);
            }
            // Load
            if (profile) {
                setFeatures({
                    telegram: await canAccessTelegram(profile!),
                    whatsapp: await canAccessWhatsApp(profile!),
                });
            }
        } catch (err: any) {
            enqueueSnackbar(
                "Error loading data. Please see logs for details.",
                {
                    variant: "error",
                }
            );
        }
    }

    async function loadChannels() {
        try {
            let items: ComboBoxItem[] = [];
            if (server && server.object.channels) {
                let i = 0;
                for (let g of server?.object?.channels) {
                    if (g.type !== 0 && g.type !== 5) continue;
                    items.push({
                        index: i,
                        name: g.name,
                        object: g,
                    });
                    i++;
                }
            }
            setChannels(items);
        } catch (err: any) {
            enqueueSnackbar(
                "Error loading data. Please see logs for details.",
                {
                    variant: "error",
                }
            );
            console.log(err);
        }
    }

    async function loadChats() {
        try {
            const items: ComboBoxItem[] = [];
            const chats = (await getChats())?.sort(
                (b, a) => a.lastMessage - b.lastMessage
            );
            if (chats) {
                // Filter chats for selected platform.
                for (let c of chats) {
                    if (c.platform === platform) {
                        // Add it to list
                        items.push({
                            index: items.length,
                            name: c.name,
                            object: c,
                        });
                    }
                }
            }
            setTargets(items);
        } catch (err: any) {
            enqueueSnackbar(
                "Error loading chats. Please see logs for details.",
                {
                    variant: "error",
                }
            );
            console.log(err);
        }
    }

    function isValid() {
        if (!platform) return false;
        if (!channel) return false;
        if (!server) return false;
        if (!target) return false;
        return true;
    }

    async function CreateLink() {
        if (!isValid()) {
            enqueueSnackbar("Please provide all the details.", {
                variant: "error",
            });
            return;
        }
        if (platform === "telegram" && !canAccessTelegram(profile)) {
            enqueueSnackbar(
                "Cannot create link to telegram due to subscription limits.",
                {
                    variant: "error",
                }
            );
            return;
        }
        if (platform === "whatsapp" && !canAccessWhatsApp(profile)) {
            enqueueSnackbar(
                "Cannot create link to whatsapp due to subscription limits.",
                {
                    variant: "error",
                }
            );
            return;
        }
        try {
            setBusy(true);
            const resp = await createLinkedChat({
                platform: platform as AnnouncelyPlatform,
                sourceChannelId: channel?.object.id,
                sourceGuildId: server?.object.id,
                sourceChannelName: channel?.name,
                sourceGuildName: server?.name,
                target: target?.object.id,
                targetName: target?.name,
                includeHeader: header,
            });
            if (resp === "OK") {
                enqueueSnackbar("Link created successfully.", {
                    variant: "success",
                });
                setCreated(true);
            } else if (resp === "ERR_DUPLICATE") {
                enqueueSnackbar(
                    "A link with same source/target parameters already exist.",
                    { variant: "error" }
                );
            }
        } catch (err: any) {
            enqueueSnackbar(
                "Error creating a new link. Please see logs for details.",
                { variant: "error" }
            );
        }
        setBusy(false);
    }

    useEffect(() => {
        loadChannels();
    }, [server]);

    useEffect(() => {
        loadChats();
    }, [platform]);

    useEffect(() => {
        load();
    }, []);

    return (
        <Dialog
            open
            sx={{ backdropFilter: "blur(2px)" }}
            onClose={props.closeHandler}
            fullWidth
        >
            <DialogTitle>
                <Typography fontSize={20}>Create Link</Typography>
            </DialogTitle>
            <DialogContent>
                {created && (
                    <Stack alignItems={"center"} spacing="12px">
                        <Typography fontSize={"48px"}>🎉</Typography>
                        <Typography fontSize={"22px"}>
                            Congratulations!
                        </Typography>
                        <Typography>
                            Your Discord channel <b>#{channel?.name}</b> is now
                            linked to <b>{target?.name}</b> on <b>{platform}</b>
                            .<br />
                            Try sending a message in your channel to see it go
                            through!
                        </Typography>
                        <Button
                            variant="contained"
                            color="success"
                            fullWidth
                            onClick={props.closeHandler}
                        >
                            Okay
                        </Button>
                    </Stack>
                )}
                {!created && (
                    <Stack spacing={"8px"}>
                        <Stack
                            direction={"row"}
                            sx={{ width: "100%" }}
                            spacing={"8px"}
                        >
                            <Stack flex={1}>
                                <Typography>Select a server</Typography>
                                <ComboBox
                                    disabled={busy}
                                    values={guilds}
                                    changed={(i) => {
                                        setServer(i);
                                    }}
                                    value={server?.index}
                                    label="Server"
                                    placeholder="Select a server..."
                                    fullWidth
                                />
                            </Stack>
                            <Stack flex={1}>
                                <Typography>Source channel</Typography>
                                <ComboBox
                                    disabled={busy}
                                    values={channels}
                                    changed={(i) => {
                                        setChannel(i);
                                    }}
                                    value={channel?.index}
                                    label="Channel"
                                    placeholder="Select a channel..."
                                    fullWidth
                                />
                            </Stack>
                        </Stack>

                        <Stack>
                            <Typography>Choose a target platform</Typography>
                            <RadioGroup
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "8px",
                                }}
                            >
                                <Tooltip
                                    followCursor
                                    title={
                                        !features.whatsapp
                                            ? "Please upgrade your subscription to acess WhatsApp features."
                                            : ""
                                    }
                                >
                                    <FormControlLabel
                                        disabled={busy || !features.whatsapp}
                                        control={
                                            <Radio
                                                disableRipple
                                                checked={
                                                    platform === "whatsapp"
                                                }
                                                onChange={(e) =>
                                                    setPlatform(
                                                        e.currentTarget.checked
                                                            ? "whatsapp"
                                                            : "telegram"
                                                    )
                                                }
                                                icon={<WhatsApp />}
                                                checkedIcon={<WhatsApp />}
                                            />
                                        }
                                        label="WhatsApp"
                                        sx={{
                                            flex: 1,
                                            height: "60px",
                                            border:
                                                platform === "whatsapp"
                                                    ? "1px solid " +
                                                      theme.palette.primary.main
                                                    : "1px solid #AAA",
                                            py: "4px",
                                            px: "18px",
                                            borderRadius: "15px",
                                            m: 0,
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip
                                    followCursor
                                    title={
                                        !features.telegram
                                            ? "Please upgrade your subscription to acess Telegram features."
                                            : ""
                                    }
                                >
                                    <FormControlLabel
                                        disabled={busy || !features.telegram}
                                        control={
                                            <Radio
                                                disableRipple
                                                checked={
                                                    platform === "telegram"
                                                }
                                                onChange={(e) =>
                                                    setPlatform(
                                                        e.currentTarget.checked
                                                            ? "telegram"
                                                            : "whatsapp"
                                                    )
                                                }
                                                icon={<Telegram />}
                                                checkedIcon={<Telegram />}
                                            />
                                        }
                                        label="Telegram"
                                        sx={{
                                            flex: 1,
                                            height: "60px",
                                            border:
                                                platform === "telegram"
                                                    ? "1px solid " +
                                                      theme.palette.primary.main
                                                    : "1px solid #AAA",
                                            py: "4px",
                                            px: "18px",
                                            borderRadius: "15px",
                                            m: 0,
                                        }}
                                    />
                                </Tooltip>
                            </RadioGroup>
                        </Stack>

                        {platform && (
                            <Stack flex={1}>
                                <Typography>Target chat</Typography>
                                <ComboBox
                                    disabled={busy}
                                    values={targets}
                                    changed={(i) => {
                                        setTarget(i);
                                    }}
                                    value={target?.index}
                                    label="Target chat"
                                    placeholder="Select a chat..."
                                    fullWidth
                                />
                            </Stack>
                        )}

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={header}
                                    onChange={(c) =>
                                        setHeader(c.currentTarget.checked)
                                    }
                                />
                            }
                            label="Include Source Information in Forwarded Messages"
                        />
                    </Stack>
                )}
            </DialogContent>
            {!created && (
                <DialogActions>
                    <Button disabled={busy} onClick={props.closeHandler}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={CreateLink}
                        disabled={!isValid() || busy}
                    >
                        Create
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}
