import { Chip, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { LOGO_LIGHT_BLUE_HORIZONTAL } from "../assets";
import SidebarButton from "./SidebarButton";
import {
    DashboardRounded,
    PeopleRounded,
    CreditCardRounded,
    LinkRounded,
    SettingsRounded,
    CampaignRounded,
    MonetizationOn,
    Analytics,
    HelpRounded,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { aProfile } from "../states/ui";
import { canAccessFeatures } from "../core/helper";
import { useEffect, useState } from "react";
import { AppConfig } from "../config";

export default function Sidebar() {
    const [profile] = useRecoilState(aProfile);
    const [canAccess, setCanAccess] = useState(false);
    const loc = useLocation();
    const nav = useNavigate();

    async function load() {
        try {
            if (profile) {
                const check = await canAccessFeatures(profile);
                setCanAccess(check);
            }
        } catch (err: any) {}
    }

    useEffect(() => {
        load();
    }, [profile?.billing?.subscriptions?.length]);

    return (
        <Stack
            sx={{ height: "100%", px: "32px" }}
            spacing={"8px"}
            alignItems={"center"}
        >
            {/* The logo  */}
            <img
                src={LOGO_LIGHT_BLUE_HORIZONTAL}
                width={"256px"}
                style={{ marginTop: "32px" }}
                alt="Announcely Logo"
            />
            <Stack sx={{ pt: "80px", width: "80%", py: "32px" }} flex={1}>
                <SidebarButton
                    active={loc.pathname.toLowerCase() === "/portal/dashboard"}
                    onClick={() => nav("dashboard")}
                    startIcon={<DashboardRounded fontSize="large" />}
                >
                    Dashboard
                </SidebarButton>

                <SidebarButton
                    active={loc.pathname.toLowerCase() === "/portal/analytics"}
                    onClick={() => nav("analytics")}
                    startIcon={<Analytics fontSize="large" />}
                >
                    Analytics
                </SidebarButton>

                {/* <SidebarButton
                    active={
                        loc.pathname.toLowerCase() === "/portal/subscribers"
                    }
                    onClick={() => nav("subscribers")}
                    startIcon={<PeopleRounded fontSize="large" />}
                >
                    Subscribers
                </SidebarButton> */}
                <SidebarButton
                    active={loc.pathname.toLowerCase() === "/portal/links"}
                    onClick={() => nav("links")}
                    startIcon={<LinkRounded fontSize="large" />}
                >
                    Linked Chats
                </SidebarButton>
                <SidebarButton
                    active={
                        loc.pathname.toLowerCase() === "/portal/announcements"
                    }
                    onClick={() => nav("announcements")}
                    startIcon={<CampaignRounded fontSize="large" />}
                >
                    Announcements
                </SidebarButton>
                <SidebarButton
                    active={loc.pathname.toLowerCase() === "/portal/billing"}
                    onClick={() => nav("billing")}
                    startIcon={<CreditCardRounded fontSize="large" />}
                >
                    Billing
                </SidebarButton>
                <SidebarButton
                    onClick={() => window.open("https://announcely.app/help")}
                    startIcon={<HelpRounded fontSize="large" />}
                >
                    Help
                </SidebarButton>
                <SidebarButton
                    active={loc.pathname.toLowerCase() === "/portal/settings"}
                    onClick={() => nav("settings")}
                    startIcon={<SettingsRounded fontSize="large" />}
                >
                    Settings
                </SidebarButton>
                {canAccess === false && (
                    <Typography
                        fontSize={"10px"}
                        color={"gold"}
                        fontStyle={"italic"}
                        maxWidth={"200px"}
                    >
                        Some of the features are restricted. Please go to
                        settings to learn more.
                    </Typography>
                )}

                <Stack
                    flex={1}
                    spacing={"8px"}
                    sx={{ mx: "8px" }}
                    direction={"row"}
                    alignItems={"end"}
                >
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing="8px"
                    >
                        <Typography fontSize={12}>Version: </Typography>
                        <Tooltip title={`Revision: ${AppConfig.revision}`}>
                            <Chip label={AppConfig.version} size="small" />
                        </Tooltip>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}
