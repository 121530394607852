import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import LoadingOverlay from "./LoadingOverlay";
import { FbApp } from "../core/firebase";
import { useRecoilState } from "recoil";
import { aProfile } from "../states/ui";

export default function SplashScreen(props: any) {
	const [initialized, setInitialized] = useState(false);
	const [profile] = useRecoilState(aProfile);
	const auth = getAuth(FbApp);

	useEffect(() => {
		// Ping the server to see if its online.
		auth.onAuthStateChanged((user) => {
			// Auth state changed.
			setInitialized(true);
		});
	}, []);

	if (initialized) {
		return <>{props.children}</>;
	} else {
		return <LoadingOverlay title="Loading app, please wait..." />;
	}
}
