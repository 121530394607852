import { useCallback, useEffect, useState } from "react";
import { DialogProps } from "./DialogProps";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    LinearProgress,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import ComboBox, { ComboBoxItem } from "../components/ComboBox";
import { AttachFile, Schedule, SendSharp } from "@mui/icons-material";
import {
    AnnouncelyPlatform,
    Announcement,
    AnnouncementRecipientKind,
    DiscordGuild,
} from "@announcely/models";
import { useSnackbar } from "notistack";
import { useRecoilState } from "recoil";
import { aProfile, aSelectedServer } from "../states/ui";
import { canAccessTelegram, canAccessWhatsApp } from "../core/helper";
import TextInput from "../components/TextInput";
import {
    DateTimeField,
    DateTimePicker,
    StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { FbAuth, FbStorage } from "../core/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { createAnnouncement, getChats } from "../core/rtm";
import moment from "moment";

export default function CreateAnnouncementDialog(
    props: DialogProps<Announcement>
) {
    // Data
    const [channels, setChannels] = useState<ComboBoxItem[]>();
    const [guilds, setGuilds] = useState<ComboBoxItem[]>();
    // Forms
    const [targetDiscordGuild] = useRecoilState(aSelectedServer);
    const [targetDiscordChannel, setTargetDiscordChannel] =
        useState<ComboBoxItem>();
    const [message, setMessage] = useState<string>();
    const [guildName, setGuildName] = useState<string>("");
    const [when, setWhen] = useState<"now" | "later">("now");
    const [time, setTime] = useState<any>();
    const [uploadInfo, setUploadInfo] = useState<string>();
    const [uploadState, setUploadState] = useState<
        "uploading" | "uploaded" | "error"
    >();
    const [uploadProgress, setUploadProgress] = useState(0);

    //
    const [busy, setBusy] = useState(false);
    const [profile] = useRecoilState(aProfile);
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    async function load() {
        try {
            const gs = await profile?.guilds;
            if (gs) {
                let items: ComboBoxItem[] = [];
                let i = 0;
                for (let g of gs) {
                    items.push({
                        index: i,
                        name: g.name,
                        object: g,
                    });
                    i++;
                }
                setGuilds(items);
                const g = gs.find((x) => x.id === targetDiscordGuild);
                if (g) {
                    setGuildName(g.name);
                    loadChannels(g);
                }
            }
        } catch (err: any) {
            enqueueSnackbar(
                "Error loading data. Please see logs for details.",
                {
                    variant: "error",
                }
            );
            console.log(err);
        }
    }

    async function loadChannels(g: DiscordGuild) {
        try {
            let items: ComboBoxItem[] = [];
            let i = 0;
            if (g && g.channels) {
                for (let c of g?.channels) {
                    if (c.type !== 0 && c.type !== 5) continue;
                    items.push({
                        index: i,
                        name: c.name,
                        object: c,
                    });
                    i++;
                }
            }
            setChannels(items);
        } catch (err: any) {
            enqueueSnackbar(
                "Error loading channels. Please see logs for details.",
                {
                    variant: "error",
                }
            );
            console.log(err);
        }
    }

    const isValid = useCallback(() => {
        if (!message && !uploadInfo) return false;
        if (!targetDiscordChannel) return false;
        if (!targetDiscordGuild) return false;
        if (when === "later" && !time) return false;
        return true;
    }, [
        time,
        when,
        message,
        uploadInfo,
        targetDiscordChannel,
        targetDiscordGuild,
    ]);

    async function SendOrSchedule() {
        if (!isValid()) {
            enqueueSnackbar("Please provide all the details.", {
                variant: "error",
            });
            return;
        }
        if (when === "later" && moment().isAfter(time)) {
            enqueueSnackbar(
                "The selected time has passed. Please select a different schedule.",
                { variant: "warning" }
            );
            return;
        }

        try {
            setBusy(true);
            // Create the announcement object.
            const ann: Partial<Announcement> = {
                content: message!,
                mode: when === "later" ? "schedule" : "send",
                attachment: uploadInfo,
                guildId: targetDiscordGuild!,
                guildName: guildName,
                schedule: time?.toDate()?.getTime() || undefined,
                recipient: {
                    kind: AnnouncementRecipientKind.DiscordChannel,
                    channelId: targetDiscordChannel?.object.id,
                    channelName: targetDiscordChannel?.object.name,
                },
            };
            await createAnnouncement(ann);
            enqueueSnackbar("Announcement has been created.", {
                variant: "success",
            });
            props.closeHandler();
        } catch (err: any) {
            enqueueSnackbar(
                "Error creating a new link. Please see logs for details.",
                { variant: "error" }
            );
            console.log(err);
        }
        setBusy(false);
    }

    async function uploadAttachment(attachment: File) {
        try {
            const r = ref(
                FbStorage,
                "/uploads/" + FbAuth.currentUser?.uid! + "/" + attachment.name
            );
            enqueueSnackbar("Uploading file..");
            const task = uploadBytesResumable(
                r,
                await attachment!.arrayBuffer(),
                {
                    customMetadata: {
                        uid: FbAuth.currentUser!.uid,
                    },
                }
            );
            task.on("state_changed", (snap) => {
                setUploadState("uploading");
                setUploadProgress(
                    (snap.bytesTransferred / snap.totalBytes) * 100
                );
            });
            task.then(async (t) => {
                if (t.state === "error") {
                    setUploadState("error");
                } else if (t.state === "success") {
                    const url = await getDownloadURL(task.snapshot.ref);
                    setUploadState("uploaded");
                    setUploadInfo(url);
                    enqueueSnackbar("File uploaded successfully.", {
                        variant: "success",
                    });
                }
            });
        } catch (err: any) {
            enqueueSnackbar("Error uploading file. ", { variant: "error" });
            console.log(err);
        }
    }

    useEffect(() => {
        async function f() {
            await load();
        }
        f();
    }, []);

    return (
        <Dialog
            open
            sx={{ backdropFilter: "blur(2px)" }}
            onClose={props.closeHandler}
            fullWidth
        >
            <DialogTitle>
                <Typography fontSize={20}>Announcement</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={"8px"}>
                    {/* Discord Channel. Shown in DiscordChannel kind */}
                    <Stack
                        direction={"row"}
                        sx={{ width: "100%" }}
                        spacing={"8px"}
                    >
                        <Stack flex={1}>
                            <Typography>Discord channel</Typography>
                            <ComboBox
                                disabled={busy}
                                values={channels}
                                changed={(i) => {
                                    setTargetDiscordChannel(i);
                                }}
                                value={targetDiscordChannel?.index}
                                label="Channel"
                                placeholder="Select a channel..."
                                fullWidth
                            />
                        </Stack>
                    </Stack>

                    <TextInput
                        label="Message"
                        multiline
                        rows={10}
                        value={message}
                        onUpdate={(c) => setMessage(c)}
                    />
                    <input
                        id="file-upload"
                        type="file"
                        style={{ opacity: 0 }}
                        onChange={(fe) => {
                            uploadAttachment(fe.target?.files?.[0]!);
                        }}
                    />
                    <TextInput
                        disabled={uploadState === "uploading"}
                        label="Attachment"
                        key={uploadInfo}
                        error={uploadState === "error"}
                        value={uploadInfo}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={(c) => {
                                        document
                                            .getElementById("file-upload")
                                            ?.click();
                                    }}
                                >
                                    <AttachFile />
                                </IconButton>
                            ),
                        }}
                    />
                    {uploadState === "uploading" && (
                        <LinearProgress
                            value={uploadProgress}
                            variant="determinate"
                        />
                    )}

                    <Stack>
                        <Typography>When to send</Typography>
                        <RadioGroup
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "8px",
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Radio
                                        disableRipple
                                        checked={when === "now"}
                                        onChange={(e) =>
                                            setWhen(
                                                e.currentTarget.checked
                                                    ? "now"
                                                    : "later"
                                            )
                                        }
                                        icon={<SendSharp />}
                                        checkedIcon={<SendSharp />}
                                    />
                                }
                                label="Now"
                                sx={{
                                    flex: 1,
                                    height: "60px",
                                    border:
                                        when === "now"
                                            ? "1px solid " +
                                              theme.palette.primary.main
                                            : "1px solid #AAA",
                                    py: "4px",
                                    px: "18px",
                                    borderRadius: "15px",
                                    m: 0,
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        disableRipple
                                        checked={when === "later"}
                                        onChange={(e) =>
                                            setWhen(
                                                e.currentTarget.checked
                                                    ? "later"
                                                    : "now"
                                            )
                                        }
                                        icon={<Schedule />}
                                        checkedIcon={<Schedule />}
                                    />
                                }
                                label="Schedule"
                                sx={{
                                    flex: 1,
                                    height: "60px",
                                    border:
                                        when === "later"
                                            ? "1px solid " +
                                              theme.palette.primary.main
                                            : "1px solid #AAA",
                                    py: "4px",
                                    px: "18px",
                                    borderRadius: "15px",
                                    m: 0,
                                }}
                            />
                        </RadioGroup>
                    </Stack>

                    {when === "later" && (
                        <>
                            <DateTimePicker
                                disablePast
                                value={time}
                                onChange={(v, c) => setTime(v)}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "4px",
                                        background: "#232323",
                                        border: "0px",
                                    },
                                }}
                            />
                        </>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button disabled={busy} onClick={props.closeHandler}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={SendOrSchedule}
                    disabled={!isValid() || busy || uploadState === "uploading"}
                >
                    {when === "now" ? "Send" : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
