import { BotStatus, Customer } from "@announcely/models";
import { atom } from "recoil";

export const aCurrentSection = atom({
	key: "announcely_current_section",
	default: "Dashboard",
});

export const aBotStatus = atom<BotStatus | null>({
	key: "announcely_bot_status",
	default: null,
});

export const aProfile = atom<Customer | null>({
	key: "announcely_profile",
	default: null,
});

export const aSelectedServer = atom<string | null>({
	key: "announcely_selected_server",
	default: null,
});
