import { Button, Link, Stack, Typography, useTheme } from "@mui/material";
import { LOGO_APPX } from "../assets";
import { AppConfig } from "../config";
import { Check } from "@mui/icons-material";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { aProfile } from "../states/ui";
import { Navigate, useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";
import { getProfile } from "../core/rtm";

export default function PageWaitlist() {
    const [account] = useRecoilState(aProfile);

    const theme = useTheme();
    const nav = useNavigate();

    async function load() {
        try {
            const acc = await getProfile();
            if (acc && acc.isApproved) {
                nav("/portal");
            }
        } catch (err: any) {
            enqueueSnackbar("Error loading profile. Please reload the page.", {
                variant: "error",
            });
        }
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <Stack
            spacing={"18px"}
            sx={{
                background: theme.palette.background.default,
                height: "100vh",
                overflow: "auto",
                width: "100%",
                transition: "all .5s",
            }}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <img
                src={LOGO_APPX}
                alt="Announcely Logo"
                height={128}
                style={{ alignSelf: "center" }}
                width={128}
            />
            <Typography fontSize={40} fontWeight={"bold"} textAlign={"center"}>
                You are on the waitlist! 🚀
            </Typography>
            <Typography
                fontSize={"20px"}
                sx={{ maxWidth: "600px", textAlign: "center" }}
            >
                Thank you for your patience. We are currently accepting a
                limited number of users. We will notify you once your account
                has been approved by our administrators.
            </Typography>

            <Typography>
                For more updates, join our Discord, Telegram, or Follow us on
                Twitter.
            </Typography>

            <Stack direction={"row"} spacing="8px">
                <Button
                    variant="outlined"
                    href="https://announcely.app/social/discord"
                >
                    Discord
                </Button>
                <Button
                    variant="outlined"
                    href="https://announcely.app/social/twitter"
                    color="success"
                >
                    Twitter
                </Button>
                <Button
                    variant="outlined"
                    href="https://announcely.app/social/telegram"
                    color="info"
                >
                    Telegram
                </Button>
            </Stack>

            <Typography fontSize={"12px"}>
                Keep an eye on your Inbox, and make sure to check your spam
                folder. You can also come back later to this page to check your
                account status.
            </Typography>
        </Stack>
    );
}
