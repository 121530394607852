import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { aBotStatus, aCurrentSection } from "../states/ui";
import {
    Alert,
    Button,
    Grid,
    Paper,
    Stack,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import TextInput from "../components/TextInput";
import { useSnackbar } from "notistack";
import moment from "moment";
import { FbAuth } from "../core/firebase";
import {
    EmailAuthProvider,
    reauthenticateWithCredential,
    updatePassword,
    updateProfile,
} from "firebase/auth";
import { useModal } from "mui-modal-provider";
import {
    Customer,
    DiscordBotConfiguration,
    SubscriptionKind,
    TelegramConfiguration,
    WhatsAppConfiguration,
} from "@announcely/models";
import DiscordConfigurationDialog from "../dialogs/DiscordConfiguration";
import WhatsAppConfigurationDialog from "../dialogs/WhatsAppConfiguration";
import TelegramConfigurationDialog from "../dialogs/TelegramConfiguration";
import LoadingIndicator from "../components/LoadingIndicator";
import { Info } from "@mui/icons-material";
import {
    getDiscordConfiguration,
    getProfile,
    getTelegramConfiguration,
    getWhatsAppConfiguration,
    setBranding,
} from "../core/rtm";

export default function SectionSettings() {
    const [, setCurrentSection] = useRecoilState(aCurrentSection);
    const [profile, setProfile] = useState<Customer>();
    const [discordConfig, setDiscordConfig] =
        useState<DiscordBotConfiguration>();
    const [whatsAppConfig, setWhatsAppConfig] =
        useState<WhatsAppConfiguration>();
    const [telegramConfig, setTelegramConfig] =
        useState<TelegramConfiguration>();
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [busy, setBusy] = useState(false);
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { showModal } = useModal();
    const [botStatus] = useRecoilState(aBotStatus);
    const [canConfigureWhatsApp, setCanConfigureWhatsApp] = useState(false);
    const [canConfigureTelegram, setCanConfigureTelegram] = useState(false);
    const [hasPremium, setHasPremium] = useState(false);

    /**
     * Returns true only if the user can configure the bots.
     */
    function canConfigure() {
        if (
            profile &&
            profile.billing &&
            profile.billing.subscriptions &&
            profile.billing.subscriptions.length > 0 &&
            botStatus &&
            botStatus.status === "running"
        ) {
            // If last seen is available, we compare
            if (botStatus?.lastSeen) {
                return new Date().getTime() - botStatus.lastSeen < 60000;
            }
            return true;
        }
        return false;
    }

    function getDisabilityReason() {
        if (canConfigure()) return "";
        if (!profile) return "Profile has not loaded.";
        if (!profile.billing) return "Please set up your billing account.";
        if (!profile.billing.subscriptions)
            return "You do not have any active subscription.";
        if (
            profile.billing.subscriptions[0]?.status !== "active" &&
            profile.billing.subscriptions[0]?.status !== "trialing"
        )
            return "Your subscription has expired or is invalid.";
        if (botStatus && botStatus.status === "provisioning")
            return "Provisioning service on backend, please wait.";
        if (botStatus && botStatus.status === "updating")
            return "Your instance is being updated, please wait.";
        if (botStatus && botStatus.status === "stopped")
            return "Service is not running.";
        if (botStatus && botStatus.status === "running" && botStatus.lastSeen) {
            if (new Date().getTime() - botStatus.lastSeen > 60000) {
                return "The bot is offline.";
            }
        }
        return "Please contact administrators.";
    }

    async function loadProfile() {
        try {
            setBusy(true);
            const _profile = await getProfile();
            if (_profile) {
                setProfile(_profile);
            }
            // We show data on UI from firebase that is available.
            setFullName(FbAuth.currentUser?.displayName || "");
            setEmail(FbAuth.currentUser?.email || "");
            const k = _profile?.billing?.subscriptions[0]?.kind;
            setCanConfigureTelegram(
                (k === SubscriptionKind.Aio ||
                    k === SubscriptionKind.Telegram) &&
                    _profile?.botStatus?.status === "running"
            );
            setCanConfigureWhatsApp(
                k === SubscriptionKind.Aio &&
                    _profile?.botStatus?.status === "running"
            );
            // Only extended data is shown from backend.
            const discordConfig = await getDiscordConfiguration();
            const telegramConfig = await getTelegramConfiguration();
            const whatsappConfig = await getWhatsAppConfiguration();
            if (discordConfig) setDiscordConfig(discordConfig);
            if (whatsappConfig) setWhatsAppConfig(whatsappConfig);
            if (telegramConfig) setTelegramConfig(telegramConfig);
            console.log("Got configuratioN!");

            setHasPremium(k === SubscriptionKind.Aio);
        } catch (err: any) {
            // Error loading profile.
            enqueueSnackbar("Error loading data. " + err.message, {
                variant: "error",
            });
        }
        setBusy(false);
    }

    async function changePassword() {
        if (!oldPassword) {
            enqueueSnackbar("Please enter your old password.", {
                variant: "error",
            });
            return;
        }
        if (password !== password2) {
            enqueueSnackbar("Passwords do not match.", { variant: "error" });
            return;
        }
        // Update
        try {
            setBusy(true);
            const creds = EmailAuthProvider.credential(
                profile!.email,
                oldPassword
            );
            const resp = await reauthenticateWithCredential(
                FbAuth.currentUser!,
                creds
            );
            // Proceed to update password.
            await updatePassword(resp.user, password);
            enqueueSnackbar("Your password has been updated.", {
                variant: "success",
            });
        } catch (err: any) {
            if (err.code && err.code === "auth/wrong-password") {
                enqueueSnackbar("Incorrect password. Please try again.", {
                    variant: "error",
                });
            } else {
                enqueueSnackbar(
                    "Error updating your password. Code: " + err.code,
                    {
                        variant: "error",
                    }
                );
                console.log(err);
            }
        }
        setBusy(false);
    }

    async function saveProfile() {
        if (!profile?.name) {
            enqueueSnackbar("Please enter your full name.", {
                variant: "error",
            });
            return;
        }
        // Update
        try {
            setBusy(true);
            // Proceed to update password.
            await updateProfile(FbAuth.currentUser!, {
                displayName: fullName,
            });
            enqueueSnackbar("Your profile has been updated.", {
                variant: "success",
            });
        } catch (err: any) {
            enqueueSnackbar("Error updating your profile.", {
                variant: "error",
            });
            console.log(err);
        }
        setBusy(false);
    }

    function showDiscordDialog() {
        const dlg = showModal(DiscordConfigurationDialog, {
            closeHandler() {
                dlg?.destroy();
                loadProfile();
            },
            data: discordConfig,
        });
    }

    function showWhatsAppDialog() {
        const dlg = showModal(WhatsAppConfigurationDialog, {
            closeHandler() {
                dlg?.destroy();
                loadProfile();
            },
            data: whatsAppConfig,
        });
    }

    function showTelegramDialog() {
        const dlg = showModal(TelegramConfigurationDialog, {
            closeHandler() {
                dlg?.destroy();
                loadProfile();
            },
            data: telegramConfig,
        });
    }

    function _setBranding(br: {
        embedTitle?: string;
        embedDescription?: string;
        embedAuthorName?: string;
        embedAuthorLink?: string;
        textModeFooter?: string;
        textModeHeader?: string;
    }) {
        setProfile({
            ...((profile as any) || {}),
            branding: { ...(profile?.branding || {}), ...br },
        });
    }

    async function saveBranding() {
        if (!profile) {
            enqueueSnackbar("Profile is not yet loaded.", {
                variant: "error",
            });
            return;
        }
        if (!profile.branding) {
            enqueueSnackbar("Please provide information for branding.", {
                variant: "error",
            });
            return;
        }
        // Update
        try {
            setBusy(true);
            // Save branding info
            await setBranding(profile.branding as any);
            enqueueSnackbar("Your profile has been updated.", {
                variant: "success",
            });
        } catch (err: any) {
            enqueueSnackbar("Error updating your profile.", {
                variant: "error",
            });
            console.log(err);
        }
        setBusy(false);
    }

    useEffect(() => {
        setCurrentSection("Settings");
        loadProfile();
    }, []);
    if (busy) {
        return (
            <LoadingIndicator
                background="transparent"
                title="Loading profile, please wait..."
            />
        );
    }
    return (
        <Stack>
            <Tooltip title={getDisabilityReason()} followCursor>
                <Grid
                    container
                    columns={3}
                    spacing={"24px"}
                    sx={{ p: "24px" }}
                    rowGap={"40px"}
                >
                    <Grid
                        item
                        sx={{
                            width: "350px",
                            height: "200px",
                            opacity: canConfigure() ? 1 : 0.4,
                        }}
                    >
                        <Paper
                            sx={{
                                height: "100%",
                                background: theme.palette.primary.main,
                                border: "0px solid",
                                borderColor: theme.palette.primary.main,
                                borderRadius: "15px",
                                overflow: "hidden",
                                position: "relative",
                                p: "24px",
                            }}
                        >
                            <Stack
                                spacing={"4px"}
                                sx={{
                                    position: "relative",
                                    zIndex: "1",
                                    height: "100%",
                                }}
                            >
                                <Typography fontSize={"18px"}>
                                    Discord
                                </Typography>
                                {/* The pricing part is 20px whole, and 15px for cents. */}

                                <Typography fontSize={"20px"}>
                                    {discordConfig &&
                                    discordConfig.clientId &&
                                    discordConfig.token
                                        ? "@" + discordConfig.account?.username
                                        : "Not Configured"}
                                </Typography>
                                <Typography fontSize={"15px"}>
                                    {discordConfig &&
                                    discordConfig.clientId &&
                                    discordConfig.token
                                        ? "Click the button below to change or view your discord configuration."
                                        : "Configure your discord bot to start using Announcely."}
                                </Typography>
                                <Stack flex={1} />
                                <Stack direction={"row"} spacing={"8px"}>
                                    <Button
                                        disabled={!canConfigure()}
                                        onClick={showDiscordDialog}
                                        variant="contained"
                                        sx={{
                                            background: "white",
                                            color: "black",
                                            borderRadius: "25px",
                                            alignSelf: "start",
                                            ":hover": {
                                                color: "#FFFA",
                                            },
                                        }}
                                    >
                                        Configure
                                    </Button>
                                </Stack>
                            </Stack>
                            {/* The absolute circles for design  */}
                            <div
                                style={{
                                    position: "absolute",
                                    top: "10px",
                                    left: "10px",
                                    width: "96px",
                                    height: "96px",
                                    background: "rgba(87, 8, 190, 0.79)",
                                    borderRadius: "50%",
                                }}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    top: "205px",
                                    left: "230px",
                                    width: "96px",
                                    height: "96px",
                                    background: "rgba(87, 8, 190, 0.79)",
                                    borderRadius: "50%",
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Tooltip
                        title={
                            canConfigureWhatsApp
                                ? ""
                                : canConfigure()
                                ? "Please upgrade your subscription to connect with WhatsApp."
                                : ""
                        }
                    >
                        <Grid
                            item
                            sx={{
                                width: "350px",
                                height: "200px",
                                opacity:
                                    !canConfigure() || !canConfigureWhatsApp
                                        ? 0.4
                                        : 1,
                            }}
                        >
                            <Paper
                                sx={{
                                    height: "100%",
                                    background: "transparent",
                                    border: "1px solid",
                                    borderColor: theme.palette.primary.main,
                                    borderRadius: "15px",
                                    overflow: "hidden",
                                    position: "relative",
                                    p: "24px",
                                }}
                            >
                                <Stack
                                    spacing={"4px"}
                                    sx={{
                                        position: "relative",
                                        zIndex: "1",
                                        height: "100%",
                                    }}
                                >
                                    <Typography fontSize={"18px"}>
                                        WhatsApp
                                    </Typography>
                                    {/* The pricing part is 20px whole, and 15px for cents. */}

                                    <Typography fontSize={"20px"}>
                                        {whatsAppConfig &&
                                        whatsAppConfig.account
                                            ? "+" +
                                              whatsAppConfig.account.number
                                            : "Disconnected"}
                                    </Typography>
                                    <Typography fontSize={"15px"}>
                                        {whatsAppConfig &&
                                        whatsAppConfig.account
                                            ? "View or manage your account by clicking the button below."
                                            : "Click the button below to connect your WhatsApp account."}
                                    </Typography>
                                    <Stack flex={1} />
                                    <Stack direction={"row"} spacing={"8px"}>
                                        <Button
                                            disabled={
                                                !canConfigure() ||
                                                !canConfigureWhatsApp
                                            }
                                            onClick={showWhatsAppDialog}
                                            variant="contained"
                                            sx={{
                                                background: "white",
                                                color: "black",
                                                borderRadius: "25px",
                                                alignSelf: "start",
                                                ":hover": {
                                                    color: "#FFFA",
                                                },
                                            }}
                                        >
                                            {whatsAppConfig &&
                                            whatsAppConfig.account
                                                ? "Configure"
                                                : "Connect WhatsApp"}
                                        </Button>
                                        <Button
                                            disabled={
                                                !canConfigure() ||
                                                !canConfigureWhatsApp
                                            }
                                            variant="text"
                                            sx={{
                                                display: "none",
                                                color: "#FFFA",
                                                borderRadius: "25px",
                                                alignSelf: "start",
                                                ":hover": {
                                                    color: "#FFF",
                                                },
                                            }}
                                        >
                                            Disconnect
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Tooltip>
                    <Tooltip
                        title={
                            canConfigureTelegram
                                ? ""
                                : canConfigure()
                                ? "Please upgrade your subscription to connect with Telegram."
                                : ""
                        }
                    >
                        <Grid
                            item
                            sx={{
                                width: "350px",
                                height: "200px",
                                opacity:
                                    !canConfigure() || !canConfigureTelegram
                                        ? 0.4
                                        : 1,
                            }}
                        >
                            <Paper
                                sx={{
                                    height: "100%",
                                    background: "transparent",
                                    border: "1px solid",
                                    borderColor: theme.palette.primary.main,
                                    borderRadius: "15px",
                                    overflow: "hidden",
                                    position: "relative",
                                    p: "24px",
                                }}
                            >
                                <Stack
                                    spacing={"4px"}
                                    sx={{
                                        position: "relative",
                                        zIndex: "1",
                                        height: "100%",
                                    }}
                                >
                                    <Typography fontSize={"18px"}>
                                        Telegram
                                    </Typography>
                                    {/* The pricing part is 20px whole, and 15px for cents. */}

                                    <Typography fontSize={"20px"}>
                                        {telegramConfig &&
                                        telegramConfig.account
                                            ? "@" +
                                              telegramConfig.account.username
                                            : "Connect your Telegram"}
                                    </Typography>
                                    <Typography fontSize={"15px"}>
                                        {telegramConfig &&
                                        telegramConfig.account
                                            ? "View or manage your account by clicking the button below."
                                            : "Connect your Telegram by clicking on button below."}
                                    </Typography>
                                    <Stack flex={1} />
                                    <Stack direction={"row"} spacing={"8px"}>
                                        <Button
                                            disabled={
                                                !canConfigure() ||
                                                !canConfigureTelegram
                                            }
                                            onClick={showTelegramDialog}
                                            variant="contained"
                                            sx={{
                                                background: "white",
                                                color: "black",
                                                borderRadius: "25px",
                                                alignSelf: "start",
                                                ":hover": {
                                                    color: "#FFFA",
                                                },
                                            }}
                                        >
                                            {telegramConfig &&
                                            telegramConfig.account
                                                ? "Configure"
                                                : "Connect Telegram"}
                                        </Button>
                                        <Button
                                            disabled={
                                                !canConfigure() ||
                                                !canConfigureTelegram
                                            }
                                            variant="text"
                                            sx={{
                                                display: "none",
                                                color: "#FFFA",
                                                borderRadius: "25px",
                                                alignSelf: "start",
                                                ":hover": {
                                                    color: "#FFF",
                                                },
                                            }}
                                        >
                                            Disconnect
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Grid>
                    </Tooltip>
                </Grid>
            </Tooltip>

            <Stack
                flex={1}
                sx={{ p: "24px", pt: "48px", overflow: "hidden" }}
                spacing={"8px"}
            >
                <Typography fontSize={"18px"} color={"#A5A5A5"}>
                    Profile
                </Typography>
                <Stack direction={"row"} sx={{ width: "100%" }} spacing={"8px"}>
                    <Stack spacing={"8px"} flex={1}>
                        <Typography>Update Password</Typography>
                        <TextInput
                            type="password"
                            label="Current Password"
                            value={oldPassword}
                            onUpdate={(c) => setOldPassword(c)}
                        />
                        <TextInput
                            type="password"
                            label="New Password"
                            value={password}
                            onUpdate={(c) => setPassword(c)}
                        />
                        <TextInput
                            type="password"
                            label="Confirm Password"
                            value={password2}
                            onUpdate={(c) => setPassword2(c)}
                            helperText="Password must be atleast 6 characters long."
                        />
                        <Button
                            onClick={changePassword}
                            disabled={busy}
                            variant="contained"
                            sx={{
                                background: "white",
                                color: "black",
                                borderRadius: "25px",
                                alignSelf: "start",
                                ":hover": {
                                    color: "#FFFA",
                                },
                            }}
                        >
                            Change Password
                        </Button>
                    </Stack>
                    <Stack spacing={"8px"} flex={1}>
                        <Typography>Personal Information</Typography>
                        <TextInput
                            key={profile?.name}
                            label="Name"
                            value={fullName}
                            onUpdate={(c) => setFullName(c)}
                        />
                        <TextInput
                            key={profile?.email}
                            label="Email"
                            value={email}
                            disabled
                        />
                        {FbAuth.currentUser?.emailVerified === false && (
                            <Typography sx={{ opacity: ".7" }} color={"error"}>
                                Unverified. Please verify your email by clicking
                                link sent to your email.
                            </Typography>
                        )}
                        <Button
                            disabled={busy}
                            onClick={saveProfile}
                            variant="contained"
                            sx={{
                                background: "white",
                                color: "black",
                                borderRadius: "25px",
                                alignSelf: "start",
                                ":hover": {
                                    color: "#FFFA",
                                },
                            }}
                        >
                            Save
                        </Button>
                        {profile && (
                            <>
                                <Stack direction={"row"} spacing={"4px"}>
                                    <Typography sx={{ opacity: ".7" }}>
                                        Registered at{" "}
                                    </Typography>
                                    <Typography>
                                        {moment(
                                            new Date(profile?.dateRegistered)
                                        ).format("DD/MM/YYYY HH:mm:ss")}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={"4px"}>
                                    <Typography sx={{ opacity: ".7" }}>
                                        Last sign in{" "}
                                    </Typography>
                                    <Typography>
                                        {moment(
                                            new Date(profile?.lastSignIn)
                                        ).format("DD/MM/YYYY HH:mm:ss")}
                                    </Typography>
                                </Stack>
                            </>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}
