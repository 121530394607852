import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { aCurrentSection, aProfile, aSelectedServer } from "../states/ui";
import {
    Avatar,
    Divider,
    Grid,
    Paper,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import AnalyticsCard from "../components/AnalyticsCard";
import {
    Message,
    People,
    Person,
    Person2,
    RecentActors,
    SpeakerNotes,
    Telegram,
    WhatsApp,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { GetAnalyticsData } from "../core/rest";
import moment from "moment-timezone";
import RangePicker from "../components/RangePicker";
import {
    Bar,
    BarChart,
    Legend,
    Rectangle,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip as ChartTooltip,
} from "recharts";
import { hasPremium } from "../core/helper";
import DataCard from "../components/DataCard";

export default function SectionAnalytics() {
    const [busy, setBusy] = useState(false);
    const [, setCurrentSection] = useRecoilState(aCurrentSection);
    const [selectedServer] = useRecoilState(aSelectedServer);
    const [profile] = useRecoilState(aProfile);
    const { enqueueSnackbar } = useSnackbar();
    const [membersChart, setMembersChart] =
        useState<{ members: number; day: string }[]>();
    const [reachChart, setReachChart] =
        useState<{ delivered: number; day: string }[]>();
    const [range, setRange] = useState<{ start: Date; end?: Date }>();
    const [chartSorting, setChartSorting] = useState("whatsapp_messages");
    const [data, setData] = useState<{
        insights: {
            members: number;
            joined: number;
            communicators: number;
            visitors: number;
            messages: number;
            totalDelivered?: number;
        };
        members: {
            labels: number[];
            values: number[];
        };
        topMembers: {
            [key: string]: {
                messages: number;
                avatar: string;
                username: string;
            };
        };
        topChannels: {
            channelId: string;
            channelName: string;
            telegram_messages: number;
            whatsapp_messages: number;
        }[];
    }>();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    async function load() {
        setBusy(true);
        try {
            if (selectedServer && profile) {
                const _data = await GetAnalyticsData(
                    selectedServer,
                    range?.start.toISOString() ||
                        moment().subtract(30, "days").toDate().toISOString(),
                    range?.end
                        ? moment(range.end).endOf("day").toDate().toISOString()
                        : moment().endOf("day").toDate().toISOString(),
                    userTimeZone
                );
                setData(_data);
                // Build the members chart.
                const _chartData: { day: string; members: number }[] = [];
                let x = 0;
                for (let d of _data?.members?.values || []) {
                    const day = moment(
                        new Date(_data!.members.labels[x])
                    ).format("MMM DD");
                    _chartData.push({
                        day: day,
                        members: d,
                    });
                    x += 1;
                }
                setMembersChart(_chartData);
                // Build the reach chart.
                x = 0;
                const _reachData: {
                    day: string;
                    delivered: number;
                }[] = [];
                let _totalDelivered = 0;
                for (let r of _data?.reach.labels || []) {
                    const day = moment(new Date(r)).format("MMM DD");
                    _reachData.push({
                        day: day,
                        delivered: _data!.reach.delivered[x],
                    });
                    x += 1;
                    _totalDelivered += _data?.reach.delivered[x] || 0;
                }
                setReachChart(_reachData);
                setData({
                    ..._data!,
                    insights: {
                        ...(_data?.insights || ({} as any)),
                        totalDelivered: _totalDelivered,
                    },
                });
            }
        } catch (err: any) {
            enqueueSnackbar(
                "Error loading data. Please see logs for details.",
                {
                    variant: "error",
                }
            );
            console.log("ERROR loading data.");
            console.log(err);
        }
        setBusy(false);
    }

    useEffect(() => {
        load();
    }, [selectedServer, range]);

    useEffect(() => {
        setCurrentSection("Analytics");
    }, []);

    return (
        <Grid
            container
            columns={8}
            sx={{ p: "24px", opacity: busy ? 0.2 : 1, transition: "all .4s" }}
            spacing={"24px"}
            columnSpacing={"28px"}
            justifyContent={"flex-start"}
        >
            <Grid item xs={8}>
                <Stack
                    spacing="8px"
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    direction={"row"}
                >
                    <Typography fontFamily={"28px"}>Key Insights</Typography>
                    <div
                        style={{
                            width: "1px",
                            opacity: 0.7,
                            background: "#FFFA",
                            height: "20px",
                        }}
                    />
                    <RangePicker
                        onChange={(r) => {
                            setRange(r);
                        }}
                    />
                </Stack>
            </Grid>

            <Grid item xs={8}>
                <Stack
                    direction={"row"}
                    columnGap={"28px"}
                    rowGap={"24px"}
                    flexWrap={"wrap"}
                >
                    <AnalyticsCard
                        label="Total Members"
                        color="#88F"
                        icon={<People />}
                        value={data?.insights?.members?.toString() || "N/A"}
                    />
                    <AnalyticsCard
                        label="New Members"
                        color="#88F"
                        icon={<RecentActors />}
                        value={data?.insights?.joined?.toString() || "N/A"}
                    />
                    <AnalyticsCard
                        label="Communicators"
                        color="#88F"
                        value={
                            data?.insights?.communicators?.toString() || "N/A"
                        }
                        icon={<SpeakerNotes />}
                    />
                    <AnalyticsCard
                        label="Visitors"
                        color="#88F"
                        value={data?.insights?.visitors?.toString() || "N/A"}
                        icon={<Person2 />}
                    />
                    <AnalyticsCard
                        label="Messages"
                        color="#88F"
                        value={data?.insights?.messages?.toString() || "N/A"}
                        icon={<Message />}
                    />
                </Stack>
            </Grid>

            <Grid item xs={2}>
                <Paper elevation={0} sx={{ height: "400px", p: "26px" }}>
                    <Stack sx={{ height: "100%" }}>
                        <Typography fontSize={"14px"}>Top</Typography>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={"18px"}
                        >
                            <Typography fontSize={"18px"} fontWeight={"bold"}>
                                Members
                            </Typography>
                        </Stack>
                        <Divider sx={{ my: "8px" }} />
                        <Stack flex={1} spacing={"8px"}>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                            >
                                <Typography
                                    fontWeight={"bold"}
                                    fontSize={"16px"}
                                >
                                    Username
                                </Typography>
                                <Tooltip title="Messages sent">
                                    <Message />
                                </Tooltip>
                            </Stack>
                            {Object.entries(data?.topMembers || {})
                                .sort((a, b) => b[1].messages - a[1].messages)
                                .map((kv) => (
                                    <Stack
                                        direction={"row"}
                                        justifyContent={"space-between"}
                                    >
                                        <Stack
                                            direction={"row"}
                                            alignItems={"center"}
                                            spacing="8px"
                                        >
                                            <Avatar
                                                src={kv[1].avatar}
                                                sx={{
                                                    width: "24px",
                                                    height: "24px",
                                                }}
                                            />
                                            <Typography>
                                                {kv[1].username}
                                            </Typography>
                                        </Stack>
                                        <Typography>
                                            {kv[1].messages}
                                        </Typography>
                                    </Stack>
                                ))}
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>

            <Grid item xs={6}>
                <Paper elevation={0} sx={{ height: "400px", p: "26px" }}>
                    <Stack sx={{ height: "100%" }}>
                        <Typography fontSize={"14px"}>History</Typography>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={"18px"}
                        >
                            <Typography fontSize={"18px"} fontWeight={"bold"}>
                                Members
                            </Typography>
                            <Typography fontSize={"14px"}>
                                {moment(range?.start).format("MMM DD, YYYY")} to{" "}
                                {moment(range?.end).format("MMM DD, YYYY")}
                            </Typography>
                        </Stack>
                        <Divider sx={{ my: "8px" }} />
                        <Stack flex={1}>
                            {data?.members && (
                                <ResponsiveContainer
                                    width={"100%"}
                                    height={"100%"}
                                >
                                    <BarChart data={membersChart}>
                                        <defs>
                                            <linearGradient
                                                id="grad1"
                                                x1="0%"
                                                y1="0%"
                                                x2="0%"
                                                y2="100%"
                                            >
                                                <stop
                                                    offset="0%"
                                                    style={{
                                                        stopColor: "#6D3AFF",
                                                    }}
                                                />
                                                <stop
                                                    offset="100%"
                                                    style={{
                                                        stopColor: "#4A3AFF",
                                                    }}
                                                />
                                            </linearGradient>
                                        </defs>
                                        <ChartTooltip
                                            cursor={false}
                                            contentStyle={{
                                                background: "#303030",
                                                border: "0px solid #707070",
                                                borderRadius: "8px",
                                                fontFamily: "Poppins",
                                                boxShadow:
                                                    "0px 0px 5px 0px #4488FF",
                                            }}
                                            itemStyle={{
                                                fontFamily: "Poppins",
                                                textTransform: "capitalize",
                                                color: "#BBB",
                                            }}
                                            content={({ payload, label }) => {
                                                return (
                                                    <Stack
                                                        spacing={"16px"}
                                                        sx={{
                                                            width: "100%",
                                                            background:
                                                                "#202020",
                                                            px: "14px",
                                                            py: "8px",
                                                            borderRadius: "8px",
                                                            border: "1px solid #88F",
                                                        }}
                                                        justifyContent={
                                                            "center"
                                                        }
                                                        alignItems={"start"}
                                                    >
                                                        <Typography>
                                                            {label}
                                                        </Typography>
                                                        {payload?.map((p) => {
                                                            return (
                                                                <Stack
                                                                    spacing={
                                                                        "8px"
                                                                    }
                                                                    direction={
                                                                        "row"
                                                                    }
                                                                    alignItems={
                                                                        "center"
                                                                    }
                                                                    justifyContent={
                                                                        "space-between"
                                                                    }
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            borderRadius:
                                                                                "25px",
                                                                            background:
                                                                                "linear-gradient(180deg, #6D3AFF, #4A3AFF)",
                                                                        }}
                                                                    />
                                                                    <Typography>
                                                                        {
                                                                            p.value
                                                                        }
                                                                    </Typography>
                                                                    <Typography>
                                                                        Members
                                                                    </Typography>
                                                                </Stack>
                                                            );
                                                        })}
                                                    </Stack>
                                                );
                                            }}
                                        />
                                        <YAxis
                                            axisLine={false}
                                            tickLine={false}
                                        />
                                        <XAxis
                                            dataKey={"day"}
                                            axisLine={false}
                                            tickLine={false}
                                        />
                                        <Bar
                                            spacing={"10px"}
                                            dataKey={"members"}
                                            stackId={"a"}
                                            background={
                                                <Rectangle
                                                    fill="#FFF2"
                                                    radius={18}
                                                />
                                            }
                                            enableBackground={"true"}
                                            radius={18}
                                            barSize={12}
                                            style={{
                                                fill: "url(#grad1)",
                                            }}
                                        />
                                        <Legend
                                            content={({ payload }) => {
                                                return (
                                                    <Stack
                                                        spacing={"16px"}
                                                        direction={"row"}
                                                        sx={{ width: "100%" }}
                                                        justifyContent={
                                                            "center"
                                                        }
                                                        alignItems={"center"}
                                                    >
                                                        {payload?.map((p) => {
                                                            return (
                                                                <Stack
                                                                    spacing={
                                                                        "8px"
                                                                    }
                                                                    direction={
                                                                        "row"
                                                                    }
                                                                    alignItems={
                                                                        "center"
                                                                    }
                                                                    justifyContent={
                                                                        "center"
                                                                    }
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            borderRadius:
                                                                                "25px",
                                                                            background:
                                                                                "linear-gradient(180deg, #6D3AFF, #4A3AFF)",
                                                                        }}
                                                                    />
                                                                    <Typography>
                                                                        Member
                                                                        Count
                                                                    </Typography>
                                                                </Stack>
                                                            );
                                                        })}
                                                    </Stack>
                                                );
                                            }}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            )}
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>

            <Grid item xs={2}>
                <DataCard
                    heading1="Top"
                    heading2="Channels"
                    heading3={
                        chartSorting === "subscribers"
                            ? "by subscribers"
                            : chartSorting === "whatsapp_messages"
                            ? "by whatsapp messages"
                            : "by telegram messages"
                    }
                    data={data?.topChannels || []}
                    columnHeaders={[
                        <Typography># Channel</Typography>,
                        <Tooltip title="WhatsApp Messages">
                            <WhatsApp
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                    setChartSorting("whatsapp_messages")
                                }
                                color={
                                    chartSorting === "whatsapp_messages"
                                        ? "primary"
                                        : undefined
                                }
                            />
                        </Tooltip>,
                        <Tooltip title="Telegram Messages">
                            <Telegram
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                    setChartSorting("telegram_messages")
                                }
                                color={
                                    chartSorting === "telegram_messages"
                                        ? "primary"
                                        : undefined
                                }
                            />
                        </Tooltip>,
                        <Tooltip title="Total Subscribers">
                            <Person
                                sx={{ cursor: "pointer" }}
                                onClick={() => setChartSorting("subscribers")}
                                color={
                                    chartSorting === "subscribers"
                                        ? "primary"
                                        : undefined
                                }
                            />
                        </Tooltip>,
                    ]}
                    labelKey="channelName"
                    sortBy={chartSorting}
                    valueKeys={[
                        "whatsapp_messages",
                        "telegram_messages",
                        "subscribers",
                    ]}
                />
            </Grid>

            <Grid item xs={6}>
                <Paper elevation={0} sx={{ height: "400px", p: "26px" }}>
                    <Stack sx={{ height: "100%" }}>
                        <Typography fontSize={"14px"}>Reach</Typography>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={"18px"}
                        >
                            <Typography fontSize={"18px"} fontWeight={"bold"}>
                                Messages Delivered:{" "}
                                {data?.insights.totalDelivered}
                            </Typography>
                            <Typography fontSize={"14px"}>
                                {moment(range?.start).format("MMM DD, YYYY")} to{" "}
                                {moment(range?.end).format("MMM DD, YYYY")}
                            </Typography>
                        </Stack>
                        <Divider sx={{ my: "8px" }} />
                        <Stack flex={1}>
                            {data && (
                                <ResponsiveContainer
                                    width={"100%"}
                                    height={"100%"}
                                >
                                    <BarChart data={reachChart}>
                                        <defs>
                                            <linearGradient
                                                id="grad1"
                                                x1="0%"
                                                y1="0%"
                                                x2="0%"
                                                y2="100%"
                                            >
                                                <stop
                                                    offset="0%"
                                                    style={{
                                                        stopColor: "#6D3AFF",
                                                    }}
                                                />
                                                <stop
                                                    offset="100%"
                                                    style={{
                                                        stopColor: "#4A3AFF",
                                                    }}
                                                />
                                            </linearGradient>
                                        </defs>
                                        <ChartTooltip
                                            cursor={false}
                                            contentStyle={{
                                                background: "#303030",
                                                border: "0px solid #707070",
                                                borderRadius: "8px",
                                                fontFamily: "Poppins",
                                                boxShadow:
                                                    "0px 0px 5px 0px #4488FF",
                                            }}
                                            itemStyle={{
                                                fontFamily: "Poppins",
                                                textTransform: "capitalize",
                                                color: "#BBB",
                                            }}
                                            content={({ payload, label }) => {
                                                return (
                                                    <Stack
                                                        spacing={"16px"}
                                                        sx={{
                                                            width: "100%",
                                                            background:
                                                                "#202020",
                                                            px: "14px",
                                                            py: "8px",
                                                            borderRadius: "8px",
                                                            border: "1px solid #88F",
                                                        }}
                                                        justifyContent={
                                                            "center"
                                                        }
                                                        alignItems={"start"}
                                                    >
                                                        <Typography>
                                                            {label}
                                                        </Typography>
                                                        {payload?.map((p) => {
                                                            return (
                                                                <Stack
                                                                    spacing={
                                                                        "8px"
                                                                    }
                                                                    direction={
                                                                        "row"
                                                                    }
                                                                    alignItems={
                                                                        "center"
                                                                    }
                                                                    justifyContent={
                                                                        "space-between"
                                                                    }
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            borderRadius:
                                                                                "25px",
                                                                            background:
                                                                                "linear-gradient(180deg, #6D3AFF, #4A3AFF)",
                                                                        }}
                                                                    />
                                                                    <Typography>
                                                                        {
                                                                            p.value
                                                                        }
                                                                    </Typography>
                                                                    <Typography>
                                                                        Delivered
                                                                    </Typography>
                                                                </Stack>
                                                            );
                                                        })}
                                                    </Stack>
                                                );
                                            }}
                                        />
                                        <YAxis
                                            axisLine={false}
                                            tickLine={false}
                                        />
                                        <XAxis
                                            dataKey={"day"}
                                            axisLine={false}
                                            tickLine={false}
                                        />
                                        <Bar
                                            spacing={"10px"}
                                            dataKey={"delivered"}
                                            stackId={"a"}
                                            background={
                                                <Rectangle
                                                    fill="#FFF2"
                                                    radius={18}
                                                />
                                            }
                                            enableBackground={"true"}
                                            radius={18}
                                            barSize={12}
                                            style={{
                                                fill: "url(#grad1)",
                                            }}
                                        />
                                        <Legend
                                            content={({ payload }) => {
                                                return (
                                                    <Stack
                                                        spacing={"16px"}
                                                        direction={"row"}
                                                        sx={{ width: "100%" }}
                                                        justifyContent={
                                                            "center"
                                                        }
                                                        alignItems={"center"}
                                                    >
                                                        {payload?.map((p) => {
                                                            return (
                                                                <Stack
                                                                    spacing={
                                                                        "8px"
                                                                    }
                                                                    direction={
                                                                        "row"
                                                                    }
                                                                    alignItems={
                                                                        "center"
                                                                    }
                                                                    justifyContent={
                                                                        "center"
                                                                    }
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            borderRadius:
                                                                                "25px",
                                                                            background:
                                                                                "linear-gradient(180deg, #6D3AFF, #4A3AFF)",
                                                                        }}
                                                                    />
                                                                    <Typography>
                                                                        Delivered
                                                                    </Typography>
                                                                </Stack>
                                                            );
                                                        })}
                                                    </Stack>
                                                );
                                            }}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            )}
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    );
}
