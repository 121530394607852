import React, { useEffect, useMemo, useState } from "react";
import {
    Alert,
    Button,
    Chip,
    IconButton,
    Link,
    Paper,
    Stack,
    Switch,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import {
    AnnouncelyPricing,
    BotCommand,
    DiscordBotConfiguration,
    OnboardingStep,
    SubscriptionKind,
    TelegramConfiguration,
    WhatsAppConfiguration,
} from "@announcely/models";
import {
    Analytics,
    ArrowBack,
    BrandingWatermark,
    Campaign,
    Check,
    Discount,
    Info,
    LinkSharp,
    Send,
    Telegram,
    Warning,
    WhatsApp,
} from "@mui/icons-material";
import {
    IC_DISCORD,
    IC_PREMIUM,
    IC_STRIPE,
    IC_TELEGRAM,
    IC_WHATSAPP,
    LOGO_APPX,
} from "../assets";
import { Player } from "@lottiefiles/react-lottie-player";
import { AppConfig } from "../config";
import { enqueueSnackbar } from "notistack";
import {
    hasAnySubscription,
    hasTelegramSubscription,
    hasWhatsAppSubscription,
} from "../core/helper";
import { useRecoilState } from "recoil";
import { aBotStatus, aProfile } from "../states/ui";
import QRCode from "react-qr-code";
import TextInput from "../components/TextInput";
import { useNavigate } from "react-router";
import ComboBox, { ComboBoxItem } from "../components/ComboBox";
import {
    finishOnboarding,
    getCheckout,
    getDiscordConfiguration,
    getPricing,
    getProfile,
    getTelegramConfiguration,
    getWhatsAppConfiguration,
    setCommand,
    setReferral,
    waitForCommandResult,
} from "../core/rtm";
import moment from "moment";
import { signOut } from "firebase/auth";
import { FbAuth } from "../core/firebase";

export default function PageOnboarding() {
    const [discordClientId, setDiscordClientId] = useState("");
    const [discordToken, setDiscordToken] = useState("");
    const [telegramToken, setTelegramToken] = useState("");
    const [referralCode, setReferralCode] = useState("");
    const [discordServer, setDiscordServer] = useState<ComboBoxItem>();
    const [discordServers, setDiscordServers] = useState<ComboBoxItem[]>([]);

    const [discordCommandExecuted, setDiscordCommandExecuted] = useState(false);
    const [telegramCommandExecuted, setTelegramCommandExecuted] =
        useState(false);

    const [busy, setBusy] = useState(false);
    const [yearly, setYearly] = useState(false);
    const [profile, setProfile] = useRecoilState(aProfile);
    const [botStatus, setBotStatus] = useRecoilState(aBotStatus);
    const [whatsappConfig, setWhatsAppConfig] =
        useState<WhatsAppConfiguration>();
    const [telegramConfig, setTelegramConfig] =
        useState<TelegramConfiguration>();
    const [discordConfig, setDiscordConfig] =
        useState<DiscordBotConfiguration>();
    const [step, setStep] = useState(
        window.location.hash.includes("checked_out=true")
            ? OnboardingStep.Discord
            : profile?.onboardingStep || OnboardingStep.Welcome
    );
    const [pricing, setPricing] = useState<AnnouncelyPricing>();
    // In  config step, there are several screens.
    const [screen, setScreen] = useState<
        "main" | "discord" | "whatsapp" | "telegram"
    >("main");
    const [selectedPlan, setSelectedPlan] = useState<
        "whatsapp" | "telegram" | "premium"
    >();
    const nav = useNavigate();
    const theme = useTheme();

    const isBotOnline = useMemo(() => {
        if (
            botStatus &&
            botStatus?.lastSeen &&
            botStatus.status === "running"
        ) {
            console.log(botStatus);
            return (
                moment.tz("UTC").toDate().getTime() - botStatus!.lastSeen <
                60000
            );
        }
        return false;
    }, [botStatus]);

    async function saveReferral() {
        try {
            setBusy(true);
            // Set the referral code
            await setReferral(referralCode || "0000000");
            const prof = await getProfile();
            if (prof) setProfile(prof);
        } catch (err: any) {
            enqueueSnackbar(
                "Error saving data. Please contact administrators.",
                {
                    variant: "error",
                }
            );
            console.log(err);
        }
        setBusy(false);
    }

    // Called when continue button is clicked on first step. This checks if the user has any subscription yet.
    // If they do, the billing step is ignored.
    async function startSetup() {
        try {
            setBusy(true);
            const prof = await getProfile();
            if (prof) {
                // Check if any plan is activated.
                if (hasAnySubscription(prof)) {
                    // Get out of here and go to next step.
                    setStep(OnboardingStep.Discord);
                } else {
                    // Load pricing.
                    const _pricing = await getPricing();
                    if (_pricing) setPricing(_pricing);
                    setStep(OnboardingStep.Billing);
                }
            }
        } catch (err: any) {
            enqueueSnackbar(
                "Error loading profile. Please try again or contact administrators.",
                { variant: "error" }
            );
            console.log(err);
        }
        setBusy(false);
    }

    // Called when clicked on activateTrial button.
    async function activateTrial() {
        try {
            setBusy(true);
            const sess = await getCheckout(
                yearly ? "yearly" : "monthly",
                selectedPlan === "premium"
                    ? SubscriptionKind.Aio
                    : SubscriptionKind.Telegram
            );
            // Open the checkout session
            window.location = sess.url;
        } catch (err: any) {
            enqueueSnackbar(
                "Error creating checkout session. Please try again or if the issue persists, contact administrators.",
                { variant: "error" }
            );
            console.log(err);
            setBusy(false);
        }
    }

    let alreadySyncing = false;
    async function getBotConfigs() {
        console.log("Getting bot config");
        if (alreadySyncing) return;
        alreadySyncing = true;
        try {
            const pf = await getProfile();
            if (pf) {
                setProfile(pf);
                setBotStatus(pf.botStatus as any);
            }
            const dfg = await getDiscordConfiguration();
            if (dfg) {
                setDiscordConfig(dfg);
            }
            const cfg = await getWhatsAppConfiguration();
            if (cfg) {
                setWhatsAppConfig(cfg);
            }
            const tgCfg = await getTelegramConfiguration();
            if (tgCfg) {
                setTelegramConfig(tgCfg);
            }
        } catch (err: any) {}
        alreadySyncing = false;
    }

    async function saveDiscord() {
        try {
            setBusy(true);
            // Send the command to the bot, with new configuration.
            await setCommand({
                executed: false,
                kind: BotCommand.StartDiscord,
                input: {
                    token: discordToken,
                    clientId: discordClientId,
                },
            });
            // Wait for result.
            const resp = await waitForCommandResult();
            // Check response
            if (resp && resp.executed) {
                // Command has been executed.
                if (resp.result?.success) {
                    // Logged in.
                    enqueueSnackbar("Successfully configured discord.", {
                        variant: "success",
                    });
                    setDiscordCommandExecuted(true);
                } else {
                    enqueueSnackbar(
                        "Error configuring discord. Message: " +
                            resp.result?.data,
                        {
                            variant: "error",
                        }
                    );
                    console.log("Command failed to run successfully. ");
                    console.log(resp);
                }
            } else if (resp) {
                enqueueSnackbar(
                    "Command execution failed. " + resp.result?.data,
                    {
                        variant: "error",
                    }
                );
                console.log("Command executing failed. Command: ");
                console.log(resp);
            }
        } catch (err: any) {
            enqueueSnackbar(
                "Error applying configuration. See logs for details.",
                {
                    variant: "error",
                }
            );
            console.log("Command executing failed. ERR: ");
            console.log(err);
        }
        setBusy(false);
    }

    async function saveTelegram() {
        try {
            setBusy(true);
            await setCommand({
                executed: false,
                kind: BotCommand.StartTelegram,
                input: {
                    loginOrToken: telegramToken,
                },
            });
            const resp = await waitForCommandResult();
            if (resp && resp.executed) {
                // Command has been executed.
                if (resp.result?.success) {
                    // Logged in.
                    enqueueSnackbar("Successfully configured telegram.", {
                        variant: "success",
                    });
                    setTelegramCommandExecuted(true);
                } else {
                    enqueueSnackbar(
                        "Error configuring telegram. Message: " +
                            resp.result?.data?.message,
                        {
                            variant: "error",
                        }
                    );
                    console.log("Command failed to run successfully. ");
                    console.log(resp);
                }
            } else if (resp) {
                enqueueSnackbar(
                    "Command execution failed. " + resp.result?.data,
                    {
                        variant: "error",
                    }
                );
                console.log("Command executing failed. Command: ");
                console.log(resp);
            }
        } catch (err: any) {
            enqueueSnackbar(
                "Error applying configuration. See logs for details.",
                {
                    variant: "error",
                }
            );
            console.log("Command executing failed. ERR: ");
            console.log(err);
        }
        setBusy(false);
    }

    function isDiscordInputsValid() {
        return Boolean(discordToken && discordToken);
    }

    async function finish() {
        // Save the boarding to profile
        try {
            if (!discordServer) {
                enqueueSnackbar(
                    "Please select the discord server to connect with your account.",
                    { variant: "error" }
                );
                return;
            }
            setBusy(true);
            await finishOnboarding(discordServer.object);
            // Get profile again
            const prof = await getProfile();
            if (prof) {
                setProfile(prof);
            }
            enqueueSnackbar(
                "Welcome to Announcely. We hope to bring joy to your awesome community!",
                { variant: "success" }
            );
            nav("/portal");
        } catch (err: any) {
            enqueueSnackbar(
                "Error saving configuration. See logs for details.",
                {
                    variant: "error",
                }
            );
            console.log(err);
        }
    }

    useEffect(() => {
        let t: any;
        //  getBotConfigs();
        getBotConfigs().then(() => {
            setBusy(false);
            t = setInterval(getBotConfigs, 5000);
            return () => clearInterval(t);
        });
    }, []);

    useEffect(() => {
        // When guilds are updated
        if (!profile) return;
        const items: ComboBoxItem[] = [];
        for (let g of profile!.all_guilds || []) {
            items.push({
                index: items.length,
                name: g.name,
                object: g,
            });
        }
        setDiscordServers(items);
    }, [profile?.guilds]);

    useEffect(() => {
        if (profile?.onboardingStep === OnboardingStep.Finished) {
            nav("/portal");
        }
    });

    // The welcome page.
    if (step === OnboardingStep.Welcome) {
        return (
            <Stack
                spacing={"18px"}
                sx={{
                    background: theme.palette.background.default,
                    height: "100vh",
                    overflow: "auto",
                    width: "100%",
                    transition: "all .5s",
                }}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <img
                    src={LOGO_APPX}
                    alt="Announcely Logo"
                    height={128}
                    style={{ alignSelf: "center" }}
                    width={128}
                />
                <Typography
                    fontSize={40}
                    fontWeight={"bold"}
                    textAlign={"center"}
                >
                    Welcome to Announcely!
                </Typography>

                <Stack
                    spacing={"8px"}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Check color="primary" />
                    <Typography fontSize={"20px"}>
                        No credits, no hassle. With every subscription, you get
                        unlimited access.
                    </Typography>
                </Stack>

                <Stack
                    spacing={"8px"}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Check color="primary" />
                    <Typography fontSize={"20px"}>
                        Seamlessly integrate your favorite platforms. Easily
                        connect your Discord, WhatsApp, and Telegram.
                    </Typography>
                </Stack>

                <Stack
                    spacing={"8px"}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Check color="primary" />
                    <Typography fontSize={"20px"}>
                        Mirror your discord channels to WhatsApp and Telegram,
                        with ease.
                    </Typography>
                </Stack>

                <Stack
                    spacing={"8px"}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Check color="primary" />
                    <Typography fontSize={"20px"}>
                        Get insights on your platforms with analytics.
                    </Typography>
                </Stack>

                <Stack
                    spacing={"8px"}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Check color="primary" />
                    <Typography fontSize={"20px"}>
                        Reach your community members on perfect time using
                        Scheduled Announcements.
                    </Typography>
                </Stack>

                <Stack
                    spacing={"8px"}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Check color="primary" />
                    <Typography fontSize={"20px"}>And so much more!</Typography>
                </Stack>

                {!busy && (
                    <Button
                        onClick={startSetup}
                        variant="contained"
                        size="large"
                        sx={{
                            borderRadius: 1,
                            width: "200px",
                            height: "50px",
                        }}
                    >
                        Continue
                    </Button>
                )}

                {busy && (
                    <Player
                        autoplay
                        loop
                        src="https://lottie.host/1d29df75-d07f-4bb8-a3af-e1a1123a14d8/6YGpRhoctT.json"
                        style={{ height: "120px" }}
                    />
                )}

                <Typography fontSize={"12px"}>
                    By clicking continue, you agree to Announcely's
                    <Link
                        component={"a"}
                        target="_blank"
                        href={AppConfig.links.terms}
                    >
                        {" "}
                        Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link
                        component={"a"}
                        target="_blank"
                        href={AppConfig.links.privacyPolicy}
                    >
                        Privacy Policy
                    </Link>
                    .
                </Typography>
            </Stack>
        );
    }

    // If step is NOT welcome, and if referrer is not set, then we ask for referral. (If no referral is set, it should be none)
    else if (!profile?.referrer) {
        // Show referral question
        return (
            <Stack
                spacing={"18px"}
                sx={{
                    background: theme.palette.background.default,
                    height: "100vh",
                    overflow: "auto",
                    width: "100%",
                    transition: "all .5s",
                }}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Stack sx={{ pt: "33px" }}>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        style={{
                            position: "absolute",
                            top: "44px",
                            left: "60px",
                        }}
                        spacing="8px"
                    >
                        <img
                            src={LOGO_APPX}
                            alt="Announcely Logo"
                            height={88}
                            width={88}
                        />
                        {profile?.name && profile?.email && (
                            <Stack
                                justifyContent={"center"}
                                alignItems={"flex-start"}
                            >
                                <Typography>{profile?.name}</Typography>
                                <Typography>{profile?.email}</Typography>
                                <Typography
                                    onClick={() => {
                                        signOut(FbAuth);
                                    }}
                                    sx={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        color: "red",
                                    }}
                                >
                                    Logout
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                    <Typography
                        fontSize={36}
                        fontWeight={"bold"}
                        textAlign={"center"}
                    >
                        Thanks you for joining us.
                    </Typography>
                    <Typography
                        fontSize={26}
                        fontWeight={400}
                        textAlign={"center"}
                    >
                        Please tell us what brings you here?
                    </Typography>
                </Stack>

                <Stack spacing="18px" sx={{ width: "500px" }}>
                    <TextInput
                        disabled={busy}
                        value={referralCode}
                        InputProps={{ endAdornment: <Info /> }}
                        onUpdate={(t) => setReferralCode(t)}
                        placeholder="Enter a referral code."
                        label="Referral Code"
                    />
                    {!busy && (
                        <Button
                            onClick={saveReferral}
                            disabled={!referralCode || busy}
                            variant="contained"
                            size="large"
                            sx={{
                                borderRadius: 1,
                                width: "200px",
                                height: "50px",
                                alignSelf: "center",
                            }}
                        >
                            Continue
                        </Button>
                    )}
                    {!busy && (
                        <Button
                            onClick={saveReferral}
                            disabled={busy}
                            variant="text"
                            size="small"
                            sx={{
                                alignSelf: "center",
                            }}
                        >
                            Skip
                        </Button>
                    )}
                    {busy && (
                        <Stack justifyContent={"center"} alignItems={"center"}>
                            <Player
                                autoplay
                                loop
                                src="https://lottie.host/1d29df75-d07f-4bb8-a3af-e1a1123a14d8/6YGpRhoctT.json"
                                style={{ height: "120px" }}
                            />
                            <Typography>Saving, please wait...</Typography>
                        </Stack>
                    )}
                </Stack>
                <Stack alignItems={"center"} spacing={"12px"}>
                    <Alert
                        variant="outlined"
                        color="success"
                        icon={<Discount />}
                    >
                        Enter the code from our sponsored partners for discounts
                        and offers.
                    </Alert>
                    <Typography fontSize={"18px"}>Are you confused?</Typography>
                    <Button href={AppConfig.links.help} target="_blank">
                        Get Help
                    </Button>
                </Stack>
            </Stack>
        );
    }

    // Price plans. Shown only in billing step. Skipped if billing is already set.
    if (step === OnboardingStep.Billing) {
        return (
            <Stack
                spacing={"18px"}
                sx={{
                    background: theme.palette.background.default,
                    height: "100vh",
                    overflow: "auto",
                    width: "100%",
                    transition: "all .5s",
                }}
                alignItems={"center"}
            >
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    style={{
                        position: "absolute",
                        top: "44px",
                        left: "60px",
                    }}
                    spacing="8px"
                >
                    <img
                        src={LOGO_APPX}
                        alt="Announcely Logo"
                        height={88}
                        width={88}
                    />
                    {profile?.name && profile?.email && (
                        <Stack
                            justifyContent={"center"}
                            alignItems={"flex-start"}
                        >
                            <Typography>{profile?.name}</Typography>
                            <Typography>{profile?.email}</Typography>
                            <Typography
                                onClick={() => {
                                    signOut(FbAuth);
                                }}
                                sx={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    color: "red",
                                }}
                            >
                                Logout
                            </Typography>
                        </Stack>
                    )}
                </Stack>
                <Typography
                    fontSize={36}
                    fontWeight={"bold"}
                    textAlign={"center"}
                >
                    Choose your perfect plan.
                </Typography>
                <Typography
                    fontSize={26}
                    fontWeight={"bold"}
                    textAlign={"center"}
                >
                    Get started with your free 7-day trial.
                </Typography>
                <Stack spacing={"8px"} direction={"row"} alignItems={"center"}>
                    <Typography>Monthly</Typography>
                    <Switch
                        checked={yearly}
                        onChange={(c) => setYearly(c.currentTarget.checked)}
                        color="success"
                        content="Billing cycle"
                    />
                    <Typography>Yearly</Typography>
                    <Chip label="10% OFF!" color="success" size="small"></Chip>
                </Stack>
                <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    flex={1}
                    direction={"row"}
                    spacing="18px"
                >
                    <Paper
                        elevation={4}
                        sx={{
                            background: "#505050",
                            px: "18px",
                            py: "18px",
                            width: "300px",
                            height: "500px",
                            border:
                                selectedPlan === "premium"
                                    ? "1px solid #4488FF"
                                    : "1px solid transparent",
                            cursor: busy ? "not-allowed" : "pointer",
                        }}
                        onClick={
                            busy ? undefined : () => setSelectedPlan("premium")
                        }
                    >
                        <Stack
                            sx={{ height: "100%" }}
                            direction={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            spacing="18px"
                        >
                            <img
                                src={IC_PREMIUM}
                                alt="Premium Package Icon"
                                style={{ height: "64px" }}
                            />
                            <Typography fontSize={"24px"}>Premium</Typography>
                            <Typography fontSize={"20px"}>
                                {yearly
                                    ? pricing?.yearly?.aio
                                    : pricing?.monthly?.aio}
                            </Typography>
                            <Typography>Features</Typography>
                            <Stack
                                alignItems={"center"}
                                justifyContent={"center"}
                                spacing="18px"
                            >
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <WhatsApp />
                                    <Typography>
                                        WhatsApp Integration
                                    </Typography>
                                    <Tooltip title="The feature is experimental and currently in development. Expect bugs and errors to occur using WhatsApp features.">
                                        <Chip
                                            size="small"
                                            label="Beta"
                                            variant="filled"
                                            color="warning"
                                        ></Chip>
                                    </Tooltip>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Telegram />
                                    <Typography>
                                        Telegram Integration
                                    </Typography>
                                </Stack>
                                {/* <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Send />
                                    <Typography>
                                        Channel Subscriptions
                                    </Typography>
                                </Stack> */}
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <LinkSharp />
                                    <Typography>Linked Chats</Typography>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Campaign />
                                    <Typography>
                                        Scheduled Announcements
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Analytics />
                                    <Typography>Analytics</Typography>
                                </Stack>
                                {/* <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <BrandingWatermark />
                                    <Typography>Custom Branding</Typography>
                                </Stack> */}
                            </Stack>
                        </Stack>
                    </Paper>

                    <Paper
                        elevation={4}
                        sx={{
                            background: "#505050",
                            px: "18px",
                            py: "18px",
                            height: "500px",
                            width: "300px",
                            border:
                                selectedPlan === "telegram"
                                    ? "1px solid #4488FF"
                                    : "1px solid transparent",
                            cursor: busy ? "not-allowed" : "pointer",
                        }}
                        onClick={
                            busy ? undefined : () => setSelectedPlan("telegram")
                        }
                    >
                        <Stack
                            sx={{ height: "100%" }}
                            direction={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            spacing="18px"
                        >
                            <img
                                src={IC_TELEGRAM}
                                alt="Telegram Package Icon"
                                style={{ height: "64px" }}
                            />
                            <Typography fontSize={"24px"}>Telegram</Typography>
                            <Typography fontSize={"20px"}>
                                {yearly
                                    ? pricing?.yearly?.telegram
                                    : pricing?.monthly?.telegram}
                            </Typography>
                            <Typography>Features</Typography>
                            <Stack
                                alignItems={"center"}
                                justifyContent={"center"}
                                spacing="18px"
                            >
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Telegram />
                                    <Typography>
                                        Telegram Integration
                                    </Typography>
                                </Stack>
                                {/* <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Send />
                                    <Typography>
                                        Channel Subscriptions
                                    </Typography>
                                </Stack> */}
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <LinkSharp />
                                    <Typography>Linked Chats</Typography>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Campaign />
                                    <Typography>
                                        Scheduled Announcements
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Analytics />
                                    <Typography>Analytics</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Paper>
                </Stack>

                {!busy && (
                    <Button
                        onClick={activateTrial}
                        variant="contained"
                        size="large"
                        sx={{
                            borderRadius: 1,
                            width: "200px",
                            height: "50px",
                        }}
                    >
                        Activate Trial
                    </Button>
                )}

                {busy && (
                    <Player
                        autoplay
                        loop
                        src="https://lottie.host/1d29df75-d07f-4bb8-a3af-e1a1123a14d8/6YGpRhoctT.json"
                        style={{ height: "120px" }}
                    />
                )}

                <Typography fontSize={"18px"}>
                    You won't be charged until trial is over. Cancel anytime.
                </Typography>

                <img
                    src={IC_STRIPE}
                    style={{ height: "32px" }}
                    alt="Powered by Stripe"
                />
            </Stack>
        );
    }

    // Configuration screen. Shows all buttons for bot configuration screens.
    // Here screen identifies the section of configuration user currently is at.
    if (step === OnboardingStep.Discord && screen === "main") {
        return (
            <Stack
                spacing={"18px"}
                sx={{
                    background: theme.palette.background.default,
                    height: "100vh",
                    overflow: "auto",
                    width: "100%",
                    transition: "all .5s",
                }}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Stack sx={{ pt: "33px" }}>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        style={{
                            position: "absolute",
                            top: "44px",
                            left: "60px",
                        }}
                        spacing="8px"
                    >
                        <img
                            src={LOGO_APPX}
                            alt="Announcely Logo"
                            height={88}
                            width={88}
                        />
                        {profile?.name && profile?.email && (
                            <Stack
                                justifyContent={"center"}
                                alignItems={"flex-start"}
                            >
                                <Typography>{profile?.name}</Typography>
                                <Typography>{profile?.email}</Typography>
                                <Typography
                                    onClick={() => {
                                        signOut(FbAuth);
                                    }}
                                    sx={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        color: "red",
                                    }}
                                >
                                    Logout
                                </Typography>
                            </Stack>
                        )}
                    </Stack>

                    <Typography
                        fontSize={36}
                        fontWeight={"bold"}
                        textAlign={"center"}
                    >
                        Your order has been processed.
                    </Typography>
                    <Typography
                        fontSize={26}
                        fontWeight={"bold"}
                        textAlign={"center"}
                    >
                        Now lets configure your bots.
                    </Typography>
                </Stack>

                <Stack spacing="18px">
                    {!isBotOnline && (
                        <Stack justifyContent={"center"} alignItems={"center"}>
                            <Player
                                autoplay
                                loop
                                src="https://lottie.host/1d29df75-d07f-4bb8-a3af-e1a1123a14d8/6YGpRhoctT.json"
                                style={{ height: "120px" }}
                            />
                            <Typography>
                                {botStatus?.status === "provisioning"
                                    ? "Provisioning"
                                    : botStatus?.status === "updating"
                                    ? "Updating service"
                                    : "Waiting for bot to be online. This may take a while..."}
                            </Typography>
                        </Stack>
                    )}

                    {isBotOnline && (
                        <>
                            <Paper
                                onClick={() => setScreen("discord")}
                                elevation={4}
                                sx={{
                                    background: "#505050",
                                    px: "74px",
                                    border: "1px solid transparent",
                                    width: "550px",
                                    height: "120px",
                                    cursor: "pointer",
                                    transition: "all .3s",
                                    ":hover": {
                                        border: "1px solid #4488FF",
                                    },
                                }}
                            >
                                <Stack
                                    sx={{ height: "100%" }}
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing="18px"
                                >
                                    <img
                                        src={IC_DISCORD}
                                        alt="Discord Icon"
                                        style={{ height: "64px" }}
                                    />
                                    <Stack>
                                        <Typography fontSize={"20px"}>
                                            Discord
                                        </Typography>
                                        <Typography fontSize={"14px"}>
                                            Connect your Discord Bot using a Bot
                                            Token
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Paper>

                            <Tooltip
                                followCursor
                                title={
                                    hasWhatsAppSubscription(profile)
                                        ? ""
                                        : "You do not have access WhatsApp features with your current subscription."
                                }
                            >
                                <Paper
                                    onClick={() => {
                                        if (hasWhatsAppSubscription(profile))
                                            setScreen("whatsapp");
                                    }}
                                    elevation={4}
                                    sx={{
                                        background: "#505050",
                                        px: "74px",
                                        width: "550px",
                                        height: "120px",
                                        border: "1px solid transparent",
                                        cursor: hasWhatsAppSubscription(profile)
                                            ? "pointer"
                                            : "not-allowed",
                                        opacity: hasWhatsAppSubscription(
                                            profile
                                        )
                                            ? 1
                                            : 0.3,
                                        transition: "all .3s",
                                        ":hover": hasWhatsAppSubscription(
                                            profile
                                        )
                                            ? {
                                                  border: "1px solid #4488FF",
                                              }
                                            : undefined,
                                    }}
                                >
                                    <Stack
                                        sx={{ height: "100%" }}
                                        direction={"row"}
                                        alignItems={"center"}
                                        spacing="18px"
                                    >
                                        <img
                                            src={IC_WHATSAPP}
                                            alt="WhatsApp Icon"
                                            style={{ height: "64px" }}
                                        />
                                        <Stack>
                                            <Typography fontSize={"20px"}>
                                                WhatsApp
                                            </Typography>
                                            <Typography fontSize={"14px"}>
                                                Connect your account with
                                                WhatsApp Web
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Paper>
                            </Tooltip>
                            <Tooltip
                                followCursor
                                title={
                                    hasTelegramSubscription(profile)
                                        ? ""
                                        : "You do not have access Telegram features with your current subscription."
                                }
                            >
                                <Paper
                                    onClick={() => {
                                        if (hasTelegramSubscription(profile))
                                            setScreen("telegram");
                                    }}
                                    elevation={4}
                                    sx={{
                                        background: "#505050",
                                        px: "74px",
                                        width: "550px",
                                        height: "120px",
                                        cursor: hasTelegramSubscription(profile)
                                            ? "pointer"
                                            : "not-allowed",
                                        opacity: hasTelegramSubscription(
                                            profile
                                        )
                                            ? 1
                                            : 0.3,
                                        border: "1px solid transparent",
                                        transition: "all .3s",
                                        ":hover": hasTelegramSubscription(
                                            profile
                                        )
                                            ? {
                                                  border: "1px solid #4488FF",
                                              }
                                            : undefined,
                                    }}
                                >
                                    <Stack
                                        sx={{ height: "100%" }}
                                        direction={"row"}
                                        alignItems={"center"}
                                        spacing="18px"
                                    >
                                        <img
                                            src={IC_TELEGRAM}
                                            alt="WhatsApp Icon"
                                            style={{ height: "64px" }}
                                        />
                                        <Stack>
                                            <Typography fontSize={"20px"}>
                                                Telegram
                                            </Typography>
                                            <Typography fontSize={"14px"}>
                                                Connect your telegram bot using
                                                a bot token.
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Paper>
                            </Tooltip>

                            {!busy && (
                                <Button
                                    onClick={finish}
                                    disabled={
                                        !(
                                            discordConfig &&
                                            discordConfig.uid &&
                                            (whatsappConfig?.account ||
                                                telegramConfig?.account)
                                        )
                                    }
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        borderRadius: 1,
                                        width: "200px",
                                        height: "50px",
                                        alignSelf: "center",
                                    }}
                                >
                                    Finish
                                </Button>
                            )}

                            {busy && (
                                <Player
                                    autoplay
                                    loop
                                    src="https://lottie.host/1d29df75-d07f-4bb8-a3af-e1a1123a14d8/6YGpRhoctT.json"
                                    style={{ height: "120px" }}
                                />
                            )}
                        </>
                    )}
                </Stack>
                <Stack>
                    <Typography fontSize={"18px"}>Are you confused?</Typography>
                    <Button href={AppConfig.links.help} target="_blank">
                        Get Help
                    </Button>
                </Stack>
            </Stack>
        );
    }

    // Discord bot configuration screen. Allows user to configure discord.
    // Once done, then only shows the connected bot info.
    if (step === OnboardingStep.Discord && screen === "discord") {
        return (
            <Stack
                sx={{
                    background: theme.palette.background.default,
                    height: "100vh",
                    overflow: "auto",
                    width: "100%",
                    transition: "all .5s",
                }}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Stack sx={{ pt: "44px" }}>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        style={{
                            position: "absolute",
                            top: "44px",
                            left: "60px",
                        }}
                        spacing="8px"
                    >
                        <img
                            src={LOGO_APPX}
                            alt="Announcely Logo"
                            height={88}
                            width={88}
                        />
                        {profile?.name && profile?.email && (
                            <Stack
                                justifyContent={"center"}
                                alignItems={"flex-start"}
                            >
                                <Typography>{profile?.name}</Typography>
                                <Typography>{profile?.email}</Typography>
                                <Typography
                                    onClick={() => {
                                        signOut(FbAuth);
                                    }}
                                    sx={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        color: "red",
                                    }}
                                >
                                    Logout
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                    <Typography
                        fontSize={36}
                        fontWeight={"bold"}
                        textAlign={"center"}
                    >
                        Configure Discord
                    </Typography>
                    <Typography fontSize={26} textAlign={"center"}>
                        Enter your Discord Bot Token and Client ID
                    </Typography>

                    <Stack
                        sx={{ width: "500px" }}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Tooltip title="Go back">
                            <IconButton
                                onClick={() => setScreen("main")}
                                size="large"
                            >
                                <ArrowBack />
                            </IconButton>
                        </Tooltip>
                        <Stack
                            sx={{ mt: "68px" }}
                            spacing="8px"
                            alignItems={"center"}
                        >
                            <img
                                src={IC_DISCORD}
                                alt="Discord Icon"
                                style={{
                                    height: "128px",
                                    width: "128px",
                                }}
                            />
                            <Typography fontSize="24px">Discord Bot</Typography>
                        </Stack>
                        <Stack />
                    </Stack>
                </Stack>

                {/* Configuration inputs. Shown only when discord is not set up */}
                {(!discordConfig ||
                    (discordConfig && !discordConfig.account)) && (
                    <Stack sx={{ width: "500px", mt: "20px" }} spacing="16px">
                        <TextInput
                            disabled={busy || discordCommandExecuted}
                            value={discordClientId}
                            onUpdate={(t) => setDiscordClientId(t)}
                            placeholder="Enter your client id.."
                            label="Client ID"
                            helperText="Required for registering /slash commands."
                        />
                        <TextInput
                            disabled={busy || discordCommandExecuted}
                            value={discordToken}
                            onUpdate={(t) => setDiscordToken(t)}
                            placeholder="Enter your bot token.."
                            label="Token"
                            helperText="Required for logging in as bot."
                        />
                        {!busy && (
                            <Button
                                onClick={saveDiscord}
                                disabled={
                                    !isDiscordInputsValid() ||
                                    busy ||
                                    discordCommandExecuted
                                }
                                variant="contained"
                                size="large"
                                sx={{
                                    borderRadius: 1,
                                    width: "200px",
                                    height: "50px",
                                    alignSelf: "center",
                                }}
                            >
                                Save
                            </Button>
                        )}
                        {busy && (
                            <Stack
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                <Player
                                    autoplay
                                    loop
                                    src="https://lottie.host/1d29df75-d07f-4bb8-a3af-e1a1123a14d8/6YGpRhoctT.json"
                                    style={{ height: "120px" }}
                                />
                                <Typography>Saving, please wait...</Typography>
                            </Stack>
                        )}
                    </Stack>
                )}

                {discordConfig && discordConfig.account && (
                    <Stack spacing={"18px"} sx={{ width: "500px" }}>
                        <Paper
                            elevation={4}
                            sx={{
                                background: "#505050",
                                width: "500px",
                                height: "120px",
                                transition: "all .3s",
                            }}
                        >
                            <Stack
                                sx={{ height: "100%", mx: "48px" }}
                                direction={"row"}
                                alignItems={"center"}
                                spacing="18px"
                            >
                                <img
                                    src={
                                        discordConfig.account.picture ||
                                        IC_DISCORD
                                    }
                                    alt="Discord Icon"
                                    style={{
                                        height: "64px",
                                        borderRadius: "10px",
                                    }}
                                />
                                <Stack>
                                    <Typography fontSize={"20px"}>
                                        {discordConfig.account.displayName}
                                    </Typography>
                                    <Typography fontSize={"14px"}>
                                        @{discordConfig.account.username}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                        <Stack spacing="8px">
                            <Typography>Discord Server</Typography>
                            <ComboBox
                                value={discordServer?.index}
                                changed={(c) => setDiscordServer(c)}
                                values={discordServers}
                                label="Select Server"
                                default={-1}
                                placeholder="Select your discord server to connect..."
                                required
                            />
                        </Stack>
                        <Alert variant="outlined">
                            Note: You can only select 1 server with each
                            subscription. If you have multiple servers, contact
                            sales team.
                        </Alert>
                    </Stack>
                )}

                <Stack>
                    <Typography fontSize={"18px"}>Are you confused?</Typography>
                    <Button href={AppConfig.links.help} target="_blank">
                        Get Help
                    </Button>
                </Stack>
            </Stack>
        );
    }

    // Telegram bot configuration. Allow user to set up their telegram bot.
    if (step === OnboardingStep.Discord && screen === "telegram") {
        return (
            <Stack
                spacing={"18px"}
                sx={{
                    background: theme.palette.background.default,
                    height: "100vh",
                    overflow: "auto",
                    width: "100%",
                    transition: "all .5s",
                }}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Stack sx={{ pt: "44px" }}>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        style={{
                            position: "absolute",
                            top: "44px",
                            left: "60px",
                        }}
                        spacing="8px"
                    >
                        <img
                            src={LOGO_APPX}
                            alt="Announcely Logo"
                            height={88}
                            width={88}
                        />
                        {profile?.name && profile?.email && (
                            <Stack
                                justifyContent={"center"}
                                alignItems={"flex-start"}
                            >
                                <Typography>{profile?.name}</Typography>
                                <Typography>{profile?.email}</Typography>
                                <Typography
                                    onClick={() => {
                                        signOut(FbAuth);
                                    }}
                                    sx={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        color: "red",
                                    }}
                                >
                                    Logout
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                    <Typography
                        fontSize={36}
                        fontWeight={"bold"}
                        textAlign={"center"}
                    >
                        Configure Telegram
                    </Typography>
                    <Typography fontSize={26} textAlign={"center"}>
                        Login with a user account, or use a bot token.
                    </Typography>

                    <Stack
                        sx={{ width: "500px" }}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Tooltip title="Go back">
                            <IconButton
                                onClick={() => setScreen("main")}
                                size="large"
                            >
                                <ArrowBack />
                            </IconButton>
                        </Tooltip>
                        <Stack
                            sx={{ mt: "68px" }}
                            spacing="8px"
                            alignItems={"center"}
                        >
                            <img
                                src={IC_TELEGRAM}
                                alt="Telegram Icon"
                                style={{
                                    height: "128px",
                                    width: "128px",
                                }}
                            />
                            <Typography fontSize="24px">
                                Telegram Bot
                            </Typography>
                        </Stack>
                        <Stack />
                    </Stack>
                </Stack>

                {/* If telegram is not configured, we show */}
                {(!telegramConfig ||
                    (telegramConfig && !telegramConfig.account)) && (
                    <>
                        <Stack sx={{ width: "550px" }} spacing="8px">
                            <TextInput
                                value={telegramToken}
                                onUpdate={(t) => setTelegramToken(t)}
                                disabled={busy || telegramCommandExecuted}
                                label="Token"
                                placeholder="Place your bot token."
                            />
                            <Alert
                                variant="outlined"
                                icon={<Info />}
                                color="info"
                            >
                                Talk to @BotFather on Telegram to get your bot
                                token.
                            </Alert>
                        </Stack>

                        {!busy && (
                            <Button
                                onClick={saveTelegram}
                                disabled={busy || telegramCommandExecuted}
                                variant="contained"
                                size="large"
                                sx={{
                                    borderRadius: 1,
                                    width: "200px",
                                    height: "50px",
                                }}
                            >
                                Save
                            </Button>
                        )}
                    </>
                )}

                {telegramConfig && telegramConfig.account && (
                    <Paper
                        elevation={4}
                        sx={{
                            background: "#505050",
                            width: "500px",
                            height: "120px",
                            transition: "all .3s",
                        }}
                    >
                        <Stack
                            sx={{ height: "100%", mx: "50px" }}
                            direction={"row"}
                            alignItems={"center"}
                            spacing="18px"
                        >
                            <img
                                src={
                                    telegramConfig.account.picture ||
                                    IC_TELEGRAM
                                }
                                alt="Telegram Icon"
                                style={{
                                    height: "64px",
                                    borderRadius: "10px",
                                }}
                            />
                            <Stack>
                                <Typography fontSize={"20px"}>
                                    {telegramConfig.account.fullName}
                                </Typography>
                                <Typography fontSize={"14px"}>
                                    @{telegramConfig.account.username}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                )}

                {busy && (
                    <Player
                        autoplay
                        loop
                        src="https://lottie.host/1d29df75-d07f-4bb8-a3af-e1a1123a14d8/6YGpRhoctT.json"
                        style={{ height: "120px" }}
                    />
                )}
                <Stack>
                    <Typography fontSize={"18px"}>Are you confused?</Typography>
                    <Button href={AppConfig.links.help} target="_blank">
                        Get Help
                    </Button>
                </Stack>
            </Stack>
        );
    }

    // Whatsap configuration
    if (step === OnboardingStep.Discord && screen === "whatsapp") {
        return (
            <Stack
                sx={{
                    background: theme.palette.background.default,
                    height: "100vh",
                    overflow: "auto",
                    width: "100%",
                    transition: "all .5s",
                }}
                justifyContent={"space-around"}
                alignItems={"center"}
            >
                <Stack sx={{ pt: "44px" }}>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        style={{
                            position: "absolute",
                            top: "44px",
                            left: "60px",
                        }}
                        spacing="8px"
                    >
                        <img
                            src={LOGO_APPX}
                            alt="Announcely Logo"
                            height={88}
                            width={88}
                        />
                        {profile?.name && profile?.email && (
                            <Stack
                                justifyContent={"center"}
                                alignItems={"flex-start"}
                            >
                                <Typography>{profile?.name}</Typography>
                                <Typography>{profile?.email}</Typography>
                                <Typography
                                    onClick={() => {
                                        signOut(FbAuth);
                                    }}
                                    sx={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                        color: "red",
                                    }}
                                >
                                    Logout
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                    <Typography
                        fontSize={36}
                        fontWeight={"bold"}
                        textAlign={"center"}
                    >
                        Configure WhatsApp
                    </Typography>
                    <Typography fontSize={26} textAlign={"center"}>
                        Connect your account with WhatsApp Web
                    </Typography>

                    <Stack
                        sx={{ width: "500px" }}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Tooltip title="Go back">
                            <IconButton
                                onClick={() => setScreen("main")}
                                size="large"
                            >
                                <ArrowBack />
                            </IconButton>
                        </Tooltip>
                        <Stack
                            sx={{ mt: "68px" }}
                            spacing="8px"
                            alignItems={"center"}
                        >
                            <img
                                src={IC_WHATSAPP}
                                alt="WhatsApp Icon"
                                style={{
                                    height: "128px",
                                    width: "128px",
                                }}
                            />
                            <Typography fontSize="24px">
                                WhatsApp Web
                            </Typography>
                        </Stack>
                        <Stack />
                    </Stack>
                </Stack>

                {!whatsappConfig ||
                    (whatsappConfig && !whatsappConfig.account && (
                        <Stack spacing="8px" alignItems={"center"}>
                            <Paper
                                elevation={4}
                                sx={{
                                    background: "#505050",
                                    width: "350px",
                                    height: "350px",
                                    border: "1px solid transparent",
                                    transition: "all .3s",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {whatsappConfig && whatsappConfig.qr && (
                                    <QRCode
                                        value={whatsappConfig?.qr || ""}
                                        width={"100%"}
                                        height={"100%"}
                                    />
                                )}
                                {(!whatsappConfig || !whatsappConfig.qr) &&
                                    !whatsappConfig?.account && (
                                        <Player
                                            autoplay
                                            loop
                                            src="https://lottie.host/1d29df75-d07f-4bb8-a3af-e1a1123a14d8/6YGpRhoctT.json"
                                            style={{ height: "120px" }}
                                        />
                                    )}
                            </Paper>
                            <Typography>
                                Click on{" "}
                                <Link
                                    target="_blank"
                                    href="https://faq.whatsapp.com/1189188245314343/?helpref=hc_fnav&cms_platform=android"
                                >
                                    More options
                                </Link>{" "}
                                icon, Go to Linked Device, and Scan the QR Code
                                from your Phone.
                            </Typography>
                            <Alert
                                variant="outlined"
                                icon={<Warning />}
                                color="warning"
                            >
                                For security reasons, it is highly recommended
                                that you use an alternative account with
                                Announcely.
                            </Alert>
                        </Stack>
                    ))}

                {whatsappConfig && whatsappConfig.account && (
                    <Paper
                        elevation={4}
                        sx={{
                            background: "#505050",
                            width: "500px",
                            height: "120px",
                            transition: "all .3s",
                        }}
                    >
                        <Stack
                            sx={{ height: "100%", mx: "50px" }}
                            direction={"row"}
                            alignItems={"center"}
                            spacing="18px"
                        >
                            <img
                                src={whatsappConfig.account.pictureUrl}
                                alt="WhatsApp Icon"
                                style={{
                                    height: "64px",
                                    borderRadius: "10px",
                                }}
                            />
                            <Stack>
                                <Typography fontSize={"20px"}>
                                    {whatsappConfig.account.name}
                                </Typography>
                                <Typography fontSize={"14px"}>
                                    +{whatsappConfig.account.number}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                )}

                <Stack>
                    <Typography fontSize={"18px"}>Are you confused?</Typography>
                    <Button href={AppConfig.links.help} target="_blank">
                        Get Help
                    </Button>
                </Stack>
            </Stack>
        );
    }

    return <Stack></Stack>;
}
