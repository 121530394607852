import React from "react";
import { Navigate, Routes } from "react-router";
import { HashRouter as Router, Route } from "react-router-dom";
import ProtectedRoute from "./providers/ProtectedRoute";
import PageLogin from "./pages/login";
import PagePortal from "./pages/portal";
import SplashScreen from "./components/SplashScreen";
import PageSignup from "./pages/signup";
import PagePasswordResetRequest from "./pages/request_password_reset";
import PageAuthCodeHandler from "./pages/authCodeHandler";
import PageAccountVerification from "./pages/verify_account";
import PageOnboarding from "./pages/onboarding";
import PageWaitlist from "./pages/waitlist";
import PagePasswordReset from "./pages/password_reset";

export default function AppRoutes() {
    return (
        <SplashScreen>
            <Router basename="/">
                <Routes>
                    {/* The unprotected routes  */}
                    <Route path="/login" element={<PageLogin />} />
                    <Route path="/signup" element={<PageSignup />} />
                    <Route
                        path="/reset-password"
                        element={<PagePasswordResetRequest />}
                    />
                    <Route path="/recover" element={<PagePasswordReset />} />
                    <Route path="/auth" element={<PageAuthCodeHandler />} />
                    <Route
                        path="/verify"
                        element={<PageAccountVerification />}
                    />

                    {/* Protected routes  */}
                    <Route
                        path="/onboarding"
                        element={
                            <ProtectedRoute fallbackChildren={<PageLogin />}>
                                <PageOnboarding />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/waitlist"
                        element={
                            <ProtectedRoute fallbackChildren={<PageLogin />}>
                                <PageWaitlist />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/portal/*"
                        element={
                            <ProtectedRoute fallbackChildren={<PageLogin />}>
                                <PagePortal />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="*" element={<Navigate to={"/portal/"} />} />
                </Routes>
            </Router>
        </SplashScreen>
    );
}
