import React from "react";
import { Button, ButtonProps, useTheme } from "@mui/material";

export default function SidebarButton(
	props: ButtonProps & { active?: boolean }
) {
	const theme = useTheme();
	return (
		<Button
			{...props}
			sx={{
				justifyContent: "start",
				borderRadius: "8px",
				color: props.active ? theme.palette.primary.main : "#A5A5A5",
				transition: "all .3s",
				":hover": {
					color: "white",
					background: "transparent",
					".MuiButton-startIcon": {
						color: "white",
					},
				},
				".MuiButton-startIcon": {
					color: props.active
						? theme.palette.primary.main
						: "#A5A5A5",
					transition: "all .3s",
				},
			}}
			variant="text"
		>
			{props.children}
		</Button>
	);
}
