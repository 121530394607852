import { useEffect, useState } from "react";
import { Stack, Typography, IconButton, Tooltip } from "@mui/material";
import SearchField from "./SearchField";
import { useRecoilState } from "recoil";
import { aCurrentSection, aProfile } from "../states/ui";
import { PowerSettingsNew } from "@mui/icons-material";
import { signOut } from "firebase/auth";
import { FbAuth } from "../core/firebase";
import BotStatusControl from "./BotStatus";
import GuildSelector from "./GuildSelector";

export default function AppBar() {
    const [currentSection] = useRecoilState(aCurrentSection);
    const [profile, setProfile] = useRecoilState(aProfile);

    async function logout() {
        signOut(FbAuth);
    }

    return (
        <Stack
            justifyContent={"space-between"}
            alignItems={"center"}
            direction={"row"}
            sx={{ p: "24px" }}
        >
            {/* The title of section  */}
            <Typography>{currentSection}</Typography>
            {/* The search and profile */}
            <Stack direction={"row"} alignItems={"center"} spacing={"24px"}>
                <BotStatusControl />
                <GuildSelector />
                <Typography>
                    {FbAuth.currentUser?.displayName ||
                        profile?.name ||
                        profile?.email}
                </Typography>
                <Tooltip title="Logout">
                    <IconButton onClick={logout}>
                        <PowerSettingsNew fontSize={"large"} color="error" />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Stack>
    );
}
