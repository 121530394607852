import { useState } from "react";
import { DialogProps } from "./DialogProps";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import { Telegram, WhatsApp } from "@mui/icons-material";
import { LinkedChat } from "@announcely/models";
import { useSnackbar } from "notistack";
import TextInput from "../components/TextInput";
import { deleteLinkedChat } from "../core/rtm";

export default function ViewLinkDialog(props: DialogProps<LinkedChat>) {
    const [busy, setBusy] = useState(false);

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    async function CreateLink() {
        try {
            setBusy(true);
            const resp = await deleteLinkedChat(props.data!);
            if (resp) {
                enqueueSnackbar("Link deleted successfully.", {
                    variant: "success",
                });
                props.closeHandler();
            }
        } catch (err: any) {
            enqueueSnackbar(
                "Error deleting link. Please see logs for details.",
                { variant: "error" }
            );
        }
        setBusy(false);
    }

    return (
        <Dialog
            open
            sx={{ backdropFilter: "blur(2px)" }}
            onClose={props.closeHandler}
            fullWidth
        >
            <DialogTitle>
                <Typography fontSize={20}>Linked Chat</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={"8px"}>
                    <Stack
                        direction={"row"}
                        sx={{ width: "100%" }}
                        spacing={"8px"}
                    >
                        <Stack flex={1}>
                            <TextInput
                                label="Server"
                                value={props.data?.sourceGuildName}
                                fullWidth
                            />
                        </Stack>
                        <Stack flex={1}>
                            <TextInput
                                label="Source channel"
                                value={props.data?.sourceChannelName}
                                fullWidth
                            />
                        </Stack>
                    </Stack>

                    <Stack>
                        <Typography>Target platform</Typography>
                        <RadioGroup
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "8px",
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Radio
                                        disableRipple
                                        checked={
                                            props.data?.platform === "whatsapp"
                                        }
                                        icon={<WhatsApp />}
                                        checkedIcon={<WhatsApp />}
                                    />
                                }
                                label="WhatsApp"
                                sx={{
                                    flex: 1,
                                    height: "60px",
                                    border:
                                        props.data?.platform === "whatsapp"
                                            ? "1px solid " +
                                              theme.palette.primary.main
                                            : "1px solid #AAA",
                                    py: "4px",
                                    px: "18px",
                                    borderRadius: "15px",
                                    m: 0,
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        disableRipple
                                        checked={
                                            props.data?.platform === "telegram"
                                        }
                                        icon={<Telegram />}
                                        checkedIcon={<Telegram />}
                                    />
                                }
                                label="Telegram"
                                sx={{
                                    flex: 1,
                                    height: "60px",
                                    border:
                                        props.data?.platform === "telegram"
                                            ? "1px solid " +
                                              theme.palette.primary.main
                                            : "1px solid #AAA",
                                    py: "4px",
                                    px: "18px",
                                    borderRadius: "15px",
                                    m: 0,
                                }}
                            />
                        </RadioGroup>
                    </Stack>

                    {props.data?.platform && (
                        <Stack flex={1}>
                            <TextInput
                                label="Target chat"
                                value={props.data?.targetName}
                                fullWidth
                            />
                        </Stack>
                    )}

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.data?.includeHeader}
                                onChange={() => {}}
                            />
                        }
                        label="Include Source Information in Forwarded Messages"
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: "24px" }}>
                <Button disabled={busy} onClick={props.closeHandler}>
                    Cancel
                </Button>
                <Button
                    color="error"
                    variant="contained"
                    onClick={CreateLink}
                    disabled={busy}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}
