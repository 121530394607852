import {
    Divider,
    Paper,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    styled,
} from "@mui/material";
import React from "react";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
        margin: theme.spacing(0.5),
        border: 0,
        "&.Mui-disabled": {
            border: 0,
        },
        "&:not(:first-of-type)": {
            borderRadius: theme.shape.borderRadius,
        },
        "&:first-of-type": {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

export default function OptionsGroup(props: {
    title?: string;
    onChange?: (option?: { value: string; label: string }) => void;
    defaultOption?: string;
    options: { value: string; label: string }[];
}) {
    const [option, setOption] = React.useState(props.defaultOption);

    const handleOptionChange = (
        event: React.MouseEvent<HTMLElement>,
        newOption: string
    ) => {
        setOption(newOption);
        props.onChange?.(props.options.find((o) => o.value === newOption));
    };

    return (
        <Paper
            elevation={0}
            sx={{
                display: "flex",
                flexWrap: "wrap",
                background: "transparent",
            }}
        >
            {props.title && (
                <Stack
                    direction="row"
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Typography sx={{ mx: 2 }}>{props.title}</Typography>
                    <Divider orientation="vertical" />
                </Stack>
            )}
            <StyledToggleButtonGroup
                size="small"
                value={option}
                exclusive
                onChange={handleOptionChange}
                aria-label="option"
            >
                {props.options.map((o) => (
                    <ToggleButton value={o.value} aria-label={o.label}>
                        <Typography variant="button">{o.label}</Typography>
                    </ToggleButton>
                ))}
            </StyledToggleButtonGroup>
        </Paper>
    );
}
