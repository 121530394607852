const DEBUG = process.env.REACT_APP_ANN_DEBUG === "true";
const VERSION = "v2.1.0";
const REVISION = "November 26, 2023";
const prod = {
    version: VERSION,
    revision: REVISION,
    rtm: "wss://public-api.announcely.app",
    api: "https://public-api.announcely.app",
    firebase: {
        apiKey: "AIzaSyCr9lkGmihek-F0c7_BfacrjzCkg_0VaYo",
        authDomain: "announcely-app.firebaseapp.com",
        projectId: "announcely-app",
        storageBucket: "announcely-app.appspot.com",
        messagingSenderId: "761175836664",
        appId: "1:761175836664:web:14e1b97386520d5193780d",
        measurementId: "G-LGTQZ1BYE1",
    },
    links: {
        privacyPolicy: "https://announcely.app/privacy-policy",
        terms: "https://announcely.app/terms",
        help: "https://announcely.app/help",
    },
};
const debug = {
    api: "http://127.0.0.1:80",
    rtm: "ws://127.0.0.1:80",
    version: VERSION,
    revision: REVISION,
    firebase: {
        apiKey: "AIzaSyBBbknLkDFqm_MhBQFzZYgPQf2zd1d7e-c",
        authDomain: "announcely-debug.firebaseapp.com",
        projectId: "announcely-debug",
        storageBucket: "announcely-debug.appspot.com",
        messagingSenderId: "348302108527",
        appId: "1:348302108527:web:a8bea71d65c0f37d6faa9a",
    },
    links: {
        privacyPolicy: "https://announcely.app/privacy-policy",
        terms: "https://announcely.app/terms",
        help: "https://announcely.app/help",
    },
};

export const AppConfig = DEBUG ? debug : prod;
