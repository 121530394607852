import {
    AddRounded,
    Refresh,
    Schedule,
    SendSharp,
    Warning,
} from "@mui/icons-material";
import {
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { aCurrentSection, aProfile, aSelectedServer } from "../states/ui";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import CreateAnnouncementDialog from "../dialogs/CreateAnnouncement";
import { Announcement, AnnouncementRecipientKind } from "@announcely/models";
import { canAccessFeatures } from "../core/helper";
import { getAnnouncements } from "../core/rtm";

export default function SectionAnnouncements() {
    const [, setCurrentSection] = useRecoilState(aCurrentSection);
    const [busy, setBusy] = React.useState(false);
    const [announcements, setAnnouncements] = useState<Announcement[]>([]);
    const [guildId] = useRecoilState(aSelectedServer);
    const [profile] = useRecoilState(aProfile);
    const [canAccess, setCanAccess] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { showModal } = useModal();

    function getTypeStr(t: AnnouncementRecipientKind | undefined) {
        switch (t) {
            case AnnouncementRecipientKind.AllSubscribers:
                return "All Subscribers";
            case AnnouncementRecipientKind.DiscordChannel:
                return "Discord Channel";
            case AnnouncementRecipientKind.TelegramChat:
                return "Telegram Chat";
            case AnnouncementRecipientKind.TelegramSubscribers:
                return "Telegram Subscribers";
            case AnnouncementRecipientKind.WhatsAppChat:
                return "WhatsApp Chat";
            case AnnouncementRecipientKind.WhatsAppSubscribers:
                return "WhatsApp Subscribers";
            default:
                return "Unknown";
        }
    }

    async function load() {
        try {
            if (profile) {
                const check = await canAccessFeatures(profile);
                setCanAccess(check);
            }
            if (guildId) {
                setBusy(true);
                const anns = await getAnnouncements(guildId);
                setAnnouncements(anns || []);
            }
        } catch (err: any) {
            enqueueSnackbar("Error loading announcements, please try again.", {
                variant: "error",
            });
            console.log(err);
        }
        setBusy(false);
    }

    function showCreateDialog() {
        const dlg = showModal(CreateAnnouncementDialog, {
            closeHandler() {
                dlg.destroy();
                load();
            },
        });
    }

    useEffect(() => {
        setCurrentSection("Announcements");
    }, []);
    useEffect(() => {
        load();
    }, [guildId]);
    return (
        <Stack sx={{ overflow: "hidden" }}>
            <Stack
                flex={1}
                sx={{ p: "24px", pt: "48px", overflow: "hidden" }}
                spacing={"8px"}
            >
                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                    <Button
                        disabled={busy}
                        onClick={load}
                        variant="contained"
                        sx={{
                            alignSelf: "start",
                        }}
                        startIcon={<Refresh />}
                    >
                        Refresh
                    </Button>
                    <Button
                        disabled={busy || !canAccess}
                        onClick={showCreateDialog}
                        variant="outlined"
                        sx={{
                            alignSelf: "start",
                        }}
                        startIcon={<AddRounded />}
                    >
                        Create Announcement
                    </Button>

                    {canAccess === false && (
                        <Tooltip title={"Please go to settings to learn more."}>
                            <Warning color="error" />
                        </Tooltip>
                    )}
                </Stack>
                {/* The table for history */}
                <TableContainer sx={{ overflow: "auto" }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">Created</TableCell>
                                <TableCell align="center">
                                    Target Type
                                </TableCell>
                                <TableCell align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {announcements?.map((a: Announcement) => (
                                <TableRow>
                                    <TableCell align="center">
                                        {a.mode === "schedule" && (
                                            <Stack
                                                justifyContent={"center"}
                                                direction={"row"}
                                                alignItems={"center"}
                                                spacing={"8px"}
                                            >
                                                <Schedule />
                                                <Typography>
                                                    Scheduled
                                                </Typography>
                                            </Stack>
                                        )}
                                        {a.mode === "send" && (
                                            <Stack
                                                justifyContent={"center"}
                                                direction={"row"}
                                                alignItems={"center"}
                                                spacing={"8px"}
                                            >
                                                <SendSharp />{" "}
                                                <Typography>Instant</Typography>
                                            </Stack>
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {moment(new Date(a.created)).format(
                                            "MMM DD, YYYY HH:mm:ss a"
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {getTypeStr(a.recipient?.kind)}
                                        {(a.recipient as any)?.chatTitle
                                            ? ` (${
                                                  (a.recipient as any).chatTitle
                                              })`
                                            : ""}
                                    </TableCell>
                                    <TableCell align="center">
                                        {a.sent
                                            ? `Sent on ${moment(
                                                  a.sentAt
                                              ).format(
                                                  "MMM DD, YYYY HH:mm:ss a"
                                              )}`
                                            : a.mode === "send"
                                            ? "Sending..."
                                            : `Sending ${moment(
                                                  a.schedule
                                              ).fromNow()}`}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Stack>
    );
}
