import { RTMClient, createClient } from "@zexcore/rtm-client";
import { AppConfig } from "../config";
import { FbAuth } from "./firebase";
import {
    AnnouncelyPricing,
    Announcement,
    Chat,
    Command,
    Customer,
    DiscordBotConfiguration,
    LinkedChat,
    Subscriber,
    TelegramConfiguration,
    WhatsAppConfiguration,
} from "@announcely/models";

let client: RTMClient;

async function ensureAuthentication() {
    return new Promise((resolve, reject) => {
        if (!client) {
            client = createClient(AppConfig.rtm, {
                enableMessageQueue: true,
                reconnectDelayMs: 5000,
                onReconnect(attempt) {
                    console.log(`Trying to reconnect for ${attempt} time...`);
                },
                onMessage(raw) {},
                async onOpen() {
                    console.log("Connected to the RTM server.");
                    console.log("Authenticating with RTM Server...");
                    const token = await FbAuth.currentUser?.getIdToken();
                    await client.authenticate(token!);
                    console.log("Authentication successfull.");
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    resolve(true);
                },

                onClose() {
                    console.log("Connection with the RTM server was closed.");
                },

                onError() {
                    console.log("Error connecting to the RTM server");
                    reject();
                },
            });
        } else {
            resolve(true);
        }
    });
}

export async function getProfile() {
    await ensureAuthentication();
    const prof = await client.callWait<Customer>("getProfile");
    return prof;
}

export async function setCommand(cmd: Command) {
    await ensureAuthentication();
    const res = await client.callWait<boolean>("setCommand", cmd);
    return res;
}

export async function finishOnboarding(guild: any) {
    await ensureAuthentication();
    const res = await client.callWait<boolean>("finishOnboarding", guild);
    return res;
}

export async function setReferral(code: string) {
    await ensureAuthentication();
    const res = await client.callWait<boolean>("setReferral", code);
    return res;
}

export async function setBranding(branding: {
    embedTitle?: string;
    embedDescription?: string;
    embedAuthorName?: string;
    embedAuthorLink?: string;
    textModeFooter?: string;
    textModeHeader?: string;
}) {
    await ensureAuthentication();
    const res = await client.callWait<boolean>("setBranding", branding);
    return res;
}

/**
 * Returns all the linked chats of the current logged in user.
 */
export async function getLinkedChats() {
    await ensureAuthentication();
    const prof = await client.callWait<LinkedChat[]>("getLinkedChats");
    return prof;
}

export async function createLinkedChat(chat: Partial<LinkedChat>) {
    await ensureAuthentication();
    const res = await client.callWait<"OK" | "ERR_DUPLICATE">(
        "createLink",
        chat
    );
    return res;
}

/**
 * Deletes the specified linked chat.
 * @param chat
 */
export async function deleteLinkedChat(chat: Partial<LinkedChat>) {
    await ensureAuthentication();
    const res = await client.callWait<boolean>("deleteLink", chat);
    return res;
}

/**
 * Returns all the chats of a user.
 * @returns
 */
export async function getChats() {
    await ensureAuthentication();
    const res = await client.callWait<Chat[]>("getChats");
    return res;
}

/**
 * Returns all the subscribers of a user.
 * @returns
 */
export async function getSubscribers() {
    await ensureAuthentication();
    const res = await client.callWait<Subscriber[]>("getSubscribers");
    return res;
}

/**
 * Removes the specified subscriber
 * @returns
 */
export async function deleteSubscriber(id: string) {
    await ensureAuthentication();
    const res = await client.callWait<boolean>("deleteSubscriber", id);
    return res;
}

/**
 * Get discord configuration
 * @returns
 */
export async function getDiscordConfiguration() {
    await ensureAuthentication();
    const res = await client.callWait<DiscordBotConfiguration | false>(
        "getDiscordConfiguration"
    );
    return res;
}

/**
 * Get whatsapp configuration
 * @returns
 */
export async function getWhatsAppConfiguration() {
    await ensureAuthentication();
    const res = await client.callWait<WhatsAppConfiguration | false>(
        "getWhatsAppConfiguration"
    );
    return res;
}

/**
 * Get telegram configuration
 * @returns
 */
export async function getTelegramConfiguration() {
    await ensureAuthentication();
    const res = await client.callWait<TelegramConfiguration | false>(
        "getTelegramConfiguration"
    );
    return res;
}

/**
 * Get scheduled announcements. Shows only last 25 announcements.
 * @returns
 */
export async function getAnnouncements(guild: string) {
    await ensureAuthentication();
    const res = await client.callWait<Announcement[]>(
        "getAnnouncements",
        guild
    );
    return res;
}

/**
 * Creates an announcement.
 * @returns
 */
export async function createAnnouncement(announcement: Partial<Announcement>) {
    await ensureAuthentication();
    const res = await client.callWait<Announcement>(
        "createAnnouncement",
        announcement
    );
    return res;
}

/**
 * Get billing history of the customer
 * @returns
 */
export async function getBillingHistory() {
    await ensureAuthentication();
    const res = await client.callWait<any[] | false>("getBillingHistory");
    return res;
}

/**
 * Get announcely pricing
 * @returns
 */
export async function getPricing() {
    await ensureAuthentication();
    const res = await client.callWait<AnnouncelyPricing | false>("getPricing");
    return res;
}

/**
 * Get stripe portal session for a customer
 * @returns
 */
export async function getPortal() {
    await ensureAuthentication();
    const res = await client.callWait<any | false>("getPortal");
    return res;
}

/**
 * Get stripe checkout session for a customer
 * @returns
 */
export async function getCheckout(period: any, kind: any) {
    await ensureAuthentication();
    const res = await client.callWait<any | false>("getCheckout", period, kind);
    return res;
}

/**
 * Waits for the most recent command to be executed.
 */
export function waitForCommandResult(): Promise<Command | false> {
    return new Promise((resolve) => {
        async function _check() {
            const prof = await getProfile();
            if (!prof.command) {
                resolve(false);
            } else if (prof.command && prof.command.result) {
                resolve(prof.command);
            } else {
                setTimeout(_check, 200);
            }
        }
        setTimeout(_check, 0);
    });
}
