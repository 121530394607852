import {
    Button,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { aCurrentSection } from "../states/ui";
import { IC_CARD } from "../assets";
import { useSnackbar } from "notistack";
import { Customer, Subscription, SubscriptionKind } from "@announcely/models";
import { useModal } from "mui-modal-provider";
import moment from "moment";
import { parseStripeAmount } from "../core/helper";
import { getBillingHistory, getPortal, getProfile } from "../core/rtm";
import SubscribeDialog from "../dialogs/Subscribe";

export default function SectionBilling() {
    const [, setCurrentSection] = useRecoilState(aCurrentSection);
    const [profile, setProfile] = useState<Customer>();
    const [busy, setBusy] = useState(false);
    const [payments, setPayments] = useState<any>([]);

    const theme = useTheme();
    const { showModal } = useModal();
    const { enqueueSnackbar } = useSnackbar();

    async function load() {
        try {
            setBusy(true);
            // Get the profile...
            const prof = await getProfile();
            setProfile(prof);
            // Load the payments history.
            const hist = await getBillingHistory();
            setPayments(hist);
        } catch (err: any) {
            console.log("ERROR LOADING BILLING INFORMATION.");
            console.log(err);
            enqueueSnackbar(
                "Error loading data. Please see logs for details.",
                {
                    variant: "error",
                }
            );
        }
        setBusy(false);
    }

    function getSub() {
        const sub =
            profile?.billing?.subscriptions?.[
                profile.billing.subscriptions.length - 1
            ];
        return sub || false;
    }

    function hasPaymentMethod() {
        return (
            profile?.billing.payment_methods &&
            profile.billing.payment_methods.length > 0
        );
    }

    function getPriceStr() {
        const sub = getSub();
        if (!sub) return "...";
        const pi = sub.items?.data?.[0]?.price;
        if (!pi) return "..";
        return `${Math.floor(pi.unit_amount / 100)}`;
    }
    function getPriceStrCents() {
        const sub = getSub();
        if (!sub) return "...";
        const pi = sub.items?.data?.[0]?.price;
        if (!pi) return "..";
        return `${pi.unit_amount % 100.0}`;
    }
    function getCurrency() {
        const sub = getSub();
        if (!sub) return "...";
        const pi = sub.items?.data?.[0]?.price;
        if (!pi) return "..";
        return pi.currency.toUpperCase();
    }

    function getSubscriptionName() {
        const sub = getSub();
        if (!sub) return "...";
        switch (sub.kind) {
            case SubscriptionKind.Aio:
                return "Premium";
            case SubscriptionKind.Telegram:
                return "Telegram";
        }
    }
    function getNextPaymentDate() {
        const sub = getSub() as any;
        if (!sub || !sub.next_invoice) return "...";
        return moment.unix(sub.next_invoice.created).format("MMMM DD, YYYY");
    }
    function getNextPaymentAmount() {
        const sub = getSub() as any;
        if (!sub || !sub.next_invoice) return { dollars: "...", cents: "..." };
        return parseStripeAmount(sub.next_invoice.total);
    }
    function getSubStatus() {
        const sub = getSub();
        return (sub as Subscription).status;
    }
    function hasNextPayment() {
        const sub = getSub() as any;
        if (sub && sub.next_invoice) return true;
        return false;
    }

    function getLast4() {
        const sub = profile?.billing as any;
        if (!sub) return "...";
        return sub.payment_methods?.card?.last4 || "...";
    }
    function getCardBrand() {
        const sub = profile?.billing as any;
        if (!sub) return "...";
        return sub.payment_methods?.card?.brand || "...";
    }
    function getCardExp() {
        const sub = profile?.billing as any;
        if (!sub) return "...";
        const m = sub.payment_methods?.card?.exp_month;
        const y = sub.payment_methods?.card?.exp_year;
        return `${m}/${y}`;
    }

    // When the primary button in susbcription is clicked.
    async function subscriptionButtonClick() {
        // When we are subscribed, we show 'manage' options. basically, it takes the customer to Stripe Portal.
        try {
            setBusy(true);
            // If we are subscribed, we show portal.
            if (getSub()) {
                const sess = await getPortal();
                // Open session in the same window
                window.location = sess.url;
            } else {
                // Otherwise we should price plans
                const modal = showModal(SubscribeDialog, {
                    data: profile,
                    closeHandler() {
                        modal.destroy();
                    },
                });
            }
        } catch (err: any) {
            enqueueSnackbar(
                "Error creating customer portal session. Please try again or contact administrators.",
                { variant: "error" }
            );
        }
        setBusy(false);
    }

    useEffect(() => {
        setCurrentSection("Billing details");
        load();
    }, []);
    return (
        <Stack>
            <Grid
                container
                columns={3}
                spacing={"24px"}
                sx={{ p: "24px" }}
                rowGap={"40px"}
            >
                <Grid item sx={{ width: "350px", height: "200px" }}>
                    <Paper
                        sx={{
                            height: "100%",
                            background: getSub()
                                ? theme.palette.primary.main
                                : "transparent",
                            border: getSub() ? "0px solid" : "1px solid",
                            borderColor: theme.palette.primary.main,
                            borderRadius: "15px",
                            overflow: "hidden",
                            position: "relative",
                            p: "24px",
                        }}
                    >
                        <Stack
                            sx={{
                                position: "relative",
                                zIndex: "1",
                                height: "100%",
                            }}
                        >
                            <Typography fontSize={"18px"}>
                                {getSub()
                                    ? "Current Subscription Plan"
                                    : "Subscribe"}
                            </Typography>
                            {/* The pricing part is 20px whole, and 15px for cents. */}
                            {/* Rendered only when there is a subs... */}

                            {getSub() && (
                                <Stack
                                    direction={"row"}
                                    alignItems={"baseline"}
                                >
                                    <Typography fontSize={"20px"}>
                                        {getCurrency()} {getPriceStr()}.
                                    </Typography>
                                    <Typography fontSize={"15px"}>
                                        {getPriceStrCents()}
                                    </Typography>
                                </Stack>
                            )}
                            <Typography fontSize={"15px"}>
                                {getSub()
                                    ? getSubscriptionName()
                                    : "You do not have any subscription. Click the button below to subscribe!"}
                            </Typography>
                            {getSub() && (
                                <Typography fontSize={"15px"}>
                                    Status: {getSubStatus()}
                                </Typography>
                            )}
                            <Stack flex={1} />
                            <Stack direction={"row"} spacing={"8px"}>
                                <Button
                                    disabled={busy}
                                    onClick={subscriptionButtonClick}
                                    variant="contained"
                                    sx={{
                                        background: "white",
                                        color: "black",
                                        borderRadius: "25px",
                                        alignSelf: "start",
                                        ":hover": {
                                            color: "#FFFA",
                                        },
                                    }}
                                >
                                    {getSub()
                                        ? "Manage Subscription"
                                        : "Subscribe"}
                                </Button>
                            </Stack>
                        </Stack>
                        {/* The absolute circles for design  */}
                        {/* Shown only when there is a sub active..  */}
                        {getSub() && (
                            <>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "10px",
                                        left: "10px",
                                        width: "96px",
                                        height: "96px",
                                        background: "rgba(87, 8, 190, 0.79)",
                                        borderRadius: "50%",
                                    }}
                                />
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "205px",
                                        left: "230px",
                                        width: "96px",
                                        height: "96px",
                                        background: "rgba(87, 8, 190, 0.79)",
                                        borderRadius: "50%",
                                    }}
                                />
                            </>
                        )}
                    </Paper>
                </Grid>

                {/* Payment Methods card is only visible if there are any payment sources */}
                {hasPaymentMethod() && (
                    <Grid item sx={{ width: "350px", height: "200px" }}>
                        <Paper
                            sx={{
                                height: "100%",
                                overflow: "hidden",
                                position: "relative",
                                p: "24px",
                                background: "transparent",
                                border: "1px solid",
                                borderColor: theme.palette.primary.main,
                                borderRadius: "15px",
                            }}
                        >
                            <Stack
                                sx={{
                                    position: "relative",
                                    zIndex: "1",
                                    height: "100%",
                                }}
                            >
                                <Typography fontSize={"18px"}>
                                    Payment Information
                                </Typography>
                                {/* The pricing part is 20px whole, and 15px for cents. */}
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={"4px"}
                                >
                                    <img
                                        src={IC_CARD}
                                        width={"64px"}
                                        height={"64px"}
                                        alt="Credit Card"
                                    />
                                    <Stack>
                                        <Typography fontSize={"12px"}>
                                            {getCardBrand().toUpperCase()}:{" "}
                                            {getLast4()}
                                        </Typography>
                                        <Typography fontSize={"12px"}>
                                            Expires: {getCardExp()}
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Stack flex={1} />
                                <Button
                                    onClick={subscriptionButtonClick}
                                    disabled={busy}
                                    variant="contained"
                                    sx={{
                                        alignSelf: "start",
                                        background: "white",
                                        color: "black",
                                        borderRadius: "25px",
                                        ":hover": {
                                            color: "#FFFA",
                                        },
                                    }}
                                >
                                    Manage
                                </Button>
                            </Stack>
                        </Paper>
                    </Grid>
                )}

                {/* Next payment card is only rendered if there is an active subscription */}
                {hasNextPayment() && (
                    <Grid item sx={{ width: "350px", height: "200px" }}>
                        <Paper
                            sx={{
                                height: "100%",
                                overflow: "hidden",
                                position: "relative",
                                p: "24px",
                                background: "transparent",
                                border: "1px solid",
                                borderColor: theme.palette.primary.main,
                                borderRadius: "15px",
                            }}
                        >
                            <Stack
                                sx={{
                                    position: "relative",
                                    zIndex: "1",
                                    height: "100%",
                                }}
                            >
                                <Typography fontSize={"18px"}>
                                    Next Payment
                                </Typography>
                                {/* The pricing part is 20px whole, and 15px for cents. */}
                                <Stack
                                    direction={"row"}
                                    alignItems={"baseline"}
                                >
                                    <Typography fontSize={"20px"}>
                                        ${getNextPaymentAmount().dollars}.
                                    </Typography>
                                    <Typography fontSize={"15px"}>
                                        {getNextPaymentAmount().cents}
                                    </Typography>
                                </Stack>
                                <Typography fontSize={"15px"}>
                                    on {getNextPaymentDate()}
                                </Typography>
                                <Stack flex={1} />
                            </Stack>
                        </Paper>
                    </Grid>
                )}
            </Grid>
            <Stack
                flex={1}
                sx={{ p: "24px", pt: "48px", overflow: "hidden" }}
                spacing={"8px"}
            >
                <Typography fontSize={"18px"} color={"#A5A5A5"}>
                    Payments history
                </Typography>
                {/* The table for history */}
                <TableContainer sx={{ overflow: "auto" }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell align="center">Invoice</TableCell>
                                <TableCell align="center">Method</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="right">Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {payments.map((p: any) => (
                                <TableRow>
                                    <TableCell>
                                        {moment
                                            .unix(p.created)
                                            .format("DD/MM/YYYY")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {p.invoice}
                                    </TableCell>
                                    <TableCell align="center">
                                        {p.payment_method_details.type.toUpperCase()}
                                    </TableCell>
                                    <TableCell align="center">
                                        {p.status.toUpperCase()}
                                    </TableCell>
                                    <TableCell align="right">
                                        ${parseStripeAmount(p.amount).dollars}.
                                        {parseStripeAmount(p.amount).cents}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Stack>
    );
}
