import React, { useEffect } from "react";
import { getAuth } from "firebase/auth";
import { useRecoilState } from "recoil";
import { aProfile } from "../states/ui";
import { getProfile } from "../core/rtm";

/**
 * Renders children only if the user is logged in. Otherwise navigates to the /login (or specified redirectTo) page.
 * @returns
 */
export default function ProtectedRoute(props: {
    redirectTo?: string;
    children: any;
    fallbackChildren: any;
}) {
    const [profile, setProfile] = useRecoilState(aProfile);

    //const user = FbAuth.currentUser;
    const [user, setUser] = React.useState<any>();

    async function loadProfile() {
        try {
            const cust = await getProfile();
            setProfile(cust || null);
        } catch (err: any) {
            // Error loading profile.
            console.log("Error loading profile.");
            console.log(err);
        }
    }

    useEffect(() => {
        let t: any;
        const unsub = getAuth().onAuthStateChanged((usr) => {
            if (usr) {
                // we are logged in
                setUser(usr);
                t = setInterval(() => {
                    loadProfile();
                }, 5000);
            } else {
                setUser(null);
                clearInterval(t);
            }
        });
        return unsub;
    }, []);

    if (!user) {
        return <>{props.fallbackChildren}</>;
    }
    return <>{props.children}</>;
}
