import { Paper, Stack, Typography, useTheme, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import TextInput from "../components/TextInput";
import { useNavigate } from "react-router-dom";
import { LOGO_LIGHT_VERTICAL } from "../assets";
import { FbAuth } from "../core/firebase";
import {
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
} from "firebase/auth";
import { enqueueSnackbar } from "notistack";

export default function PageLogin() {
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [busy, setBusy] = useState(false);

    const theme = useTheme();
    const navigate = useNavigate();

    async function signin(fe: any) {
        fe?.preventDefault?.();
        setError("");
        setBusy(false);
        // Make sure all fields are valid...
        if (!email) {
            setError("email");
            return;
        }
        if (!password) {
            setError("password");
            return;
        }
        try {
            setBusy(true);
            // Sign in using email and pass
            const r = await signInWithEmailAndPassword(FbAuth, email, password);
            if (r && !r.user.emailVerified) {
                // Don't login.
                enqueueSnackbar(
                    "Please confirm your account by clicking the link sent to your email.",
                    { variant: "error" }
                );
                await signOut(FbAuth);
            } else {
                navigate("/portal");
            }
        } catch (err: any) {
            if (
                err.code &&
                (err.code === "auth/wrong-password" ||
                    err.code === "auth/user-not-found")
            ) {
                enqueueSnackbar(
                    "Invalid username or password. Please try again.",
                    { variant: "error" }
                );
            } else {
                enqueueSnackbar(
                    "Error logging in. Please contact administrators.",
                    { variant: "error" }
                );
                console.log(err);
            }
        }
        setBusy(false);
    }

    return (
        <Stack
            sx={{
                height: "100vh",
                width: "100vw",
                background: theme.palette.background.default,
            }}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Stack justifyContent={"center"} alignItems={"center"}>
                {/* The logo  */}
                <img
                    src={LOGO_LIGHT_VERTICAL}
                    alt="Announcely Logo"
                    style={{ height: "196px" }}
                />
                {/* The paper container  */}
                <Paper
                    onSubmit={signin}
                    action=""
                    component={"form"}
                    elevation={1}
                    sx={{ p: "16px", minWidth: "300px" }}
                >
                    <Stack spacing={"14px"}>
                        <Typography fontSize={"24px"}>Sign in</Typography>
                        <Typography>
                            Sign in with Google. <br />
                            Or, sign in with your email.
                        </Typography>
                        <Typography>
                            Don't have an account?{" "}
                            <Button onClick={() => navigate("/signup")}>
                                Sign up
                            </Button>
                        </Typography>
                        <TextInput
                            disabled={busy}
                            error={error === "email"}
                            label="Email"
                            value={email}
                            onUpdate={(p) => setEmail(p)}
                        />
                        <TextInput
                            disabled={busy}
                            error={error === "password"}
                            type="password"
                            label="Password"
                            value={password}
                            onUpdate={(p) => setPassword(p)}
                        />
                        <Typography>
                            Forgot your password?{" "}
                            <Button
                                onClick={() => navigate("/reset-password")}
                                disabled={busy}
                            >
                                Click here to reset
                            </Button>
                        </Typography>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ height: "44px" }}
                            disabled={busy}
                        >
                            Sign in
                        </Button>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    );
}
