import { Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";

export default function DataCard(props: {
	data: any[];
	/**
	 * The name of property in data element.
	 */
	labelKey: string;
	/**
	 * Keys of the values to show. Multiple keys means showing multiple values.
	 */
	valueKeys: string[];
	/**
	 * Column headers. First one is for labels.
	 */
	columnHeaders: ReactElement[];
	heading1: string;
	heading2: string;
	heading3?: string;
	/**
	 * Sorting by. Must be one of the valueKeys.
	 */
	sortBy: string;
}) {
	function getMax() {
		let x = 0;
		for (let v of props.data) {
			x += v[props.sortBy];
		}
		return x;
	}
	const max = getMax();

	// Return percentage of specified value with max being 100%
	function GetPercentageOfValue(val: number) {
		return (100 * val) / max;
	}

	function RenderBar(index: number, data: any) {
		// Get the label
		const label = props.data[index][props.labelKey];
		return (
			<>
				<Grid item xs={2} sx={{ position: "relative" }}>
					<div
						style={{
							opacity: 0.7,
							zIndex: "0",
							width: `${GetPercentageOfValue(
								data[props.sortBy]
							)}%`,
							height: "100%",
							position: "absolute",
							background:
								"linear-gradient(90deg, #4A3AFF, #6D3AFF)",
							borderRadius: "25px",
						}}
					></div>
					<Typography
						sx={{ zIndex: 1, mx: "8px", position: "relative" }}
					>
						{label}
					</Typography>
				</Grid>
				{props.valueKeys.map((vk, ind) => (
					<Grid item xs>
						<Typography sx={{ zIndex: 1, mx: "8px" }}>
							{data[vk]}
						</Typography>
					</Grid>
				))}
			</>
		);
		// Render the bar.
		return (
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				alignItems={"center"}
				sx={{
					position: "relative",
					my: "4px",
				}}
			>
				<Typography sx={{ zIndex: 1, mx: "8px", position: "relative" }}>
					{label}
				</Typography>
				<Stack direction={"row"} alignItems={"center"}>
					{props.valueKeys.map((vk, ind) => (
						<Typography sx={{ zIndex: 1, mx: "8px" }}>
							{data[vk]}
						</Typography>
					))}
				</Stack>
				<div
					style={{
						opacity: 0.7,
						zIndex: "0",
						width: `${GetPercentageOfValue(data[props.sortBy])}%`,
						height: "100%",
						position: "absolute",
						background: "linear-gradient(90deg, #4A3AFF, #6D3AFF)",
						borderRadius: "25px",
					}}
				></div>
			</Stack>
		);
	}

	return (
		<Paper elevation={0} sx={{ height: "400px", p: "26px", overflow: "hidden" }}>
			<Stack sx={{ height: "100%" }}>
				<Typography fontSize={"14px"}>{props.heading1}</Typography>
				<Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
					<Typography fontSize={"18px"} fontWeight={"bold"}>
						{props.heading2}
					</Typography>
					<Typography fontSize={"14px"} sx={{ opacity: 0.6 }}>
						{props.heading3}
					</Typography>
				</Stack>
				<Divider sx={{ my: "8px" }} />
				<Grid container columns={props.valueKeys.length + 1}>
					{/* The header  */}
					<Grid item xs={2}>
						<Typography fontWeight={"bold"} fontSize={"16px"}>
							{props.columnHeaders[0]}
						</Typography>
					</Grid>
					{props.columnHeaders.slice(1).map((vh, ind) => (
						<Grid item xs>
							{vh}
						</Grid>
					))}
					{props?.data
						.sort((a, b) => b[props.sortBy] - a[props.sortBy])
						.map((data, i) => RenderBar(i, data))}
				</Grid>
			</Stack>
		</Paper>
	);
}
