import { Paper, Stack, Typography, useTheme, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGO_LIGHT_VERTICAL } from "../assets";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { applyActionCode, getAuth } from "firebase/auth";
import { SendVerificationConfirmation } from "../core/rest";

export default function PageAccountVerification() {
    const loc = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const code = loc.state?.code;
    const uid = loc.state?.uid;
    const theme = useTheme();
    const params = new URLSearchParams(loc.search);

    // Location.State should contain the username and hashed password

    async function verifyEmail() {
        try {
            if (!uid) {
                enqueueSnackbar(
                    "Required user identifier not present in request. "
                );
                setLoading(false);
                return;
            }
            await setLoading(true);
            // Proceed.
            await applyActionCode(getAuth(), code!);
            // Call /confirmed
            await SendVerificationConfirmation(uid!);
            // Send verification email.
            enqueueSnackbar("Your account has been confirmed.", {
                variant: "success",
            });
            // IF continue url is set, we navigatte.
            setConfirmed(true);
            setTimeout(() => {
                if (params.has("continue_url")) {
                    navigate(params.get("continue_url") || "/login");
                } else {
                    navigate("/login");
                }
            }, 2000);
        } catch (err: any) {
            enqueueSnackbar(
                "Error verifying your account. Please contact administrators.",
                {
                    variant: "error",
                }
            );
            console.log(err);
        }
        await setLoading(false);
    }

    useEffect(() => {
        if (!code) {
            // Redirect to /
            navigate("/login");
            return;
        }
        verifyEmail();
    }, []);
    return (
        <Stack
            sx={{
                height: "100vh",
                width: "100vw",
                background: theme.palette.background.default,
            }}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Stack justifyContent={"center"} alignItems={"center"}>
                {/* The logo  */}
                <img
                    src={LOGO_LIGHT_VERTICAL}
                    alt="Announcely Logo"
                    style={{ height: "196px" }}
                />
                {/* The paper container  */}
                <Paper elevation={1} sx={{ p: "16px", minWidth: "300px" }}>
                    <Stack
                        spacing={"14px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Typography fontSize={"24px"}>
                            Account Verification
                        </Typography>
                        <Typography fontSize={"14px"}>
                            {loading
                                ? "Verifying your email..."
                                : confirmed
                                ? "Your account has been confirmed. Redirecting to the login page.."
                                : "Unable to verify your account. Please try again or contact administrators."}
                        </Typography>
                        <Button
                            onClick={() => navigate("/login")}
                            disabled={loading}
                            variant="contained"
                            sx={{ height: "44px" }}
                        >
                            {loading ? "Please wait..." : "Login"}
                        </Button>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    );
}
