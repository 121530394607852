import {
    CircularProgress,
    Stack,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { Terminal } from "@mui/icons-material";
import moment from "moment";
import { useRecoilState } from "recoil";
import { aBotStatus, aProfile } from "../states/ui";
import { getProfile } from "../core/rtm";

export default function BotStatusControl() {
    const [status, setStatus] = useRecoilState(aBotStatus);
    const [profile, setProfile] = useRecoilState(aProfile);
    const theme = useTheme();

    function isOnline() {
        if (status?.lastSeen && status.status === "running") {
            return new Date().getTime() - status!.lastSeen < 60000;
        }
        return false;
    }

    useEffect(() => {
        let timer = setInterval(async () => {
            try {
                const p = await getProfile();
                setProfile(p);
                // Set status
                setStatus(p?.botStatus || null);
            } catch (err: any) {}
        }, 10000);
        return () => clearInterval(timer);
    }, []);

    return (
        <Tooltip title="Bot Information">
            <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                <Terminal
                    color={
                        status?.status === "running" && isOnline()
                            ? "success"
                            : status?.status === "stopped"
                            ? "error"
                            : status?.status === "provisioning" ||
                              status?.status === "updating"
                            ? "warning"
                            : "error"
                    }
                />

                {/* When online  and running */}
                {status && <Typography>{status?.version}</Typography>}
                {isOnline() && (
                    <Typography color={theme.palette.success.main}>
                        ONLINE
                    </Typography>
                )}
                {!isOnline() && status?.status !== "provisioning" && (
                    <Typography color={"error"}>OFFLINE!</Typography>
                )}
                {!isOnline() &&
                    status?.lastSeen &&
                    (status?.status === "stopped" ||
                        status?.status === "running") && (
                        <Typography>
                            Last seen:{" "}
                            {moment(new Date(status!.lastSeen)).fromNow()}
                        </Typography>
                    )}

                {status && status.status === "provisioning" && (
                    <Typography color={theme.palette.warning.main}>
                        Provisioning
                    </Typography>
                )}
                {status && status.status === "provisioning" && (
                    <CircularProgress size={"24px"} color="warning" />
                )}

                {/* When updating  */}
                {status && status.status === "updating" && (
                    <CircularProgress size="24px" color="warning" />
                )}
                {status && status.status === "updating" && (
                    <Typography color={theme.palette.warning.main}>
                        Updating
                    </Typography>
                )}
            </Stack>
        </Tooltip>
    );
}
