import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Divider, Stack, Typography } from "@mui/material";
import moment from "moment";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
        margin: theme.spacing(0.5),
        border: 0,
        "&.Mui-disabled": {
            border: 0,
        },
        "&:not(:first-of-type)": {
            borderRadius: theme.shape.borderRadius,
        },
        "&:first-of-type": {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

export default function RangePicker(props: {
    title?: string;
    onChange?: (range: { start: Date; end?: Date }) => void;
}) {
    const [range, setRange] = React.useState<any>();

    const [filter, setFilter] = React.useState("1-month");

    React.useEffect(() => {
        if (!props.onChange) return;
        switch (filter) {
            case "today":
                props.onChange({
                    start: moment().startOf("day").toDate(),
                });
                break;
            case "yesterday":
                props.onChange({
                    start: moment().subtract(1, "day").startOf("day").toDate(),
                });
                break;
            case "3-day":
                props.onChange({
                    start: moment().subtract(2, "day").startOf("day").toDate(),
                });
                break;
            case "week":
                // Set startDate to 7 days ago
                props.onChange({
                    start: moment().subtract(6, "days").startOf("day").toDate(),
                });
                break;
            case "two-week":
                props.onChange({
                    start: moment()
                        .subtract(13, "days")
                        .startOf("day")
                        .toDate(),
                });
                break;
            case "1-month":
                props.onChange({
                    start: moment()
                        .subtract(1, "month")
                        .startOf("day")
                        .toDate(),
                });
                break;
            case "2-month":
                props.onChange({
                    start: moment()
                        .subtract(2, "month")
                        .startOf("day")
                        .toDate(),
                });
                break;
            case "3-month":
                props.onChange({
                    start: moment()
                        .subtract(3, "month")
                        .startOf("day")
                        .toDate(),
                });
                break;
            case "custom":
                if (Boolean(range) === false) return;
                if (typeof range == typeof Date) {
                    // We have one date.
                    props.onChange({
                        start: moment(range).startOf("day").toDate(),
                    });
                } else {
                    props.onChange({
                        start: moment(range[0]).startOf("day").toDate(),
                        end: moment(range[1]).endOf("day").toDate(),
                    });
                }
                break;
        }
    }, [range, filter]);

    const handleFilter = (
        event: React.MouseEvent<HTMLElement>,
        newFilter: string
    ) => {
        setFilter(newFilter);
    };

    return (
        <Paper
            elevation={0}
            sx={{
                display: "flex",
                flexWrap: "wrap",
                background: "transparent",
            }}
        >
            {props.title && (
                <Stack
                    direction="row"
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Typography sx={{ mx: 2 }}>{props.title}</Typography>
                    <Divider orientation="vertical" />
                </Stack>
            )}
            <StyledToggleButtonGroup
                size="small"
                value={filter}
                exclusive
                onChange={handleFilter}
                aria-label="time frame"
            >
                <ToggleButton value="today" aria-label="Today">
                    <Typography variant="button">TODAY</Typography>
                </ToggleButton>
                <ToggleButton value="yesterday" aria-label="Yesterday">
                    <Typography variant="button">YESTERDAY</Typography>
                </ToggleButton>
                <ToggleButton value="3-day" aria-label="3 days">
                    <Typography variant="button">3d</Typography>
                </ToggleButton>
                <ToggleButton value="week" aria-label="7 days">
                    <Typography variant="button">7d</Typography>
                </ToggleButton>
                <ToggleButton value="two-week" aria-label="two weeks">
                    <Typography variant="button">14d</Typography>{" "}
                </ToggleButton>
                <ToggleButton value="1-month" aria-label="month">
                    <Typography variant="button">1mo</Typography>
                </ToggleButton>

                <ToggleButton value="2-month" aria-label="2 month">
                    <Typography variant="button">2mo</Typography>
                </ToggleButton>
                <ToggleButton value="3-month" aria-label="3 month">
                    <Typography variant="button">3mo</Typography>
                </ToggleButton>

                {/* <ToggleButton value="custom" aria-label="custom">
					<Typography variant="button">CUSTOM</Typography>
				</ToggleButton> */}
            </StyledToggleButtonGroup>
        </Paper>
    );
}
