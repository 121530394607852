import { BotStatus, Customer, SubscriptionKind } from "@announcely/models";
import {
    getDiscordConfiguration,
    getTelegramConfiguration,
    getWhatsAppConfiguration,
} from "./rtm";

export function parseStripeAmount(cents: number) {
    const dollars = Math.floor(cents / 100);
    const remainingCents = cents % 100;
    return { dollars, cents: remainingCents };
}

export function hasAnySubscription(cust: Customer) {
    const subCheck =
        cust &&
        cust.billing &&
        cust.billing.subscriptions &&
        cust.billing.subscriptions.length > 0 &&
        (cust.billing.subscriptions[0].status === "active" ||
            cust.billing.subscriptions[0].status === "trialing");
    return subCheck;
}

export function hasWhatsAppSubscription(cust?: Customer | null) {
    const subCheck =
        cust &&
        cust.billing &&
        cust.billing.subscriptions &&
        cust.billing.subscriptions.length > 0 &&
        (cust.billing.subscriptions[0].status === "active" ||
            cust.billing.subscriptions[0].status === "trialing");
    if (subCheck) {
        const sub = cust.billing.subscriptions[0].kind;
        return sub === SubscriptionKind.Aio;
    }
    return false;
}

export function hasTelegramSubscription(cust?: Customer | null) {
    const subCheck =
        cust &&
        cust.billing &&
        cust.billing.subscriptions &&
        cust.billing.subscriptions.length > 0 &&
        (cust.billing.subscriptions[0].status === "active" ||
            cust.billing.subscriptions[0].status === "trialing");
    if (subCheck) {
        const sub = cust.billing.subscriptions[0].kind;
        return (
            sub === SubscriptionKind.Aio || sub === SubscriptionKind.Telegram
        );
    }
    return false;
}

export async function canAccessWhatsApp(cust: any) {
    const k = cust?.billing?.subscriptions[0]?.kind;
    if (k && k === SubscriptionKind.Aio) {
        const whatConfig = await getWhatsAppConfiguration();
        if (whatConfig && Boolean(whatConfig?.account)) {
            return true;
        }
        return false;
    }
    return false;
}

export function hasPremium(cust: any) {
    const k = cust?.billing?.subscriptions[0]?.kind;
    if (k && k === SubscriptionKind.Aio) {
        return true;
    }
    return false;
}

export async function canAccessTelegram(cust: any) {
    const k = cust?.billing?.subscriptions[0]?.kind;
    if (k && (k === SubscriptionKind.Aio || k === SubscriptionKind.Telegram)) {
        // Make sure telegram is configured as well
        const tgConfigs = await getTelegramConfiguration();
        if (tgConfigs && Boolean(tgConfigs?.account)) return true;
        return false;
    }
    return false;
}

/**
 * Returns true if: 1. User has subscription, 2. Bot is online, 3. Discord is configured, 4. One of the platforms is configured.
 * @param cust
 * @returns
 */
export async function canAccessFeatures(cust: Customer) {
    // Subscription check
    const subCheck =
        cust &&
        cust.billing &&
        cust.billing.subscriptions &&
        cust.billing.subscriptions.length > 0 &&
        (cust.billing.subscriptions[0].status === "active" ||
            cust.billing.subscriptions[0].status === "trialing");
    // Bots check. Features only be accessible when:
    // 1. Bot must be online
    // 2. Discord must be configured
    // 3. WhatsApp or Telegram must be configured.
    const botCheck = cust.botStatus?.status === "running";

    const discordCfg = await getDiscordConfiguration();
    const discCheck =
        discordCfg &&
        Boolean(discordCfg?.clientId) &&
        Boolean(discordCfg?.token) &&
        Boolean(discordCfg?.uid);

    // WhatsApp check.
    const whatConfig = await getWhatsAppConfiguration();
    const whatCheck = whatConfig && Boolean(whatConfig?.account);

    // Telegram check
    const tgConfig = await getTelegramConfiguration();
    const tgCheck = tgConfig && Boolean(tgConfig?.account);

    return subCheck && botCheck && discCheck && (whatCheck || tgCheck);
}

export function isBotOnline(bs: any) {
    if (bs && bs?.lastSeen && bs.status === "running") {
        return new Date().getTime() - bs!.lastSeen < 60000;
    }
    return false;
}
