import { useSnackbar } from "notistack";
import TextInput from "../components/TextInput";
import { DialogProps } from "./DialogProps";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { BotCommand, DiscordBotConfiguration } from "@announcely/models";
import { setCommand, waitForCommandResult } from "../core/rtm";

export default function DiscordConfigurationDialog(
    props: DialogProps<DiscordBotConfiguration>
) {
    const [busy, setBusy] = useState(false);
    const [token, setToken] = useState("");
    const [clientId, setClientId] = useState("");

    const { enqueueSnackbar } = useSnackbar();

    // Save the settings.
    async function save() {
        try {
            setBusy(true);
            // Send the command to the bot, with new configuration.
            await setCommand({
                executed: false,
                kind: BotCommand.StartDiscord,
                input: {
                    token: token,
                    clientId: clientId,
                },
            });
            // Wait for result.
            const resp = await waitForCommandResult();
            // Check response
            if (resp && resp.executed) {
                // Command has been executed.
                if (resp.result?.success) {
                    // Logged in.
                    enqueueSnackbar("Successfully configured discord.", {
                        variant: "success",
                    });
                    props.closeHandler();
                } else {
                    enqueueSnackbar(
                        "Error configuring discord. Message: " +
                            resp.result?.data,
                        {
                            variant: "error",
                        }
                    );
                    console.log("Command failed to run successfully. ");
                    console.log(resp);
                }
            } else if (resp) {
                enqueueSnackbar(
                    "Command execution failed. " + resp.result?.data,
                    {
                        variant: "error",
                    }
                );
                console.log("Command executing failed. Command: ");
                console.log(resp);
            }
        } catch (err: any) {
            enqueueSnackbar(
                "Error applying configuration. See logs for details.",
                { variant: "error" }
            );
            console.log("Command executing failed. ERR: ");
            console.log(err);
        }
        setBusy(false);
    }

    useEffect(() => {
        if (props.data) {
            setToken(props.data.token);
            setClientId(props.data.clientId);
        }
    }, []);

    return (
        <Dialog
            open
            sx={{ backdropFilter: "blur(2px)" }}
            onClose={busy ? undefined : props.closeHandler}
            fullWidth
        >
            <DialogTitle>
                <Typography fontSize={20}>Configure Discord</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={"8px"}>
                    <TextInput
                        label="Token"
                        value={token}
                        onUpdate={(c) => setToken(c)}
                    />
                    <TextInput
                        value={clientId}
                        onUpdate={(c) => setClientId(c)}
                        label="Client Id"
                        helperText="See discord developers portal for more information."
                    />

                    <Stack></Stack>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ px: "24px", pb: "24px" }}>
                <Button onClick={props.closeHandler} disabled={busy}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={save} disabled={busy}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
