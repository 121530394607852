const LOGO_LIGHT_VERTICAL = require("./brand/vertical/light.png");
const LOGO_DARK_VERTICAL = require("./brand/vertical/dark.png");
const LOGO_DARK_BLUE_VERTICAL = require("./brand/vertical/blue_dark.png");
const LOGO_LIGHT_BLUE_VERTICAL = require("./brand/vertical/blue_light.png");

const LOGO_LIGHT_HORIZONTAL = require("./brand/horizontal/light.png");
const LOGO_DARK_HORIZONTAL = require("./brand/horizontal/dark.png");
const LOGO_DARK_BLUE_HORIZONTAL = require("./brand/horizontal/blue_dark.png");
const LOGO_LIGHT_BLUE_HORIZONTAL = require("./brand/horizontal/blue_light.png");

const LOGO_ISOTYPE = require("./brand/icon/isotype.png");
const LOGO_APP = require("./brand/icon/app.png");
const LOGO_APPX = require("./brand/icon/appx.png");

const IC_AVATAR = require("./ui/avatar.png");
const IC_CARD = require("./ui/card.png");

const IC_WHATSAPP = require("./ui/whatsapp.png");
const IC_TELEGRAM = require("./ui/telegram.png");
const IC_DISCORD = require("./ui/discord.png");
const IC_PREMIUM = require("./ui/premium.png");
const IC_STRIPE = require("./ui/stripe.png");

export {
	IC_STRIPE,
	IC_PREMIUM,
	IC_DISCORD,
	IC_WHATSAPP,
	IC_TELEGRAM,
	LOGO_APPX,
	LOGO_APP,
	LOGO_LIGHT_VERTICAL,
	LOGO_DARK_VERTICAL,
	LOGO_DARK_BLUE_VERTICAL,
	LOGO_LIGHT_BLUE_VERTICAL,
	LOGO_LIGHT_HORIZONTAL,
	LOGO_DARK_HORIZONTAL,
	LOGO_DARK_BLUE_HORIZONTAL,
	LOGO_LIGHT_BLUE_HORIZONTAL,
	IC_AVATAR,
	IC_CARD,
	LOGO_ISOTYPE,
};
