import { useSnackbar } from "notistack";
import { DialogProps } from "./DialogProps";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { BotCommand, WhatsAppConfiguration } from "@announcely/models";
import QRCode from "react-qr-code";
import { MoreVert, Warning } from "@mui/icons-material";
import {
    getWhatsAppConfiguration,
    setCommand,
    waitForCommandResult,
} from "../core/rtm";

export default function WhatsAppConfigurationDialog(
    props: DialogProps<WhatsAppConfiguration>
) {
    const [busy, setBusy] = useState(false);
    const [config, setConfig] = useState<WhatsAppConfiguration>();

    const { enqueueSnackbar } = useSnackbar();

    let timer;

    async function loadData() {
        try {
            // Load data.
            const prof = await getWhatsAppConfiguration();
            if (prof) {
                setConfig(prof);
                console.log(prof);
            }
        } catch (err: any) {
            // Show error.
            enqueueSnackbar("Error loading data. See logs for details.", {
                variant: "error",
            });
            console.log(err);
        }
        // Reset timer
        timer = setTimeout(loadData, 1000);
    }

    async function disconnect() {
        try {
            setBusy(true);
            // Send the command to the bot, with new configuration.
            await setCommand({
                executed: false,
                kind: BotCommand.ResetWhatsApp,
            });
            // Wait for result.
            const resp = await waitForCommandResult();
            // Check response
            if (resp && resp.executed) {
                // Command has been executed.
                if (resp.result?.success) {
                    // Logged in.
                    enqueueSnackbar("Unlinked WhatsApp Account.", {
                        variant: "success",
                    });
                    props.closeHandler();
                } else {
                    enqueueSnackbar(
                        "Error resetting WhatsApp. Message: " +
                            resp.result?.data,
                        {
                            variant: "error",
                        }
                    );
                    console.log("Command failed to run successfully. ");
                    console.log(resp);
                }
            } else if (resp) {
                enqueueSnackbar(
                    "Command execution failed. " + resp.result?.data,
                    {
                        variant: "error",
                    }
                );
                console.log("Command executing failed. Command: ");
                console.log(resp);
            }
        } catch (err: any) {
            enqueueSnackbar(
                "Error applying configuration. See logs for details.",
                {
                    variant: "error",
                }
            );
            console.log("Command executing failed. ERR: ");
            console.log(err);
        }
        setBusy(false);
    }

    useEffect(() => {
        setConfig(props.data);
        timer = setTimeout(loadData, 1000);
    }, []);

    function RenderAccountInfo() {
        return (
            <Stack spacing={"12px"}>
                {config?.account && (
                    <>
                        <Stack direction={"row"} spacing={"12px"}>
                            <img
                                src={config?.account?.pictureUrl}
                                width={"128px"}
                                height={"128px"}
                                alt={config?.account?.name}
                            />
                            <Stack>
                                <Typography fontSize={24}>
                                    {config.account.name}
                                </Typography>
                                <Typography fontSize={20}>
                                    +{config.account.number}
                                </Typography>
                                <Typography>Connected</Typography>
                                <Button
                                    onClick={disconnect}
                                    color="error"
                                    sx={{ alignSelf: "start" }}
                                    disabled={busy}
                                >
                                    Disconnect
                                </Button>
                            </Stack>
                        </Stack>
                    </>
                )}
            </Stack>
        );
    }

    function RenderQr() {
        return (
            <Stack alignItems={"center"} spacing={"12px"}>
                <Stack
                    sx={{
                        width: "300px",
                        height: "300px",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid white",
                    }}
                >
                    {config?.qr && (
                        <QRCode
                            value={config?.qr || ""}
                            width={"100%"}
                            height={"100%"}
                        />
                    )}
                </Stack>
                <Typography>
                    Click on{" "}
                    <Link
                        target="_blank"
                        href="https://faq.whatsapp.com/1189188245314343/?helpref=hc_fnav&cms_platform=android"
                    >
                        More options
                    </Link>{" "}
                    icon, Go to Linked Device, and Scan the QR Code from your
                    Phone. Please be patient after scanning the QR Code while
                    the bot synchronizes everything.
                </Typography>
                <Alert variant="outlined" icon={<Warning />} color="warning">
                    For security reasons, do not use your personal account with
                    Announcely. Also note that it may take a while to sync
                    chats.
                </Alert>
            </Stack>
        );
    }

    return (
        <Dialog
            open
            sx={{ backdropFilter: "blur(2px)" }}
            onClose={busy ? undefined : props.closeHandler}
            fullWidth
        >
            <DialogTitle>
                <Typography fontSize={20}>Configure WhatsApp</Typography>
            </DialogTitle>
            <DialogContent>
                {config?.account && <RenderAccountInfo />}
                {!config?.account && <RenderQr />}
            </DialogContent>
            <DialogActions sx={{ px: "24px", pb: "24px" }}>
                <Button onClick={props.closeHandler} disabled={busy}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
