import {
    Paper,
    Stack,
    Typography,
    useTheme,
    Button,
    Link,
} from "@mui/material";
import React, { useState } from "react";
import TextInput from "../components/TextInput";
import { useNavigate } from "react-router-dom";
import { LOGO_LIGHT_VERTICAL } from "../assets";
import { enqueueSnackbar } from "notistack";
import { ArrowBack } from "@mui/icons-material";
import { SendPasswordResetEmail } from "../core/rest";

export default function PagePasswordResetRequest() {
    const [email, setEmail] = useState("");
    const [linkSent, setLinkSent] = useState(false);
    const [error, setError] = useState("");
    const [busy, setBusy] = useState(false);

    async function send() {
        try {
            if (!email) {
                setError("email");
                return;
            }
            setBusy(true);
            await SendPasswordResetEmail(email);
            enqueueSnackbar("Password reset email has been sent.", {
                variant: "success",
            });
            setLinkSent(true);
        } catch (err: any) {}
        setBusy(false);
    }
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <Stack
            sx={{
                height: "100vh",
                width: "100vw",
                background: theme.palette.background.default,
            }}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Stack justifyContent={"center"} alignItems={"center"}>
                {/* The logo  */}
                <img
                    src={LOGO_LIGHT_VERTICAL}
                    alt="Announcely Logo"
                    style={{ height: "196px" }}
                />
                {/* The paper container  */}
                <Paper elevation={1} sx={{ p: "16px", minWidth: "300px" }}>
                    {linkSent && (
                        <Stack spacing="14px" sx={{ maxWidth: "350px" }}>
                            <Typography fontSize={"24px"}>
                                Reset Password
                            </Typography>
                            <Typography>
                                Please check your inbox and follow the password
                                reset instructions.
                            </Typography>
                            <Button
                                onClick={() => navigate("/login")}
                                sx={{ alignSelf: "start" }}
                                startIcon={<ArrowBack />}
                            >
                                Back to Login
                            </Button>
                        </Stack>
                    )}
                    {!linkSent && (
                        <Stack spacing="14px" sx={{ maxWidth: "350px" }}>
                            <Typography fontSize={"24px"}>
                                Reset Password
                            </Typography>
                            <Typography>
                                Please enter your registered email.
                            </Typography>

                            <TextInput
                                disabled={busy}
                                label="Email"
                                error={error === "email"}
                                value={email}
                                onUpdate={(p) => setEmail(p)}
                            />
                            <Button
                                disabled={busy}
                                onClick={send}
                                variant="contained"
                                sx={{ height: "44px" }}
                            >
                                Submit
                            </Button>
                            <Typography>
                                Know your password?{" "}
                                <Button onClick={() => navigate("/login")}>
                                    Sign in
                                </Button>
                            </Typography>
                        </Stack>
                    )}
                </Paper>
            </Stack>
        </Stack>
    );
}
