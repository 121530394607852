import { useEffect, useState } from "react";
import { DialogProps } from "./DialogProps";
import {
    Button,
    Chip,
    Dialog,
    Paper,
    Stack,
    Switch,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import {
    AnnouncelyPricing,
    Customer,
    SubscriptionKind,
} from "@announcely/models";
import { IC_PREMIUM, IC_STRIPE, IC_TELEGRAM, LOGO_APPX } from "../assets";
import { getCheckout, getPricing } from "../core/rtm";
import { enqueueSnackbar } from "notistack";
import {
    Analytics,
    Campaign,
    LinkSharp,
    Telegram,
    WhatsApp,
} from "@mui/icons-material";
import { Player } from "@lottiefiles/react-lottie-player";

export default function SubscribeDialog(props: DialogProps<Customer>) {
    const [busy, setBusy] = useState(false);
    const [yearly, setYearly] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<"telegram" | "premium">();
    const [pricing, setPricing] = useState<AnnouncelyPricing>();
    const theme = useTheme();

    async function load() {
        try {
            const _pricing = await getPricing();
            if (_pricing) setPricing(_pricing);
        } catch (err: any) {
            enqueueSnackbar("Error loading prices. Please try again. ", {
                variant: "error",
            });
        }
    }

    async function checkout() {
        try {
            setBusy(true);
            const sess = await getCheckout(
                yearly ? "yearly" : "monthly",
                selectedPlan === "premium"
                    ? SubscriptionKind.Aio
                    : SubscriptionKind.Telegram
            );
            // Open the checkout session
            window.location = sess.url;
        } catch (err: any) {
            enqueueSnackbar(
                "Error creating checkout session. Please try again or if the issue persists, contact administrators.",
                { variant: "error" }
            );
            console.log(err);
            setBusy(false);
        }
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <Dialog
            open
            sx={{ backdropFilter: "blur(2px)" }}
            onClose={props.closeHandler}
            fullScreen
        >
            <Stack
                spacing={"18px"}
                sx={{
                    background: theme.palette.background.default,
                    height: "100vh",
                    overflow: "auto",
                    width: "100%",
                    transition: "all .5s",
                }}
                alignItems={"center"}
            >
                <img
                    src={LOGO_APPX}
                    alt="Announcely Logo"
                    height={88}
                    style={{
                        position: "absolute",
                        top: "44px",
                        left: "60px",
                    }}
                    width={88}
                />
                <Typography
                    fontSize={36}
                    fontWeight={"bold"}
                    textAlign={"center"}
                >
                    Choose your perfect plan.
                </Typography>
                <Typography
                    fontSize={26}
                    fontWeight={"bold"}
                    textAlign={"center"}
                >
                    Start building your audience today.
                </Typography>
                <Stack spacing={"8px"} direction={"row"} alignItems={"center"}>
                    <Typography>Monthly</Typography>
                    <Switch
                        checked={yearly}
                        onChange={(c) => setYearly(c.currentTarget.checked)}
                        color="success"
                        content="Billing cycle"
                    />
                    <Typography>Yearly</Typography>
                    <Chip label="10% OFF!" color="success" size="small"></Chip>
                </Stack>
                <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    flex={1}
                    direction={"row"}
                    spacing="18px"
                >
                    <Paper
                        elevation={4}
                        sx={{
                            background: "#505050",
                            px: "18px",
                            py: "18px",
                            width: "300px",
                            height: "500px",
                            border:
                                selectedPlan === "premium"
                                    ? "1px solid #4488FF"
                                    : "1px solid transparent",
                            cursor: busy ? "not-allowed" : "pointer",
                        }}
                        onClick={
                            busy ? undefined : () => setSelectedPlan("premium")
                        }
                    >
                        <Stack
                            sx={{ height: "100%" }}
                            direction={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            spacing="18px"
                        >
                            <img
                                src={IC_PREMIUM}
                                alt="Premium Package Icon"
                                style={{ height: "64px" }}
                            />
                            <Typography fontSize={"24px"}>Premium</Typography>
                            <Typography fontSize={"20px"}>
                                {yearly
                                    ? pricing?.yearly?.aio
                                    : pricing?.monthly?.aio}
                            </Typography>
                            <Typography>Features</Typography>
                            <Stack
                                alignItems={"center"}
                                justifyContent={"center"}
                                spacing="18px"
                            >
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <WhatsApp />
                                    <Typography>
                                        WhatsApp Integration
                                    </Typography>
                                    <Tooltip title="The feature is experimental and currently in development. Expect bugs and errors to occur using WhatsApp features.">
                                        <Chip
                                            size="small"
                                            label="Beta"
                                            variant="filled"
                                            color="warning"
                                        ></Chip>
                                    </Tooltip>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Telegram />
                                    <Typography>
                                        Telegram Integration
                                    </Typography>
                                </Stack>
                                {/* <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Send />
                                    <Typography>
                                        Channel Subscriptions
                                    </Typography>
                                </Stack> */}
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <LinkSharp />
                                    <Typography>Linked Chats</Typography>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Campaign />
                                    <Typography>
                                        Scheduled Announcements
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Analytics />
                                    <Typography>Analytics</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Paper>

                    <Paper
                        elevation={4}
                        sx={{
                            background: "#505050",
                            px: "18px",
                            py: "18px",
                            height: "500px",
                            width: "300px",
                            border:
                                selectedPlan === "telegram"
                                    ? "1px solid #4488FF"
                                    : "1px solid transparent",
                            cursor: busy ? "not-allowed" : "pointer",
                        }}
                        onClick={
                            busy ? undefined : () => setSelectedPlan("telegram")
                        }
                    >
                        <Stack
                            sx={{ height: "100%" }}
                            direction={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            spacing="18px"
                        >
                            <img
                                src={IC_TELEGRAM}
                                alt="Telegram Package Icon"
                                style={{ height: "64px" }}
                            />
                            <Typography fontSize={"24px"}>Telegram</Typography>
                            <Typography fontSize={"20px"}>
                                {yearly
                                    ? pricing?.yearly?.telegram
                                    : pricing?.monthly?.telegram}
                            </Typography>
                            <Typography>Features</Typography>
                            <Stack
                                alignItems={"center"}
                                justifyContent={"center"}
                                spacing="18px"
                            >
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Telegram />
                                    <Typography>
                                        Telegram Integration
                                    </Typography>
                                </Stack>
                                {/* <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Send />
                                    <Typography>
                                        Channel Subscriptions
                                    </Typography>
                                </Stack> */}
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <LinkSharp />
                                    <Typography>Linked Chats</Typography>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Campaign />
                                    <Typography>
                                        Scheduled Announcements
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    spacing="8px"
                                    alignItems={"center"}
                                >
                                    <Analytics />
                                    <Typography>Analytics</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Paper>
                </Stack>

                {!busy && (
                    <Button
                        onClick={checkout}
                        variant="contained"
                        size="large"
                        sx={{
                            borderRadius: 1,
                            width: "200px",
                            height: "50px",
                        }}
                    >
                        Checkout
                    </Button>
                )}

                {busy && (
                    <Player
                        autoplay
                        loop
                        src="https://lottie.host/1d29df75-d07f-4bb8-a3af-e1a1123a14d8/6YGpRhoctT.json"
                        style={{ height: "120px" }}
                    />
                )}

                <img
                    src={IC_STRIPE}
                    style={{ height: "32px" }}
                    alt="Powered by Stripe"
                />
            </Stack>
        </Dialog>
    );
}
