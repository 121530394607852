import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";

/**
 * Wraps the children in the theme provider using the current application theme set in the state.
 * @param props
 * @returns
 */
export default function ThemeManager(props: any) {
    const theme = createTheme({
        typography: {
            fontFamily: "Poppins",
            allVariants: {
                color: "#D4D4D4",
            },
        },
        palette: {
            mode: "dark",
            background: {
                default: "#343434",
                paper: "#1E1E1E",
            },
            primary: {
                main: "#4488FF",
            },
            secondary: {
                main: "#A234DE",
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: "12px",
                        textTransform: "none",
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: "12px",
                    },
                    elevation0: {
                        boxShadow: "0 0 0px 0px black",
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        borderRadius: "25px",
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    // Removes the lines
                    root: ({ ownerState, theme }) => ({
                        borderBottom: "none",
                    }),
                    // Makes them rounded.
                    head: ({ theme }) => ({
                        transition: "all .2s",
                        background: "#202020DD",
                        fontWeight: 500,
                        fontSize: "10px",
                        border: "solid 1px " + theme.palette.primary.main,
                        borderLeft: "0px solid",
                        borderRight: "0px solid",
                        ":first-child": {
                            border: "solid 1px " + theme.palette.primary.main,
                            borderTopLeftRadius: "12px",
                            borderBottomLeftRadius: "12px",
                            borderRight: "0px solid",
                        },
                        ":last-child": {
                            border: "solid 1px " + theme.palette.primary.main,
                            borderTopRightRadius: "12px",
                            borderBottomRightRadius: "12px",
                            borderLeft: "0px solid transparent",
                        },
                        [theme.breakpoints.up("sm")]: { fontSize: "12px" },
                    }),
                    body: ({ theme }) => ({
                        transition: "all .2s",
                        background: `transparent`,
                        fontWeight: 500,
                        fontSize: "10px",
                        ":first-child": {
                            border: "solid 0px transparent",
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                        },
                        ":last-child": {
                            border: "solid 0px transparent",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                        },
                        [theme.breakpoints.up("sm")]: { fontSize: "12px" },
                    }),
                },
            },
        },
    });
    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
