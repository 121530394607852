import { Paper, Stack, Typography, useTheme, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextInput from "../components/TextInput";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGO_LIGHT_VERTICAL } from "../assets";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { FbAuth } from "../core/firebase";
import { enqueueSnackbar } from "notistack";
import { ArrowBack } from "@mui/icons-material";

export default function PagePasswordReset() {
    const [verified, setVerified] = useState(false);
    const [busy, setBusy] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const [isChanged, setIsChanged] = useState(false);
    const loc = useLocation();
    const code = loc.state?.code;

    const theme = useTheme();
    const navigate = useNavigate();

    async function updatePassword() {
        try {
            if (password !== passwordConfirm) {
                setError("password");
                return;
            }
            setBusy(true);
            await confirmPasswordReset(FbAuth, code, password);
            // Send verification email.
            enqueueSnackbar("Your password has been updated.", {
                variant: "success",
            });
            // IF continue url is set, we navigatte.
            setIsChanged(true);
        } catch (err: any) {
            enqueueSnackbar(
                "Error updating your password. Please contact administrators.",
                {
                    variant: "error",
                }
            );
            console.log(err);
            setError("error");
        }
        setBusy(false);
    }

    async function verifyCode() {
        try {
            setBusy(true);
            // Proceed.
            const email = await verifyPasswordResetCode(FbAuth, code);
            setEmail(email);
            setVerified(true);
        } catch (err: any) {
            enqueueSnackbar(
                "Error verifying password reset code. Please contact administrators.",
                {
                    variant: "error",
                }
            );
            console.log(err);
            setError("verification");
            setVerified(false);
        }
        setBusy(false);
    }

    useEffect(() => {
        verifyCode();
    }, []);
    return (
        <Stack
            sx={{
                height: "100vh",
                width: "100vw",
                background: theme.palette.background.default,
            }}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Stack justifyContent={"center"} alignItems={"center"}>
                {/* The logo  */}
                <img
                    src={LOGO_LIGHT_VERTICAL}
                    alt="Announcely Logo"
                    style={{ height: "196px" }}
                />
                {/* The paper container  */}
                <Paper elevation={1} sx={{ p: "16px", minWidth: "300px" }}>
                    {verified && !isChanged && (
                        <Stack spacing={"14px"}>
                            <Typography fontSize={"24px"}>
                                Update Password
                            </Typography>
                            <Typography>
                                Please enter your new password.
                            </Typography>
                            <TextInput
                                disabled={busy}
                                label="Password"
                                type="password"
                                error={error === "password"}
                                value={password}
                                onUpdate={(p) => setPassword(p)}
                            />
                            <TextInput
                                disabled={busy}
                                label="Confirm Password"
                                error={error === "password"}
                                type="password"
                                value={passwordConfirm}
                                onUpdate={(p) => setPasswordConfirm(p)}
                            />
                            <Button
                                disabled={busy || !verified}
                                onClick={updatePassword}
                                variant="contained"
                                sx={{ height: "44px" }}
                            >
                                Submit
                            </Button>
                        </Stack>
                    )}
                    {verified && isChanged && (
                        <Stack spacing={"14px"}>
                            <Typography fontSize={"24px"}>
                                Password Updated
                            </Typography>
                            <Typography>
                                Your password has been updated. You may now log
                                in.
                            </Typography>
                            <Button
                                onClick={() => navigate("/login")}
                                sx={{ alignSelf: "start" }}
                                startIcon={<ArrowBack />}
                            >
                                Back to Login
                            </Button>
                        </Stack>
                    )}
                    {!verified && error === "verification" && (
                        <Stack spacing={"14px"}>
                            <Typography fontSize={"24px"}>
                                Verification Failed
                            </Typography>
                            <Typography>
                                The link has expired or is invalid. Please try
                                again.
                            </Typography>
                            <Button
                                onClick={() => navigate("/login")}
                                sx={{ alignSelf: "start" }}
                                startIcon={<ArrowBack />}
                            >
                                Back to Login
                            </Button>
                        </Stack>
                    )}
                    {!verified && busy && (
                        <Stack spacing={"14px"}>
                            <Typography fontSize={"24px"}>
                                Verifying Code
                            </Typography>
                            <Typography>
                                Please wait while the code is being verified...
                            </Typography>
                        </Stack>
                    )}
                </Paper>
            </Stack>
        </Stack>
    );
}
