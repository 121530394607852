import { useSnackbar } from "notistack";
import TextInput from "../components/TextInput";
import { DialogProps } from "./DialogProps";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { BotCommand, TelegramConfiguration } from "@announcely/models";
import { setCommand, waitForCommandResult } from "../core/rtm";

export default function TelegramConfigurationDialog(
    props: DialogProps<TelegramConfiguration>
) {
    const [busy, setBusy] = useState(false);
    const [usernameToken, setUsernameToken] = useState("");
    // After login is sent, this is set to true. In user mode,
    // this shows the 'code' input.
    const [loginSent, setLoginSent] = useState(false);
    const theme = useTheme();
    const config = props.data;

    const { enqueueSnackbar } = useSnackbar();

    // Save the settings.
    async function save() {
        try {
            setBusy(true);
            // Send the command to the bot, with new configuration.
            await setCommand({
                executed: false,
                kind: BotCommand.StartTelegram,
                input: {
                    loginOrToken: usernameToken,
                },
            });
            setLoginSent(true);
            // Wait for result.
            const resp = await waitForCommandResult();
            // Check response
            if (resp && resp.executed) {
                // Command has been executed.
                if (resp.result?.success) {
                    // Logged in.
                    enqueueSnackbar("Successfully configured telegram.", {
                        variant: "success",
                    });
                    props.closeHandler();
                } else {
                    enqueueSnackbar(
                        "Error configuring telegram. Message: " +
                            resp.result?.data?.message,
                        {
                            variant: "error",
                        }
                    );
                    console.log("Command failed to run successfully. ");
                    console.log(resp);
                }
            } else if (resp) {
                enqueueSnackbar(
                    "Command execution failed. " + resp.result?.data,
                    {
                        variant: "error",
                    }
                );
                console.log("Command executing failed. Command: ");
                console.log(resp);
            }
            setBusy(false);
        } catch (err: any) {
            enqueueSnackbar(
                "Error applying configuration. See logs for details.",
                {
                    variant: "error",
                }
            );
            console.log("Command executing failed. ERR: ");
            console.log(err);
        }
        setBusy(false);
        setLoginSent(false);
    }

    async function disconnect() {
        try {
            setBusy(true);
            // Send the command to the bot, with new configuration.
            await setCommand({
                executed: false,
                kind: BotCommand.ResetTelegram,
            });
            // Wait for result.
            const resp = await waitForCommandResult();
            // Check response
            if (resp && resp.executed) {
                // Command has been executed.
                if (resp.result?.success) {
                    // Logged in.
                    enqueueSnackbar("Unlinked Telegram Account.", {
                        variant: "success",
                    });
                    props.closeHandler();
                } else {
                    enqueueSnackbar(
                        "Error resetting Telegram. Message: " +
                            resp.result?.data,
                        {
                            variant: "error",
                        }
                    );
                    console.log("Command failed to run successfully. ");
                    console.log(resp);
                }
            } else if (resp) {
                enqueueSnackbar(
                    "Command execution failed. " + resp.result?.data,
                    {
                        variant: "error",
                    }
                );
                console.log("Command executing failed. Command: ");
                console.log(resp);
            }
        } catch (err: any) {
            enqueueSnackbar(
                "Error applying configuration. See logs for details.",
                {
                    variant: "error",
                }
            );
            console.log("Command executing failed. ERR: ");
            console.log(err);
        }
        setBusy(false);
    }

    useEffect(() => {
        if (props.data) {
        }
    }, []);

    // If configured, we return another dialog
    if (config && config.account) {
        return (
            <Dialog
                open
                sx={{ backdropFilter: "blur(2px)" }}
                onClose={busy ? undefined : props.closeHandler}
                fullWidth
            >
                <DialogTitle>
                    <Typography fontSize={20}>Configure Telegram</Typography>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={"12px"}>
                        {config?.account && (
                            <>
                                <Stack direction={"row"} spacing={"12px"}>
                                    <img
                                        src={""}
                                        width={"128px"}
                                        height={"128px"}
                                        alt={config?.account?.fullName}
                                    />
                                    <Stack>
                                        <Typography fontSize={24}>
                                            {config.account.fullName}
                                        </Typography>
                                        <Typography fontSize={20}>
                                            @{config.account.username}
                                        </Typography>
                                        <Typography>
                                            Connected with Telegram
                                        </Typography>
                                        <Button
                                            onClick={disconnect}
                                            color="error"
                                            sx={{ alignSelf: "start" }}
                                            disabled={busy}
                                        >
                                            Disconnect
                                        </Button>
                                    </Stack>
                                </Stack>
                            </>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ px: "24px", pb: "24px" }}>
                    <Button onClick={props.closeHandler} disabled={busy}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Dialog
            open
            sx={{ backdropFilter: "blur(2px)" }}
            onClose={busy ? undefined : props.closeHandler}
            fullWidth
        >
            <DialogTitle>
                <Typography fontSize={20}>Configure Telegram</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={"8px"}>
                    <TextInput
                        disabled={busy}
                        label={"Bot Token"}
                        value={usernameToken}
                        helperText={
                            "Chat with @BotFather on telegram to get your own bot token."
                        }
                        onUpdate={(c) => setUsernameToken(c)}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ px: "24px", pb: "24px" }}>
                <Button onClick={props.closeHandler} disabled={busy}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={save} disabled={busy}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
