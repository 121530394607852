import {
    AddRounded,
    Refresh,
    Telegram,
    Warning,
    WhatsApp,
} from "@mui/icons-material";
import {
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { aCurrentSection, aProfile, aSelectedServer } from "../states/ui";
import { useSnackbar } from "notistack";
import { AnnouncelyPlatform, LinkedChat } from "@announcely/models";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import CreateLinkDialog from "../dialogs/CreateLink";
import ViewLinkDialog from "../dialogs/LinkInfo";
import { canAccessFeatures } from "../core/helper";
import OptionsGroup from "../components/OptionsGroup";
import { getLinkedChats } from "../core/rtm";

export default function SectionLinks() {
    const [, setCurrentSection] = useRecoilState(aCurrentSection);
    const [links, setLinks] = React.useState<LinkedChat[]>([]);
    const [busy, setBusy] = React.useState(false);
    const [selectedServer] = useRecoilState(aSelectedServer);
    const [profile] = useRecoilState(aProfile);
    const [canAccess, setCanAccess] = useState(false);
    const [filter, setFilter] = useState<"all" | "telegram" | "whatsapp">(
        "all"
    );

    const { enqueueSnackbar } = useSnackbar();
    const { showModal } = useModal();

    async function LoadChats() {
        try {
            if (busy) return;
            setBusy(true);
            // Load subscribers
            if (profile) {
                const check = await canAccessFeatures(profile);
                setCanAccess(check);
            }
            const _chats = await getLinkedChats();
            const subs = _chats.filter(
                (g) => g.sourceGuildId === selectedServer
            );

            switch (filter) {
                case "all": {
                    setLinks(subs || []);
                    break;
                }
                case "telegram": {
                    // Only with subscriptions
                    const subbed = subs?.filter(
                        (c) => c.platform === AnnouncelyPlatform.Telegram
                    );
                    setLinks(subbed || []);
                    break;
                }
                case "whatsapp": {
                    // Only in waitlist
                    const subbed = subs?.filter(
                        (c) => c.platform === AnnouncelyPlatform.WhatsApp
                    );
                    setLinks(subbed || []);
                    break;
                }
            }
        } catch (err: any) {
            enqueueSnackbar("Error loading linked chats, please try again.", {
                variant: "error",
            });
            console.log(err);
        }
        setBusy(false);
    }

    function showCreateDialog() {
        const modal = showModal(CreateLinkDialog, {
            closeHandler() {
                modal?.destroy();
                // Reload links
                LoadChats();
            },
        });
    }

    function showEditDialog(link: any) {
        const modal = showModal(ViewLinkDialog, {
            closeHandler() {
                modal?.destroy();
                // Reload links
                LoadChats();
            },
            data: link,
        });
    }

    useEffect(() => {
        setCurrentSection("Linked Chats");
    }, []);

    useEffect(() => {
        LoadChats();
    }, [filter]);

    return (
        <Stack sx={{ overflow: "hidden" }}>
            <Stack
                flex={1}
                sx={{ p: "24px", pt: "48px", overflow: "hidden" }}
                spacing={"8px"}
            >
                <Stack direction={"row"} alignItems={"center"} spacing="8px">
                    <Button
                        disabled={busy}
                        onClick={LoadChats}
                        variant="contained"
                        startIcon={<Refresh />}
                    >
                        Refresh
                    </Button>
                    <Button
                        disabled={!canAccess || busy}
                        onClick={showCreateDialog}
                        variant="outlined"
                        startIcon={<AddRounded />}
                    >
                        Create Link
                    </Button>
                    {canAccess === false && (
                        <Tooltip title={"Please go to settings to learn more."}>
                            <Warning color="error" />
                        </Tooltip>
                    )}
                    <OptionsGroup
                        onChange={(o) => setFilter(o?.value as any)}
                        title="Filter"
                        defaultOption="all"
                        options={[
                            { label: "All", value: "all" },
                            { label: "Telegram", value: "telegram" },
                            { label: "WhatsApp", value: "whatsapp" },
                        ]}
                    />
                </Stack>

                {/* The table for history */}
                <TableContainer sx={{ overflow: "auto" }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Link ID</TableCell>
                                <TableCell align="center">Created</TableCell>
                                <TableCell align="center">
                                    Source Channel
                                </TableCell>
                                <TableCell align="center">
                                    Target Chat
                                </TableCell>
                                <TableCell align="center">
                                    Target Platform
                                </TableCell>
                                <TableCell align="center">
                                    Messages Delivered
                                </TableCell>
                                <TableCell align="right">
                                    Last Delivery
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {links?.map((l) => (
                                <TableRow onClick={() => showEditDialog(l)}>
                                    <TableCell>{l.id}</TableCell>
                                    <TableCell align="center">
                                        {moment(new Date(l.created)).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {l.sourceGuildName}/
                                        {l.sourceChannelName}
                                    </TableCell>
                                    <TableCell align="center">
                                        {l.targetName}
                                    </TableCell>
                                    <TableCell align="center">
                                        {l.platform === "whatsapp" && (
                                            <WhatsApp />
                                        )}{" "}
                                        {l.platform === "telegram" && (
                                            <Telegram />
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {l.totalDelivered}
                                    </TableCell>
                                    <TableCell align="right">
                                        {l.lastDelivery
                                            ? moment(
                                                  new Date(l.lastDelivery)
                                              ).fromNow()
                                            : ""}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Stack>
    );
}
