import {
	filledInputClasses,
	inputLabelClasses,
	styled,
	TextField,
	TextFieldProps,
	useTheme,
} from "@mui/material";
import React from "react";

const MyTextField = styled(TextField)<TextFieldProps>((state) => ({
	" & .MuiFilledInput-root": {
		fontSize: "12px",
		borderRadius: "6px",
		fontWeight: 400,
		background: "#232323",
	},

	// For some reason, the label is also styled with inputs.
	[`& .${filledInputClasses.error}:not(.${inputLabelClasses.root})`]: {
		background: "#EDBDF833",
		border: "1px solid red",
	},
}));

export default function TextInput(
	props: TextFieldProps & {
		onUpdate?: (text: string) => void;
		filled?: boolean;
	}
) {
	const theme = useTheme();
	return (
		<MyTextField
			{...(props as any)}
			variant="filled"
			sx={{
				minHeight: "34px",
				...props.sx,
				" & .MuiFilledInput-root": {
					border: props.value
						? `1px solid ${theme.palette.primary.main}`
						: "1px solid #414141",
				},
			}}
			value={props.value}
			defaultValue={props.value}
			InputProps={{
				disableUnderline: true,
				...props.InputProps,
			}}
			onChange={(c) => {
				if (props.onChange) {
					props.onChange(c);
				}
				if (props.onUpdate) {
					props.onUpdate(c.currentTarget.value);
				}
			}}
		/>
	);
}
