import { Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import AppBar from "../components/AppBar";
import { Navigate, Route, Routes, useNavigate } from "react-router";
import SectionBilling from "../sections/billing";
import SectionDashboard from "../sections/dashboard";
import SectionLinks from "../sections/links";
import SectionSubscribers from "../sections/subscribers";
import SectionSettings from "../sections/settings";
import SectionAnnouncements from "../sections/announcements";
import SectionAnalytics from "../sections/analytics";
import { useRecoilState } from "recoil";
import { aProfile } from "../states/ui";
import { OnboardingStep } from "@announcely/models";
import { signOut } from "firebase/auth";
import { FbAuth } from "../core/firebase";
import { enqueueSnackbar } from "notistack";
import LoadingIndicator from "../components/LoadingIndicator";
import { getProfile } from "../core/rtm";

export default function PagePortal() {
    const theme = useTheme();
    const [profile, setProfile] = useRecoilState(aProfile);
    const nav = useNavigate();

    if (!profile) {
        return <LoadingIndicator title="Loading profile..." />;
    }

    if (profile && !profile.verified) {
        signOut(FbAuth);
        enqueueSnackbar(
            "You have been logged out. Reason: Email not verified.",
            {
                variant: "error",
            }
        );
        return <Navigate to="/login" />;
    }
    // If the account is not approved, we show waitlist
    if (profile && !profile.isApproved) {
        return <Navigate to="/waitlist" />;
    }
    if (profile && profile.onboardingStep !== OnboardingStep.Finished) {
        //we show welcome dialog.
        return <Navigate to="/onboarding" />;
    }

    return (
        <Stack
            sx={{
                height: "100vh",
                width: "100%",
                background: theme.palette.background.default,
            }}
            direction={"row"}
        >
            <Sidebar />
            <Stack
                flex={1}
                sx={{
                    p: "32px",
                    background: "rgba(19, 19, 19, 0.71)",
                    borderRadius: "25px",
                }}
            >
                {/* App bar goes here  */}
                <AppBar />

                {/* Content goes here  */}
                <Stack
                    flex={1}
                    sx={{
                        overflowY: "auto",
                        overflowX: "hidden",
                        height: "100%",
                    }}
                >
                    <Routes>
                        <Route path="links" element={<SectionLinks />} />
                        <Route
                            path="announcements"
                            element={<SectionAnnouncements />}
                        />
                        {/* <Route
                            path="subscribers"
                            element={<SectionSubscribers />}
                        /> */}
                        <Route
                            path="analytics"
                            element={<SectionAnalytics />}
                        />
                        <Route path="settings" element={<SectionSettings />} />
                        <Route path="billing" element={<SectionBilling />} />
                        <Route
                            path="dashboard"
                            element={<SectionDashboard />}
                        />

                        <Route path="*" element={<Navigate to="dashboard" />} />
                    </Routes>
                </Stack>
            </Stack>
        </Stack>
    );
}
