import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { aProfile, aSelectedServer } from "../states/ui";
import ComboBox, { ComboBoxItem } from "./ComboBox";

export default function GuildSelector() {
	const [, setSelectedServer] = useRecoilState(aSelectedServer);
	const [server, setServer] = useState<ComboBoxItem>();
	const [profile] = useRecoilState(aProfile);
	const [guilds, setGuilds] = useState<ComboBoxItem[]>();

	function load() {
		if (profile?.guilds) {
			let items: ComboBoxItem[] = [];
			let i = 0;
			for (let g of profile.guilds) {
				items.push({
					index: i,
					name: g.name,
					object: g,
				});
				i++;
			}
			setGuilds(items);
			// if default guild is not selected, we select the first one
			if (!server) {
				setSelectedServer(items[0]?.object?.id);
				setServer(items[0]);
			}
		}
	}

	useEffect(() => {
		load();
	}, [profile]);

	return (
		<ComboBox
			key={server?.index}
			sx={{ width: "300px" }}
			values={guilds}
			changed={(i) => {
				setServer(i);
				setSelectedServer(i?.object.id);
			}}
			value={server?.index}
			label="Server"
			placeholder="Select a server..."
			fullWidth
		/>
	);
}
