import { Player } from "@lottiefiles/react-lottie-player";
import { Backdrop, Stack, Typography } from "@mui/material";
import React from "react";

export default function LoadingOverlay(props: { title: string }) {
  return (
    <Backdrop
      sx={{
        color: "#FFF",
        background: "#505050",
        opacity: 1,
        zIndex: (theme: any) => theme.zIndex.drawer + 1,
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
      }}
      open={true}
    >
      <Stack
        flexGrow="1"
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Player
          autoplay
          loop
          src="https://lottie.host/1d29df75-d07f-4bb8-a3af-e1a1123a14d8/6YGpRhoctT.json"
          style={{ height: "180px" }}
        />
      </Stack>
      <Stack
        sx={{ position: "absolute", bottom: "10px" }}
        my={5}
        direction="row"
        spacing={2}
        justifyContent="end"
        alignItems="center"
      >
        <Typography>{props.title}</Typography>
      </Stack>
    </Backdrop>
  );
}
