import { Paper, Stack, Typography, useTheme, Button } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGO_LIGHT_VERTICAL } from "../assets";

export default function PageAuthCodeHandler() {
    const loc = useLocation();
    const nav = useNavigate();
    const params = new URLSearchParams(loc.search);
    const mode = params.get("mode");
    const code = params.get("oobCode");
    const theme = useTheme();
    React.useEffect(() => {
        // Check the code and action
        switch (mode) {
            case "resetPassword":
                // Display reset password handler and UI.
                nav("/recover", { state: { code: code } });
                break;
            case "verifyEmail":
                // Display email verification handler and UI.
                nav("/verify", {
                    state: { code: code, uid: params.get("uid") },
                });
                break;
            default:
                // Error: invalid mode.
                nav("/");
        }
    }, []);
    return (
        <Stack
            sx={{
                height: "100vh",
                width: "100vw",
                background: theme.palette.background.default,
            }}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Stack justifyContent={"center"} alignItems={"center"}>
                {/* The logo  */}
                <img
                    src={LOGO_LIGHT_VERTICAL}
                    alt="Announcely Logo"
                    style={{ height: "196px" }}
                />
                {/* The paper container  */}
                <Paper elevation={1} sx={{ p: "16px", minWidth: "300px" }}>
                    <Stack spacing={"14px"}>
                        <Typography fontSize={"24px"}>
                            Redirecting...
                        </Typography>
                        <Button
                            disabled
                            variant="contained"
                            sx={{ height: "44px" }}
                        >
                            Please wait...
                        </Button>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    );
}
