import { Player } from "@lottiefiles/react-lottie-player";
import { Stack, Typography, useTheme } from "@mui/material";

export default function LoadingIndicator(props: {
  title: string;
  background?: string;
}) {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        height: "100vh",
        background: props.background || theme.palette.background.default,
      }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Player
        autoplay
        loop
        src="https://lottie.host/1d29df75-d07f-4bb8-a3af-e1a1123a14d8/6YGpRhoctT.json"
        style={{ height: "180px" }}
      />
      <Typography>{props.title}</Typography>
    </Stack>
  );
}
