import { FirebaseApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { AppConfig } from "../config";
import { getStorage } from "firebase/storage";

let app: FirebaseApp;
app = initializeApp(AppConfig.firebase);

export const FbApp = app;
export const FbAuth = getAuth(app);
export const FbStorage = getStorage(app);
