import { TrendingDown, TrendingFlat, TrendingUp } from "@mui/icons-material";
import { Paper, Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";

export default function AnalyticsCard(props: {
	color?: string;
	icon?: ReactElement;
	label?: string;
	value?: string;
	/**
	 * Difference in percentage. Higher value will add TrendUp icon and lower values will add TrendDown icon.
	 * 0 for TrendFlat icon, and undefined for none.
	 */
	difference?: number;
}) {
	return (
		<Paper
			elevation={0}
			sx={{
				height: "130px",
				width: "240px",
				borderRadius: "20px",
				overflow: "hidden",
				borderTop: "3px solid " + (props.color || "#4488FF"),
				transition: "all 1s",
				":hover": {
					background: "#303030",
					borderTop: "3px solid transparent",
				},
			}}
		>
			<Stack sx={{ my: "18px", mx: "21px" }} spacing="4px">
				{props.icon}
				<Typography fontSize={"20px"}>{props.label}</Typography>
				<Stack
					alignItems={"center"}
					direction={"row"}
					sx={{ width: "100%" }}
					justifyContent={"space-between"}
				>
					<Typography fontSize={"25px"}>{props.value}</Typography>
					<Stack direction={"row"} alignItems={"center"}>
						{props.difference && (
							<Typography>{props.difference}%</Typography>
						)}
						{props.difference && props.difference > 0 && (
							<TrendingUp />
						)}
						{props.difference && props.difference < 0 && (
							<TrendingDown />
						)}
						{props.difference === 0 && <TrendingFlat />}
					</Stack>
				</Stack>
			</Stack>
		</Paper>
	);
}
