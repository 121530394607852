import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { aCurrentSection, aSelectedServer } from "../states/ui";
import {
    Button,
    Divider,
    Grid,
    Paper,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import AnalyticsCard from "../components/AnalyticsCard";
import {
    CampaignRounded,
    LinkRounded,
    MessageRounded,
    People,
    Person,
    Telegram,
    Today,
    WhatsApp,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { GetDashboardData } from "../core/rest";
import {
    Bar,
    BarChart,
    Tooltip as ChartTooltip,
    Legend,
    Rectangle,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from "recharts";
import moment from "moment";
import VerticalTopData from "../components/VerticalTopData";
import DataCard from "../components/DataCard";
import { useModal } from "mui-modal-provider";
import CreateAnnouncementDialog from "../dialogs/CreateAnnouncement";
import CreateLinkDialog from "../dialogs/CreateLink";
import SendSubscriptionEmbedDialog from "../dialogs/SendSubscriptionEmbedDialog";

export default function SectionDashboard() {
    const [busy, setBusy] = useState(false);
    const [, setCurrentSection] = useRecoilState(aCurrentSection);
    const [selectedServer] = useRecoilState(aSelectedServer);
    const { enqueueSnackbar } = useSnackbar();
    const [totalMessage, setTotalMessages] = useState(0);
    const [data, setData] = useState<
        | {
              subscribers: {
                  total: number;
                  telegram: number;
                  whatsapp: number;
                  today: number;
              };
              messages: { labels: string[]; values: number[] };
              channels: {
                  channelId: string;
                  channelName: string;
                  subscribers: number;
                  telegram_messages: number;
                  whatsapp_messages: number;
              }[];
          }
        | undefined
    >();
    const [chartData, setChartData] =
        useState<{ messageCount: number; name: string }[]>();
    const [channelsChartData, setChannelsChartData] = useState<any[]>();
    const [chartSorting, setChartSorting] = useState("whatsapp_messages");
    const { showModal } = useModal();

    async function load() {
        try {
            if (selectedServer) {
                setBusy(true);
                const _data = await GetDashboardData(selectedServer);
                setData(_data);
                let i = 0;
                for (let v of _data?.messages?.values || []) {
                    i += v;
                }
                setTotalMessages(i);

                const _cjs: any[] = [];
                // Itreate data and calculate chart data
                let x = 0;
                for (let _d of _data.messages.values) {
                    _cjs.push({
                        messageCount: _d,
                        name: moment(new Date(_data.messages.labels[x])).format(
                            "MMM DD"
                        ),
                    });
                    x += 1;
                }
                setChartData(_cjs);
                setChannelsChartData(_data.channels);
            }
        } catch (err: any) {
            enqueueSnackbar(
                "Error loading data. Please see logs for details.",
                {
                    variant: "error",
                }
            );
            console.log("ERROR loading data.");
            console.log(err);
        }
        setBusy(false);
    }

    function showAnnouncementDialog() {
        const dlg = showModal(CreateAnnouncementDialog, {
            closeHandler() {
                dlg.destroy();
            },
        });
    }
    function showLinkDialog() {
        const modal = showModal(CreateLinkDialog, {
            closeHandler() {
                modal?.destroy();
            },
        });
    }
    function showSendEmbedDialog() {
        const dlg = showModal(SendSubscriptionEmbedDialog, {
            closeHandler() {
                dlg.destroy();
            },
        });
    }

    useEffect(() => {
        load();
    }, [selectedServer]);

    useEffect(() => {
        setCurrentSection("Dashboard");
        load();
    }, []);

    const chartRef = useRef<any>(null);

    return (
        <Grid
            container
            columns={4}
            sx={{ p: "24px", opacity: busy ? 0.2 : 1, transition: "all .4s" }}
            spacing={"24px"}
            columnSpacing={"28px"}
            justifyContent={"flex-start"}
        >
            <Grid item xs={4}>
                <Paper elevation={0} sx={{ p: "26px" }}>
                    <Stack spacing="8px">
                        <Typography>Quick Actions</Typography>
                        <Stack direction={"row"} spacing="8px">
                            <Button
                                onClick={showAnnouncementDialog}
                                variant="contained"
                                sx={{ borderRadius: "4px" }}
                                startIcon={<CampaignRounded />}
                            >
                                Create an Announcement
                            </Button>
                            <Button
                                onClick={showLinkDialog}
                                startIcon={<LinkRounded />}
                                variant="contained"
                                sx={{ borderRadius: "4px" }}
                            >
                                Link a Discord Channel
                            </Button>
                            {/* <Button
                                onClick={showSendEmbedDialog}
                                startIcon={<MessageRounded />}
                                variant="contained"
                                sx={{ borderRadius: "4px" }}
                            >
                                Send 'Subscribe' Embed
                            </Button> */}
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>

            <Grid item xs={3}>
                <Paper elevation={0} sx={{ height: "400px", p: "26px" }}>
                    <Stack sx={{ height: "100%" }}>
                        <Typography fontSize={"14px"}>Activity</Typography>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={"18px"}
                        >
                            <Typography fontSize={"18px"} fontWeight={"bold"}>
                                Messages Delivered: {totalMessage}
                            </Typography>
                            <Typography fontSize={"14px"}>
                                {moment()
                                    .subtract(30, "days")
                                    .format("MMM DD, YYYY")}{" "}
                                to {moment().format("MMM DD, YYYY")}
                            </Typography>
                        </Stack>
                        <Divider sx={{ my: "8px" }} />
                        <Stack flex={1}>
                            {data &&
                                data.messages.labels &&
                                data.messages.values && (
                                    <ResponsiveContainer
                                        width={"100%"}
                                        height={"100%"}
                                    >
                                        <BarChart data={chartData}>
                                            <defs>
                                                <linearGradient
                                                    id="grad1"
                                                    x1="0%"
                                                    y1="0%"
                                                    x2="0%"
                                                    y2="100%"
                                                >
                                                    <stop
                                                        offset="0%"
                                                        style={{
                                                            stopColor:
                                                                "#6D3AFF",
                                                        }}
                                                    />
                                                    <stop
                                                        offset="100%"
                                                        style={{
                                                            stopColor:
                                                                "#4A3AFF",
                                                        }}
                                                    />
                                                </linearGradient>
                                            </defs>
                                            <ChartTooltip
                                                cursor={false}
                                                contentStyle={{
                                                    background: "#303030",
                                                    border: "0px solid #707070",
                                                    borderRadius: "8px",
                                                    fontFamily: "Poppins",
                                                    boxShadow:
                                                        "0px 0px 5px 0px #4488FF",
                                                }}
                                                itemStyle={{
                                                    fontFamily: "Poppins",
                                                    textTransform: "capitalize",
                                                    color: "#BBB",
                                                }}
                                                content={({
                                                    payload,
                                                    label,
                                                }) => {
                                                    return (
                                                        <Stack
                                                            spacing={"16px"}
                                                            sx={{
                                                                width: "100%",
                                                                background:
                                                                    "#202020",
                                                                px: "14px",
                                                                py: "8px",
                                                                borderRadius:
                                                                    "8px",
                                                                border: "1px solid #88F",
                                                            }}
                                                            justifyContent={
                                                                "center"
                                                            }
                                                            alignItems={"start"}
                                                        >
                                                            <Typography>
                                                                {label}
                                                            </Typography>
                                                            {payload?.map(
                                                                (p) => {
                                                                    return (
                                                                        <Stack
                                                                            spacing={
                                                                                "8px"
                                                                            }
                                                                            direction={
                                                                                "row"
                                                                            }
                                                                            alignItems={
                                                                                "center"
                                                                            }
                                                                            justifyContent={
                                                                                "space-between"
                                                                            }
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                    borderRadius:
                                                                                        "25px",
                                                                                    background:
                                                                                        "linear-gradient(180deg, #6D3AFF, #4A3AFF)",
                                                                                }}
                                                                            />
                                                                            <Typography>
                                                                                {
                                                                                    p.value
                                                                                }
                                                                            </Typography>
                                                                            <Typography>
                                                                                Messages
                                                                                Delivered
                                                                            </Typography>
                                                                        </Stack>
                                                                    );
                                                                }
                                                            )}
                                                        </Stack>
                                                    );
                                                }}
                                            />
                                            <YAxis
                                                axisLine={false}
                                                tickLine={false}
                                            />
                                            <XAxis
                                                dataKey={"name"}
                                                axisLine={false}
                                                tickLine={false}
                                            />
                                            <Bar
                                                spacing={"10px"}
                                                dataKey={"messageCount"}
                                                stackId={"a"}
                                                background={
                                                    <Rectangle
                                                        fill="#FFF2"
                                                        radius={18}
                                                    />
                                                }
                                                enableBackground={"true"}
                                                radius={18}
                                                barSize={12}
                                                style={{
                                                    fill: "url(#grad1)",
                                                }}
                                            />
                                            <Legend
                                                content={({ payload }) => {
                                                    return (
                                                        <Stack
                                                            spacing={"16px"}
                                                            direction={"row"}
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                            justifyContent={
                                                                "center"
                                                            }
                                                            alignItems={
                                                                "center"
                                                            }
                                                        >
                                                            {payload?.map(
                                                                (p) => {
                                                                    return (
                                                                        <Stack
                                                                            spacing={
                                                                                "8px"
                                                                            }
                                                                            direction={
                                                                                "row"
                                                                            }
                                                                            alignItems={
                                                                                "center"
                                                                            }
                                                                            justifyContent={
                                                                                "center"
                                                                            }
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                    borderRadius:
                                                                                        "25px",
                                                                                    background:
                                                                                        "linear-gradient(180deg, #6D3AFF, #4A3AFF)",
                                                                                }}
                                                                            />
                                                                            <Typography>
                                                                                Messages
                                                                                Delivered
                                                                            </Typography>
                                                                        </Stack>
                                                                    );
                                                                }
                                                            )}
                                                        </Stack>
                                                    );
                                                }}
                                            />
                                        </BarChart>
                                    </ResponsiveContainer>
                                )}
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>

            <Grid item xs={1}>
                <DataCard
                    heading1="Top"
                    heading2="Channels"
                    heading3={
                        chartSorting === "subscribers"
                            ? "by subscribers"
                            : chartSorting === "whatsapp_messages"
                            ? "by whatsapp messages"
                            : "by telegram messages"
                    }
                    data={channelsChartData || []}
                    columnHeaders={[
                        <Typography># Channel</Typography>,
                        <Tooltip title="WhatsApp Messages">
                            <WhatsApp
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                    setChartSorting("whatsapp_messages")
                                }
                                color={
                                    chartSorting === "whatsapp_messages"
                                        ? "primary"
                                        : undefined
                                }
                            />
                        </Tooltip>,
                        <Tooltip title="Telegram Messages">
                            <Telegram
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                    setChartSorting("telegram_messages")
                                }
                                color={
                                    chartSorting === "telegram_messages"
                                        ? "primary"
                                        : undefined
                                }
                            />
                        </Tooltip>,
                        <Tooltip title="Total Subscribers">
                            <Person
                                sx={{ cursor: "pointer" }}
                                onClick={() => setChartSorting("subscribers")}
                                color={
                                    chartSorting === "subscribers"
                                        ? "primary"
                                        : undefined
                                }
                            />
                        </Tooltip>,
                    ]}
                    labelKey="channelName"
                    sortBy={chartSorting}
                    valueKeys={[
                        "whatsapp_messages",
                        "telegram_messages",
                        "subscribers",
                    ]}
                />
            </Grid>
        </Grid>
    );
}
