import { Paper, Stack, Typography, useTheme, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import TextInput from "../components/TextInput";
import { useNavigate } from "react-router-dom";
import { LOGO_LIGHT_VERTICAL } from "../assets";
import {
    GoogleAuthProvider,
    RecaptchaVerifier,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    updateProfile,
} from "firebase/auth";
import { FbAuth } from "../core/firebase";
import { enqueueSnackbar } from "notistack";
import { SendVerificationEmail } from "../core/rest";
import { ReCAPTCHA } from "react-google-recaptcha";

export default function PageSignup() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [error, setError] = useState("");
    const [busy, setBusy] = useState(false);
    const [created, setCreated] = useState(false);
    const [token, setToken] = useState("");

    const theme = useTheme();
    const navigate = useNavigate();

    async function signup(fe: any) {
        fe?.preventDefault?.();
        const token = await recaptchaRef.current?.executeAsync?.();
        setError("");
        setBusy(false);
        // Make sure all fields are valid...
        if (!firstName) {
            setError("firstName");
            return;
        }
        if (!lastName) {
            setError("lastName");
            return;
        }
        if (!email) {
            setError("email");
            return;
        }
        if (!password) {
            setError("password");
            return;
        }
        if (!passwordConfirm) {
            setError("password2");
            return;
        }
        if (password !== passwordConfirm) {
            setError("password_confirmation");
            return;
        }
        try {
            setBusy(true);
            // Create account on firebase.
            const acc = await createUserWithEmailAndPassword(
                FbAuth,
                email,
                password
            );
            if (acc.user) {
                // Update the user id
                await updateProfile(acc.user, {
                    displayName: firstName + " " + lastName,
                });
                await SendVerificationEmail();
                // Prompt for verification.
                await signOut(FbAuth);
                setCreated(true);
            }
        } catch (err: any) {
            if (err.code) {
                if (err.code === "auth/weak-password") {
                    enqueueSnackbar(
                        "Password must be atleast 6 characters long.",
                        {
                            variant: "error",
                        }
                    );
                } else if (err.code === "auth/email-already-in-use") {
                    // 2. Try signing in. If we succeed, we then re-send email verification. Otherwise, we login the user.
                    try {
                        const creds = await signInWithEmailAndPassword(
                            FbAuth,
                            email,
                            password
                        );
                        if (creds && creds.user.emailVerified) {
                            // Login
                            navigate("/portal");
                        } else if (creds && !creds.user.emailVerified) {
                            // Send verification email
                            await SendVerificationEmail();
                            signOut(FbAuth);
                            setCreated(true);
                        }
                    } catch (err: any) {
                        // Invalid password?
                        signOut(FbAuth);
                        enqueueSnackbar(
                            "Email is already associated with another account.",
                            { variant: "error" }
                        );
                    }
                } else {
                    enqueueSnackbar(
                        "Error signing up. Please try again or contact administrators.",
                        { variant: "error" }
                    );
                }
            } else {
                enqueueSnackbar(
                    "Unknown error signing up. Please try again or contact administrators.",
                    { variant: "error" }
                );
            }
        }
        setBusy(false);
    }

    useEffect(() => {
        if (FbAuth.currentUser) {
            // Navigate to /
            navigate("/");
        }
    }, []);

    const recaptchaRef = React.useRef<any>(null);
    return (
        <Stack
            sx={{
                height: "100vh",
                width: "100vw",
                background: theme.palette.background.default,
            }}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Stack justifyContent={"center"} alignItems={"center"}>
                {/* The logo  */}
                <img
                    src={LOGO_LIGHT_VERTICAL}
                    alt="Announcely Logo"
                    style={{ height: "196px" }}
                />
                {/* The paper container  */}
                <Paper
                    onSubmit={signup}
                    component={"form"}
                    elevation={1}
                    sx={{ p: "16px", minWidth: "350px", maxWidth: "350px" }}
                >
                    {created && (
                        <Stack spacing={"14px"}>
                            <Typography fontSize={"24px"}>
                                Create an Account
                            </Typography>
                            <Typography>
                                Your account has been created. Please click on
                                the link sent to your email to verify your
                                account.
                            </Typography>
                        </Stack>
                    )}
                    {!created && (
                        <Stack spacing={"14px"}>
                            <Typography fontSize={"24px"}>
                                Create an Account
                            </Typography>
                            <Typography>
                                Please tell us about yourself.
                            </Typography>
                            <Stack direction={"row"} spacing={"14px"}>
                                <TextInput
                                    disabled={busy}
                                    error={error === "firstName"}
                                    fullWidth
                                    label="First Name"
                                    value={firstName}
                                    onUpdate={(p) => setFirstName(p)}
                                />
                                <TextInput
                                    disabled={busy}
                                    error={error === "lastName"}
                                    fullWidth
                                    label="Last Name"
                                    value={lastName}
                                    onUpdate={(p) => setLastName(p)}
                                />
                            </Stack>
                            <TextInput
                                disabled={busy}
                                error={error === "email"}
                                label="Email"
                                value={email}
                                onUpdate={(p) => setEmail(p)}
                            />
                            <TextInput
                                disabled={busy}
                                error={
                                    error === "password" ||
                                    error === "password_confirmation"
                                }
                                name="password"
                                type="password"
                                label="Password"
                                value={password}
                                onUpdate={(p) => setPassword(p)}
                            />
                            <TextInput
                                disabled={busy}
                                error={
                                    error === "password2" ||
                                    error === "password_confirmation"
                                }
                                name="confirm-password"
                                type="password"
                                label="Confirm Password"
                                value={passwordConfirm}
                                onUpdate={(p) => setPasswordConfirm(p)}
                            />
                            <Typography>
                                By creating an account, you agree to our Terms
                                of Service and Privacy Policy.
                            </Typography>
                            <Typography>
                                Have an account?{" "}
                                <Button
                                    disabled={busy}
                                    onClick={() => navigate("/login")}
                                >
                                    Sign in
                                </Button>
                            </Typography>
                            <Button
                                type="submit"
                                disabled={busy}
                                variant="contained"
                                sx={{ height: "44px" }}
                            >
                                Sign up
                            </Button>
                        </Stack>
                    )}
                </Paper>
            </Stack>
        </Stack>
    );
}
